/*
Author:      Tyler Petty
Created:     6/7/2022
Modified:    7/7/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useEffect, useState } from "react";
import Error from "../../../components/Error/Error";
import "./DeviceList.scss";
import PropTypes from "prop-types";
import Context from "../../../components/Context/Context";
import {
  DEV_API,
  PROD_API
} from "../../../utilities/constantsRpmAdministrativeConsole";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/apiRequest";
import { useParams } from "react-router-dom";

// An editable list of devices associated with currently selected company

export default function DeviceList(props) {
  const [filterId, setFilterId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const context = useContext(Context);
  const { companyId } = useParams();


  // Clear error messages when devices are saved or being edited.
  useEffect(() => {
    setErrorMessage("");
  }, [props.devicesChanged]);

  // Save changes to devices.
  async function saveDevices() {
    if (devicesAreValid()) {
      const apiSuccess = await apiPutDevices(props);
      if (apiSuccess === true) {
        props.onAction({
          type: "SAVE_DEVICES",
          payload: {}
        });
      }
    }
  }

  // Check if all devices are valid.
  function devicesAreValid() {
    let valid = true;
    for (const device of props.companyDevices) {
      if (device.identifier.length === 0) {
        setErrorMessage("Each device is required to have an identifier.");
        valid = false;
        break;
      } else if (device.model === "") {
        setErrorMessage("Each device is required to have a valid type.");
        valid = false;
        break;
      } else if (!props.deviceTypes.find(deviceType => deviceType.deviceTypeId === device.deviceTypeId)) {
        setErrorMessage("Each device is required to have a valid type");
        valid = false;
        break;
      }
    }
    return valid;
  }

  // Attempt an api put request
  async function apiPutDevices(state) {
    let successful = true;
    const devices = state.companyDevices.map((device) => ({
      identifier: device.identifier,
      deviceType: device.model
    }));
    const requestBody = { devices: devices };

    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/company/${companyId}/device`,
      "PUT",
      requestBody
    );
    setLoading(false);

    if (!response.ok) {
      successful = false;
      if (response.status < 500 && responseBody.error) {
        setErrorMessage(responseBody.error);
      } else {
        setErrorMessage("Internal server error. Unable to update devices");
      }
    }
    return successful;
  }

  return (
    <div>
      <Spinner loading={loading} />

      <div className="mt-5">
        <div
          className="list-header p-2"
          style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
        >
          <div className="row align-items-top">
            <div className="list-title col">
              <span className="align-top">Associated Devices</span>
              <input
                className="form-control"
                list="datalist-devices"
                value={props.companyDevices.identifier}
                placeholder="Filter by identifier"
                onChange={(e) => setFilterId(e.target.value.toLowerCase())}
              />
            </div>

            <div className="col">
              <button
                className="create-btn btn btn-light float-end my-auto"
                onClick={() => props.onAction({
                  type: "CREATE_DEVICE",
                  payload: {}
                })}
              >
                <i className="fa fa-fw fa-plus fa-1x" />
              </button>
            </div>
          </div>
        </div>
        {props.companyDevices.length === 0 && props.deviceTypes.length === 0 ? (
          <div className="empty-list py-5 px-4 text-center">
            There are no devices to display
          </div>
        ) : (
          <div className="device-list">
            <table className="table table-hover mb-0" >
              <thead className="list-header">
                <tr>
                  <th scope="col-4">Type</th>
                  <th scope="col-3">Identifier</th>
                  <th scope="col-1">Delete</th>
                </tr>
              </thead>
              <tbody className="list-body">
                {props.companyDevices.filter((device) => device.identifier.includes(filterId)).map((device) =>
                  <tr key={device.tempIdentifier}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="list-item col-4">
                      <select
                        className="form-select"
                        value={device.model}
                        onChange={e => props.onAction({
                          type: "UPDATE_DEVICE",
                          payload: {
                            identifier: device.identifier,
                            deviceTypeId: props.deviceTypes.find(
                              (deviceType) => deviceType.model === e.target.value
                            ).deviceTypeId,
                            model: e.target.value,
                            tempIdentifier: device.tempIdentifier
                          }
                        })}
                      >
                        <option value="" disabled>
                          Select a model...
                        </option>
                        {props.deviceTypes.map(deviceType =>
                          <option
                            key={deviceType.deviceTypeId}
                            value={deviceType.model}
                          >
                            {deviceType.model}
                          </option>
                        )}
                      </select>
                    </td>
                    <td className="list-item col-3">
                      <input
                        className="form-control mx-auto"
                        type="text"
                        value={device.identifier}
                        onChange={e => props.onAction({
                          type: "UPDATE_DEVICE",
                          payload: {
                            deviceTypeId: device.deviceTypeId,
                            identifier: e.target.value,
                            model: device.model,
                            tempIdentifier: device.tempIdentifier
                          }
                        })}
                      />
                    </td>
                    <td className="list-item col-1">
                      <button
                        type="button"
                        className="btn btn-danger btn-floating"
                        onClick={() => props.onAction({
                          type: "DELETE_DEVICE",
                          payload: { tempIdentifier: device.tempIdentifier }
                        })}
                      >
                        <i className="fa fa-times" />
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
              <tbody className="list-body">
                {props.companyDevices.filter((device) => !device.identifier.includes(filterId)).map((device) =>
                  <tr
                    key={device.tempIdentifier}
                  >
                    <td className="list-item col-4">
                      <input
                        className="form-select"
                        value={device.model}
                        disabled
                      >
                      </input>
                    </td>
                    <td className="list-item col-3">
                      <input
                        className="form-control mx-auto"
                        type="text"
                        disabled
                        value={device.identifier}
                      />
                    </td>
                    <td className="list-item col-1">
                      <button
                        type="button"
                        disabled
                        className="btn btn-danger btn-floating"
                      >
                        <i className="fa fa-times" />
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )
        }
      </div>
      {
        props.devicesChanged && (
          <div
            className="list-footer p-3"
            style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
          >
            <div className="row align-items-center">
              <div className="col">
                {errorMessage.length > 0 && (
                  <Error message={errorMessage} />
                )}
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-light me-3"
                  onClick={() => saveDevices()}
                >
                  Save
                </button>

                <button
                  className="btn btn-light"
                  onClick={() => props.onAction({
                    type: "REVERT_DEVICES",
                    payload: {}
                  })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}


DeviceList.propTypes = {
  companyDevices: PropTypes.array.isRequired,
  deviceTypes: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  devicesChanged: PropTypes.bool.isRequired
};