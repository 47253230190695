/*
Author:      Zachary Thomas
Created:     4/14/2022
Modified:    4/14/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import ModalFooter from "../ModalFooter/ModalFooter";
import Error from "../Error/Error";
import "./PermanentlyDeleteModal.scss";

// Modal for confirming a permanent deletion.
export default function PermanentlyDeleteModal(props) {
  const [confirmationText, setConfirmationText] = useState("");
  const [deletionReady, setDeletionReady] = useState(false);

  // Check to see if the user is ready to delete.
  useEffect(() => {
    if (confirmationText.toUpperCase() === "PERMANENTLY DELETE") {
      setDeletionReady(true);
    } else {
      setDeletionReady(false);
    }
  }, [confirmationText]);

  // Attempt to delete.
  function attemptDelete() {
    if (deletionReady) {
      setConfirmationText("");
      setDeletionReady(false);
      props.onDelete();
    }
  }

  // Cancel delete.
  function cancelDelete() {
    setConfirmationText("");
    setDeletionReady(false);
    props.onCancel();
  }

  return (
    <div className="confirm-modal">
      <Modal
        show={props.showModal}
        onHide={() => cancelDelete()}
        backdropClassName={"top-level-backdrop"}
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title">
            {props.title}
          </h5>
        </ModalHeader>
        <ModalBody>
          <p>
            {props.content}
          </p>

          <br />

          <p>
            Enter &apos;<span className="font-weight-bold">permanently delete</span>&apos; in the box below to confirm.
          </p>
          <input
            className="form-control"
            placeholder="permanently delete"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />

          {props.errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={props.errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-danger"
            data-dismiss="modal"
            onClick={() => attemptDelete()}
            disabled={!deletionReady}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => cancelDelete()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

PermanentlyDeleteModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};