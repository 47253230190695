/*
Author:      Zachary Thomas
Created:     4/6/2022
Modified:    4/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Context from "../../../components/Context/Context";
import PropTypes from "prop-types";
import SubsystemModal from "./SubsystemModal/SubsystemModal";
import { SUBDIRECTORY } from "../../../utilities/constantsNotificationManager";
import "./SubsystemList.scss";

// A list of subsystems.
export default function SubsystemList(props) {
  const [showModal, setShowModal] = useState(false);
  const context = useContext(Context);
  const history = useHistory();

  return (
    <div className="subsystem-list my-5">
      <div
        className="list-header p-4"
        style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
      >
        <div className="row align-items-top">
          <div className="list-title col">
            <span className="align-top">
              {props.audienceType === "operations" ? "Operation" : "RPM Customer"} Subsystems &nbsp;
              <i className={`fa fa-fw fa-${props.audienceType === "operations" ? "wrench" : "users"} fa-1x`} />
            </span>
          </div>

          <div className="col">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => setShowModal(true)}
            >
              <i className="fa fa-fw fa-plus fa-1x" />
            </button>
          </div>
        </div>
      </div>

      <div className="list-body">
        {!props.loading && (
          <table className="table mb-0 pb-0">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Code</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              {props.subsystems.map(subsystem =>
                <tr
                  className="list-item selectable-container py-2"
                  onClick={() => history.push(`/${SUBDIRECTORY}/subsystem/${props.audienceType}/${subsystem.subsystemId}`)}
                  key={subsystem.subsystemId}
                >
                  <td>{subsystem.name}</td>
                  <td>{subsystem.code}</td>
                  <td>{subsystem.description}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {props.subsystems.length === 0 && !props.loading && (
          <div className="empty-list py-5 px-4">
            There are no subsystems to display
          </div>
        )}
      </div>

      <SubsystemModal
        showModal={showModal}
        audienceType={props.audienceType}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

SubsystemList.propTypes = {
  loading: PropTypes.bool.isRequired,
  audienceType: PropTypes.string.isRequired,
  subsystems: PropTypes.array.isRequired
};