/*
Author:      Zachary Thomas
Created:     9/1/2021
Modified:    8/29/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Constants that are referenced throughout the application.
module.exports = Object.freeze({
  AUTH_API: "https://3l4lpgihyk.execute-api.us-west-2.amazonaws.com/prod",
  SHOW_TOAST_MILLISECONDS: 5000,
  ADMIN_ROLE: "ADMIN",
  OPERATIONS_ROLE: "OPERATIONS",
  BASIC_USER_ROLE: "READONLY"
});