/*
Author:      Zachary Thomas
Created:     2/21/2022
Modified:    3/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext } from "react";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import PageTitle from "../../components/PageTitle/PageTitle";
import TabList from "../../components/TabList/TabList";
import Context from "../../components/Context/Context";
import { useParams } from "react-router-dom";
import DeviceDetails from "./DeviceDetails/DeviceDetails";
import DeviceConfiguration from "./DeviceConfiguration/DeviceConfiguration";
import DeviceUpload from "./DeviceUpload/DeviceUpload";
import DeviceHistory from "./DeviceHistory/DeviceHistory";
import { LINKS, DEV_API, PROD_API } from "../../utilities/constantsIotManager";
import IotManagerCleanDeviceClassHistory from "../../api/IotManagerCleanDeviceClassHistory";
import IotManagerCleanDevice from "../../api/IotManagerCleanDevice";
import "./IotManagerDevicePage.scss";

// Page for viewing a single device or a whole device class.
export default function IotManagerDevicePage() {
  const tabs = [{
    tabId: "config",
    name: "Device Configuration",
    shortName: "Device Configuration",
    breakPoints: "col"
  }, {
    tabId: "file",
    name: "File Management",
    shortName: "File Management",
    breakPoints: "col"
  }];
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState("");
  const [assetIdentifier, setAssetIdentifier] = useState("");
  const [history, setHistory] = useState([]);
  const [isClass, setIsClass] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState("config");
  const { deviceType, deviceClass, deviceIdentifier } = useParams();
  const context = useContext(Context);

  // Setting the title and the links to be displayed in the navbar for the current page.
  useEffect(() => {
    context.setTitle("IoT Manager");
    context.setLinks(LINKS);
  }, []);

  // Check if we are displaying a device or device class.
  useEffect(() => {
    setIsClass(deviceIdentifier === undefined);
  }, [deviceIdentifier]);

  // Get the data for the current device.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/${deviceType}/${deviceClass}${deviceIdentifier ? "/" + deviceIdentifier : "/history"}`,
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        if (deviceIdentifier) {
          responseBody = IotManagerCleanDevice(responseBody);
          setOwnerCompany(responseBody.company);
          setAssetIdentifier(responseBody.assetIdentifier);
          setHistory(responseBody.history);
        } else {
          responseBody = IotManagerCleanDeviceClassHistory(responseBody);
          setHistory(responseBody.history);
        }
      } else {
        console.error("Internal server error. Unable to retrieve device data.");
      }
      setLoading(false);
    },
    [deviceType, deviceClass, deviceIdentifier]
  );

  return (
    <div className="page-iot-device mb-5">
      <Spinner loading={loading} />
      <PageTitle
        title={deviceType === deviceClass ? (
          `${deviceClass} ${isClass ? "" : "/ " + deviceIdentifier}`
        ) : (
          `${deviceType} / ${deviceClass} ${isClass ? "" : "/ " + deviceIdentifier}`
        )}
      />

      <div className="row">
        {!isClass && (
          <div className="col-12 col-lg-4 mb-4">
            <DeviceDetails
              deviceType={deviceType}
              deviceClass={deviceClass}
              deviceIdentifier={deviceIdentifier}
              ownerCompany={ownerCompany}
              assetIdentifier={assetIdentifier}
            />
          </div>
        )}

        <div className={isClass ? "tabs col-12 mb-4" : "tabs col-12 col-lg-8 mb-4"}>
          <div className="h-100">
            <TabList
              tabs={tabs}
              selectedTabId={selectedTabId}
              onSelect={tabId => setSelectedTabId(tabId)}
            />
            {selectedTabId === "config" ? (
              <DeviceConfiguration
                deviceType={deviceType}
                deviceClass={deviceClass}
                deviceIdentifier={deviceIdentifier}
              />
            ) : (
              <DeviceUpload
                deviceType={deviceType}
                deviceClass={deviceClass}
                deviceIdentifier={deviceIdentifier}
              />
            )}
          </div>
        </div>
      </div>

      <DeviceHistory historyRecords={history} />
    </div>
  );
}