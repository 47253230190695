/*
Author:      Zachary Thomas
Created:     2/24/2022
Modified:    3/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useEffect, useState, useContext } from "react";
import apiRequest from "../../../utilities/apiRequest";
import Warning from "../../../components/Warning/Warning";
import SelectResourceModal from "./SelectResourceModal/SelectResourceModal";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import Context from "../../../components/Context/Context";
import Spinner from "../../../components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import Success from "../../../components/Success/Success";
import PropTypes from "prop-types";
import { PROD_API, DEV_API } from "../../../utilities/constantsIotManager";
import "./DeviceConfiguration.scss";

// Form for configuring monitoring devices.
export default function DeviceConfiguration(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [configurationType, setConfigurationType] = useState("UPLOAD_QUERY");
  const [resourceName, setResourceName] = useState("");
  const [resourceLocation, setResourceLocation] = useState("");
  const [jsonText, setJsonText] = useState("{}");
  const [jsonIsValid, setJsonIsValid] = useState(true);
  const [showSelectResource, setShowSelectResource] = useState(false);
  const [showApplyFile, setShowApplyFile] = useState(false);
  const [showApplyQuery, setShowApplyQuery] = useState(false);
  const [showSaveQuery, setShowSaveQuery] = useState(false);
  const context = useContext(Context);

  // When the configuration type, device, or device class changes, reset values to default.
  useEffect(() => {
    // Get the initial location of the current file.
    let deviceHighLevel = `${props.deviceType}`;
    let deviceLowLevel = "";
    if (props.deviceType !== props.deviceClass) {
      deviceHighLevel += `/${props.deviceClass}`;
    }
    if (props.deviceIdentifier !== undefined) {
      deviceLowLevel = `/${props.deviceIdentifier}`;
    }
    const initialLocation = `${deviceHighLevel}${deviceLowLevel}/`;

    switch (configurationType) {
      case "UPLOAD_QUERY": {
        // Get the current UTC date as a string.
        const date = new Date();
        const currentUtcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
          date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        const currentUtcString = new Date(currentUtcDate).toISOString()
          .slice(0, -5)
          .replaceAll(":", "")
          .replaceAll("-", "");

        // Set initial values.
        const initialResourceName = `${initialLocation.replaceAll("/", "_")}${currentUtcString}.json`;
        setResourceName(initialResourceName);
        setResourceLocation(initialLocation);
        break;
      }

      case "SELECT_RESOURCE":
      default: {
        setResourceName("");
        setResourceLocation("");
      }
    }
  }, [props.deviceType, props.deviceClass, props.deviceIdentifier, configurationType]);

  // If an input field changes, clear error and success messages.
  useEffect(() => {
    setSuccessMessage("");
    setErrorMessage("");
  }, [props.deviceType, props.deviceClass, props.deviceIdentifier, configurationType,
    jsonText, resourceName, resourceLocation]);

  // Check if the user entered JSON is valid.
  useEffect(() => {
    try {
      JSON.parse(jsonText);
      setJsonIsValid(true);
    } catch (e) {
      setJsonIsValid(false);
    }
  }, [jsonText]);

  // Update the current resource name and location.
  function updateResource(name, location) {
    setResourceName(name);
    setResourceLocation(location);
  }

  // Check if a request is valid.
  function requestIsValid() {
    if (configurationType === "SELECT_FILE" && resourceName.length === 0) {
      setSuccessMessage("");
      setErrorMessage("You must select a file to apply.");
      return false;

    } else if (configurationType === "UPLOAD_QUERY" && jsonText.length === 0) {
      setSuccessMessage("");
      setErrorMessage("Your query may not be empty.");
      return false;

    } else if (configurationType === "UPLOAD_QUERY" && !jsonIsValid) {
      setSuccessMessage("");
      setErrorMessage("Your query must be valid JSON.");
      return false;

    } else {
      return true;
    }
  }

  // Apply configuration file to device(s).
  async function applyFile() {
    if (requestIsValid()) {
      const requestBody = {
        data: null,
        location: `${resourceLocation}${resourceName}`,
        saveQuery: false,
        fileName: resourceName,
        fileDescription: "",
        event: "Post a File"
      };

      applyRequest(requestBody);
    } else {
      setShowApplyFile(false);
      setShowApplyQuery(false);
      setShowSaveQuery(false);
    }
  }

  // Apply query to device(s).
  async function applyQuery(saveQuery) {
    if (requestIsValid()) {
      const requestBody = {
        data: jsonText,
        location: `${resourceLocation}${resourceName}`,
        saveQuery: saveQuery,
        fileName: resourceName,
        fileDescription: "Custom JSON Query",
        event: "Post an Ad-hoc Query"
      };

      applyRequest(requestBody);
    } else {
      setShowApplyFile(false);
      setShowApplyQuery(false);
      setShowSaveQuery(false);
    }
  }

  // Apply a request body to device(s).
  async function applyRequest(requestBody) {
    let url;
    if (props.deviceIdentifier) {
      url = `${context.isProd ? PROD_API : DEV_API}/${props.deviceType}/${props.deviceClass}/${props.deviceIdentifier}/resource`;
    } else {
      url = `${context.isProd ? PROD_API : DEV_API}/${props.deviceType}/${props.deviceClass}/resource`;
    }

    setLoading(true);
    const [response, responseBody] = await apiRequest(
      url,
      "POST",
      requestBody
    );
    setLoading(false);
    setShowApplyFile(false);
    setShowApplyQuery(false);
    setShowSaveQuery(false);

    if (response.ok && responseBody) {
      setSuccessMessage("Request was accepted. Monitor the devices history to confirm that the changes have been applied.");
      setErrorMessage("");

    } else {
      if (response.status < 500 && responseBody.error) {
        setSuccessMessage("");
        setErrorMessage(responseBody.error);
      } else {
        setSuccessMessage("");
        setErrorMessage("Internal server error. Unable to apply configuration.");
      }
    }
  }

  return (
    <div className="iot-device-configuration">
      <Spinner loading={loading} />

      <div className="device-container px-5 pt-3 pb-4 my-0">
        <div className="form-group my-3">
          <label className="my-2">Select Configuration Type</label>
          <select
            className="form-select mb-3"
            value={configurationType}
            onChange={(e) => setConfigurationType(e.target.value)}
          >
            <option value="UPLOAD_QUERY">Apply JSON Query</option>
            <option value="SELECT_RESOURCE">Apply Existing File</option>
          </select>

          {configurationType === "UPLOAD_QUERY" && (
            <div>
              <label>JSON Query</label>
              <textarea
                className="form-control mb-3"
                type="text-area"
                rows="3"
                value={jsonText}
                onChange={(e) => setJsonText(e.target.value)}
              />

              <Warning message={jsonIsValid ? "" : "The above text is not valid JSON."} />
            </div>
          )}

          {configurationType === "SELECT_RESOURCE" && (
            <div className="row align-items-center">
              <div className="col-auto pe-0">
                <button
                  className="select-resource-btn btn btn-secondary mx-auto"
                  type="button"
                  onClick={() => setShowSelectResource(true)}
                >
                  Select File
                </button>
              </div>

              <div className="col label-col ps-0">
                <div
                  className="select-resource-label"
                  onClick={() => setShowSelectResource(true)}
                >
                  <span>
                    {resourceName.length === 0 ? "No Resource Selected" : resourceName}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="text-center mt-4 mb-2">
          {configurationType === "SELECT_RESOURCE" ? (
            <button
              type="button"
              className={`submit-btn btn btn-success ${resourceName.length === 0 ? "disabled" : ""}`}
              onClick={() => setShowApplyFile(true)}
            >
              Apply
            </button>

          ) : (
            <Fragment>
              <button
                type="button"
                className="submit-btn btn btn-success mx-2"
                onClick={() => setShowApplyQuery(true)}
              >
                Apply
              </button>

              <button
                type="button"
                className="submit-btn btn btn-info mx-2"
                onClick={() => setShowSaveQuery(true)}
              >
                Save & Apply
              </button>
            </Fragment>
          )}
        </div>

        <div className="d-flex justify-content-center pt-4">
          <Error message={errorMessage} />
          <Success message={successMessage} />
        </div>
      </div>

      <SelectResourceModal
        showModal={showSelectResource}
        deviceType={props.deviceType}
        deviceClass={props.deviceClass}
        deviceIdentifier={props.deviceIdentifier}
        selectedResource={`${resourceLocation}${resourceName}`}
        onClose={() => setShowSelectResource(false)}
        onSelect={(name, location) => updateResource(name, location)}
      />

      <ConfirmModal
        showModal={showApplyFile}
        title="Apply File Confirmation"
        content={props.deviceIdentifier ? (
          `Are you sure that you want to apply the file '${resourceName}'`
          + ` to the specific device '${resourceLocation.slice(0, -1)}'?`
        ) : (
          `Are you sure that you want to apply the file '${resourceName}'`
          + ` to the device class '${resourceLocation.slice(0, -1)}'?`
        )}
        yesText="Apply"
        noText="Cancel"
        danger={false}
        onClose={() => setShowApplyFile(false)}
        onYes={() => applyFile()}
        onNo={() => setShowApplyFile(false)}
      />

      <ConfirmModal
        showModal={showApplyQuery}
        title="Apply Query Confirmation"
        content={props.deviceIdentifier ? (
          `Are you sure that you want to apply your query to the specific device '${resourceLocation.slice(0, -1)}'?`
        ) : (
          `Are you sure that you want to apply your query to the device class '${resourceLocation.slice(0, -1)}'?`
        )}
        yesText="Apply"
        noText="Cancel"
        danger={false}
        onClose={() => setShowApplyQuery(false)}
        onYes={() => applyQuery(false)}
        onNo={() => setShowApplyQuery(false)}
      />

      <ConfirmModal
        showModal={showSaveQuery}
        title="Save and Apply Query Confirmation"
        content={props.deviceIdentifier ? (
          `Are you sure that you want to save the query '${resourceName}'`
          + ` and apply it to the specific device '${resourceLocation.slice(0, -1)}'?`
        ) : (
          `Are you sure that you want to save the query '${resourceName}'`
          + ` and apply it to the device class '${resourceLocation.slice(0, -1)}'?`
        )}
        yesText="Save & Apply"
        noText="Cancel"
        danger={false}
        onClose={() => setShowSaveQuery(false)}
        onYes={() => applyQuery(true)}
        onNo={() => setShowSaveQuery(false)}
      />
    </div>
  );
}

DeviceConfiguration.propTypes = {
  deviceType: PropTypes.string.isRequired,
  deviceClass: PropTypes.string.isRequired,
  deviceIdentifier: PropTypes.string
};