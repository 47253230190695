/*
Author:      Zachary Thomas
Created:     2/28/2022
Modified:    2/28/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./Warning.scss";

// Warning message.
export default function Warning(props) {
  return (
    (props.message.length > 0 && (
      <div className="alert alert-warning" role="alert">
        {props.message}
      </div>
    ))
  );
}

Warning.propTypes = {
  message: PropTypes.string.isRequired
};