/*
Author:      Zachary Thomas
Created:     3/14/2022
Modified:    3/16/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext } from "react";
import Context from "../Context/Context";
import PropTypes from "prop-types";
import "./TabList.scss";

// A list of selectable tabs.
export default function TabList(props) {
  const context = useContext(Context);

  return (
    <div className="tab-list-container container">
      <div className="row">
        {props.tabs.map(tab =>
          <div key={tab.tabId} className={`px-0 ${tab.breakPoints}`}>
            <button
              className={"container-tab btn btn-primary py-3 w-100"
                + ` ${props.selectedTabId === tab.tabId ? "tab-active" : ""}`
                + ` ${context.isProd ? "prod" : "dev"}`
              }
              onClick={() => props.onSelect(tab.tabId)}
            >
              <span className="tab-name">{tab.name}</span>
              <span className="tab-short-name">{tab.shortName}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

TabList.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTabId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};