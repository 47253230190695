/*
Author:      Zachary Thomas
Created:     4/7/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useEffect, useState } from "react";
import Error from "../../../components/Error/Error";
import Context from "../../../components/Context/Context";
import PropTypes from "prop-types";
import "./AddresseeList.scss";

// A list of addressees.
export default function AddresseeList(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const context = useContext(Context);

  // Clear error messages when addressees are saved or being edited.
  useEffect(() => {
    setErrorMessage("");
  }, [props.addresseesChanged]);

  // Check if all addressees are valid.
  function addresseesAreValid() {
    let valid = true;
    for (const addressee of props.addressees) {
      if (addressee.name.length === 0) {
        setErrorMessage("Each addressee is required to have a name.");
        valid = false;
        break;
      } else if (addressee.address.length === 0) {
        setErrorMessage("Each addressee is required to have an address.");
        valid = false;
        break;
      } else if (addressee.notificationType.length === 0) {
        setErrorMessage("Each addressee is required to have a notification type.");
        valid = false;
        break;
      } else if (addressee.minimumAlertUrgency.length === 0) {
        setErrorMessage("Each addressee is required to have a minimum urgency.");
        valid = false;
        break;
      }
    }
    return valid;
  }

  // Save changes to addressees.
  function saveAddressees() {
    if (addresseesAreValid()) {
      props.onAction({
        type: "SAVE_ADDRESSEES",
        payload: {}
      });
    }
  }

  return (
    <div className="addressee-list my-4">
      <div
        className="list-header p-4"
        style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
      >
        <div className="row align-items-top">
          <div className="list-title col">
            <span className="align-top">Addressees</span>
          </div>

          <div className="col">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => props.onAction({
                type: "CREATE_ADDRESSEE",
                payload: {}
              })}
            >
              <i className="fa fa-fw fa-plus fa-1x" />
            </button>
          </div>
        </div>
      </div>

      <div className="list-body">
        {!props.loading && (
          <table className="table mb-0 pb-0">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">Notification Type</th>
                <th scope="col">Minimum Urgency</th>
                <th scope="col">Enabled</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {props.addressees.map(addressee =>
                <tr
                  className="list-item py-2"
                  key={addressee.addresseeId}
                >
                  <td>
                    <input
                      className="form-control mx-auto"
                      type="text"
                      value={addressee.name}
                      onChange={e => props.onAction({
                        type: "UPDATE_ADDRESSEE",
                        payload: {
                          addresseeId: addressee.addresseeId,
                          name: e.target.value,
                          address: addressee.address,
                          notificationType: addressee.notificationType,
                          minimumAlertUrgency: addressee.minimumAlertUrgency,
                          enabled: addressee.enabled
                        }
                      })}
                    />
                  </td>

                  <td>
                    <input
                      className="form-control mx-auto"
                      type="text"
                      value={addressee.address}
                      onChange={e => props.onAction({
                        type: "UPDATE_ADDRESSEE",
                        payload: {
                          addresseeId: addressee.addresseeId,
                          name: addressee.name,
                          address: e.target.value,
                          notificationType: addressee.notificationType,
                          minimumAlertUrgency: addressee.minimumAlertUrgency,
                          enabled: addressee.enabled
                        }
                      })}
                    />
                  </td>

                  <td>
                    <select
                      className="form-select"
                      value={addressee.notificationType}
                      onChange={e => props.onAction({
                        type: "UPDATE_ADDRESSEE",
                        payload: {
                          addresseeId: addressee.addresseeId,
                          name: addressee.name,
                          address: addressee.address,
                          notificationType: e.target.value,
                          minimumAlertUrgency: addressee.minimumAlertUrgency,
                          enabled: addressee.enabled
                        }
                      })}
                    >
                      <option value="" disabled>
                        Select a notification...
                      </option>
                      {props.notificationTypes.map(notificationType =>
                        <option
                          key={notificationType.notificationTypeId}
                          value={notificationType.value}
                        >
                          {notificationType.value}
                        </option>
                      )}
                    </select>
                  </td>

                  <td>
                    <select
                      className="form-select"
                      value={addressee.minimumAlertUrgency}
                      onChange={e => props.onAction({
                        type: "UPDATE_ADDRESSEE",
                        payload: {
                          addresseeId: addressee.addresseeId,
                          name: addressee.name,
                          address: addressee.address,
                          notificationType: addressee.notificationType,
                          minimumAlertUrgency: e.target.value,
                          enabled: addressee.enabled
                        }
                      })}
                    >
                      <option value="" disabled>
                        Select an urgency...
                      </option>
                      {props.urgencies.map(urgency =>
                        <option
                          key={urgency.urgencyId}
                          value={urgency.value}
                        >
                          {urgency.value}
                        </option>
                      )}
                    </select>
                  </td>

                  <td className="enable-addressee px-3">
                    <input
                      className="form-check-input form-check-big my-auto"
                      type="checkbox"
                      checked={addressee.enabled}
                      onChange={e => props.onAction({
                        type: "UPDATE_ADDRESSEE",
                        payload: {
                          addresseeId: addressee.addresseeId,
                          name: addressee.name,
                          address: addressee.address,
                          notificationType: addressee.notificationType,
                          minimumAlertUrgency: addressee.minimumAlertUrgency,
                          enabled: e.target.checked
                        }
                      })}
                    />
                  </td>

                  <td className="delete-addressee">
                    <button
                      type="button"
                      className="btn btn-danger btn-floating w-100"
                      onClick={() => props.onAction({
                        type: "DELETE_ADDRESSEE",
                        payload: { addresseeId: addressee.addresseeId }
                      })}>
                      <i className="fa fa-times" />
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {props.addressees.length === 0 && !props.loading && (
          <div className="empty-list py-5 px-4">
            There are no addressees to display
          </div>
        )}
      </div>

      {props.addresseesChanged && (
        <div
          className="list-footer p-3"
          style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
        >
          <div className="row align-items-center">
            <div className="col">
              {errorMessage.length > 0 && (
                <Error message={errorMessage} />
              )}
            </div>
            <div className="col-auto">
              <button
                className="btn btn-light me-3"
                onClick={() => saveAddressees()}
              >
                Save
              </button>

              <button
                className="btn btn-light"
                onClick={() => props.onAction({
                  type: "REVERT_ADDRESSEES",
                  payload: {}
                })}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

AddresseeList.propTypes = {
  loading: PropTypes.bool.isRequired,
  notificationTypes: PropTypes.array.isRequired,
  addressees: PropTypes.array.isRequired,
  addresseesChanged: PropTypes.bool.isRequired,
  urgencies: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired
};