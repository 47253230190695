/*
Author:      Zachary Thomas
Created:     10/22/2021
Modified:    7/28/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import LoginPage from "./pages/LoginPage/LoginPage";
import Error404Page from "./pages/Error404Page/Error404Page";
import Error500Page from "./pages/Error500Page/Error500Page";
import Context from "./components/Context/Context";
import EtlProcessManagerRouter from "./routers/EtlProcessManagerRouter/EtlProcessManagerRouter";
import EventlogViewerRouter from "./routers/EventlogViewerRouter/EventlogViewerRouter";
import IotManagerRouter from "./routers/IotManagerRouter/IotManagerRouter";
import NotificationManagerRouter from "./routers/NotificationManagerRouter/NotificationManagerRouter";
import RpmAdministrativeConsoleRouter from "./routers/RpmAdministrativeConsoleRouter/RpmAdministrativeConsoleRouter";
import HomePage from "./pages/HomePage/HomePage";
import { SUBDIRECTORY as EVENTLOGVIEWER_SUBDIRECTORY } from "./utilities/constantsEventlogViewer";
import { SUBDIRECTORY as ETLPROCESSMANAGER_SUBDIRECTORY } from "./utilities/constantsEtlProcessManager";
import { SUBDIRECTORY as NOTIFICATIONMANAGER_SUBDIRECTORY } from "./utilities/constantsNotificationManager";
import { SUBDIRECTORY as IOTMANAGER_SUBDIRECTORY } from "./utilities/constantsIotManager";
import { SUBDIRECTORY as RPMADMINISTRATIVECONSOLE_SUBDIRECTORY } from "./utilities/constantsRpmAdministrativeConsole";
import {
  ADMIN_ROLE,
  OPERATIONS_ROLE,
  BASIC_USER_ROLE
} from "./utilities/constants";
import "./App.css";

// Handles page routing and getting theme data.
export default function App() {
  const currentPage = useLocation().pathname;
  const history = useHistory();
  const [isProd, setIsProd] = useState(false);
  const [title, setTitle] = useState("");
  const [links, setLinks] = useState([]);
  const [role, setRole] = useState(BASIC_USER_ROLE);

  // If the user is not logged in, bring them to the login page, also load environment state.
  useEffect(() => {
    const savedState = localStorage.getItem("isProd");
    const idToken = localStorage.getItem("idToken");
    const savedRole = localStorage.getItem("userRole");

    if (savedState === "true") {
      setIsProd(true);
    } else {
      setIsProd(false);
    }

    if (role !== null) {
      setRole(savedRole);
    }

    if (!idToken || idToken.length === 0) {
      history.push("/login");
    }

  }, []);

  // Handles the setting of the environment state if the toggle in the navbar is switched.
  return (
    <Context.Provider value={{ title, setTitle, links, setLinks, isProd, setIsProd, role, setRole }}>
      <Navbar currentPage={currentPage} />

      <main>
        <div className="app container">
          <Switch>

            <Route exact path="/">
              <Redirect to="/homepage" />
            </Route>

            <Route path="/homepage">
              <HomePage />
            </Route>

            {[ADMIN_ROLE].includes(role) &&
              <Route path={`/${ETLPROCESSMANAGER_SUBDIRECTORY}`}>
                <EtlProcessManagerRouter />
              </Route>
            }


            <Route path={`/${EVENTLOGVIEWER_SUBDIRECTORY}`}>
              <EventlogViewerRouter />
            </Route>


            {[ADMIN_ROLE].includes(role) &&
              <Route path={`/${IOTMANAGER_SUBDIRECTORY}`}>
                <IotManagerRouter />
              </Route>
            }

            {[ADMIN_ROLE].includes(role) &&
              <Route path={`/${NOTIFICATIONMANAGER_SUBDIRECTORY}`}>
                <NotificationManagerRouter />
              </Route>
            }

            {[ADMIN_ROLE, OPERATIONS_ROLE].includes(role) &&
              <Route path={`/${RPMADMINISTRATIVECONSOLE_SUBDIRECTORY}`}>
                <RpmAdministrativeConsoleRouter />
              </Route>
            }

            <Route path="/login">
              <LoginPage />
            </Route>

            <Route path="/error-500">
              <Error500Page />
            </Route>

            <Route path="*">
              <Error404Page />
            </Route>
          </Switch>
        </div>
      </main>

      <Footer isProd={isProd} />
    </Context.Provider>
  );
}
