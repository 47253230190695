/*
Author:      KC Willard
Created:     4/22/2022
Modified:    6/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import Card from "../../../components/Card/Card";
import PropTypes from "prop-types";
import formatTimeToLocal from "../../../utilities/formatTimeToLocal";
import "./MessageHistoryList.scss";

// RPM Administrative Console Message history list
export default function MessageHistoryList(props) {

  return (
    <div className="table-responsive">
      <Card title="System-wide Message History">
        {props.messages.length === 0 && !props.loading ? (
          <div className="empty-list py-5 px-4 text-center">
            There are no messages to display
          </div>
        ) : (
          <table className="table text-center">
            <thead>
              <tr>
                <th scope="col" >Message</th>
                <th scope="col" >Urgency</th>
                <th scope="col" >Message Sent (UTC)</th>
              </tr>
            </thead>
            <tbody>
              {props.messages.map((message) =>
                <tr key={message.messageId}>
                  <td>{message.content}</td>
                  <td>{message.urgency}</td>
                  <td>{formatTimeToLocal(`${message.messageSentUtc}.000Z`)}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

      </Card>
    </div>
  );
}


MessageHistoryList.propTypes = {
  messages: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};