/*
Author:      KC Willard
Created:     4/21/2022
Modified:    4/22/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import Card from "../../../components/Card/Card";
import PropTypes from "prop-types";
import "./NotificationInput.scss";
import formatTitleCase from "../../../utilities/formatTitleCase";
import {
  ALERT_EMERGENCY,
  ALERT_HIGH,
  ALERT_MEDIUM,
  ALERT_LOW,
  ALERT_INFO
} from "../../../utilities/constantsRpmAdministrativeConsole";
import Success from "../../../components/Success/Success";

// RPM Administrative Console Send notification input form
export default function NotificationInput(props) {

  return (
    <div className="pt-5 mb-4">
      <Card title="Send alert to all RPM users">

        <div className="row">
          <div className="col mx-2 my-2">
            <label className="mx-2 my-2 mb-2">
              Alert Body
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={props.alertMessage}
              placeholder={"Enter a message..."}
              onChange={(e) => props.onChangeAlertMessage(e.target.value)}
            />
          </div>
          <div className="col-2 mx-2  my-2">
            <label className="mx-2 my-2 mb-2">
              Alert Urgency
            </label>
            <select
              className="form-select"
              value={props.urgency}
              onChange={(e) => props.onChangeUrgency(e.target.value)}
            >
              <option value={ALERT_INFO.toUpperCase()}>
                {formatTitleCase(ALERT_INFO)}
              </option>

              <option value={ALERT_EMERGENCY.toUpperCase()}>
                {formatTitleCase(ALERT_EMERGENCY)}
              </option>

              <option value={ALERT_HIGH.toUpperCase()}>
                {formatTitleCase(ALERT_HIGH)}
              </option>

              <option value={ALERT_MEDIUM.toUpperCase()}>
                {formatTitleCase(ALERT_MEDIUM)}
              </option>

              <option value={ALERT_LOW.toUpperCase()}>
                {formatTitleCase(ALERT_LOW)}
              </option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="text-center">
            <button className="btn btn-success mx-2 my-2 col-2 " type="button" onClick={() => props.showConfirmSend(true)}>
              Send Alert
            </button>
          </div>
        </div>

        {props.successMessage.length > 0 && (
          <div className="row">
            <div className="col mt-4 mx-2">
              <Success message={props.successMessage} />
            </div>
          </div>
        )}

      </Card>
    </div>
  );
}

NotificationInput.propTypes = {
  alertMessage: PropTypes.string.isRequired,
  urgency: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  onChangeAlertMessage: PropTypes.func.isRequired,
  onChangeUrgency: PropTypes.func.isRequired,
  showConfirmSend: PropTypes.func.isRequired
};