/*
Author:      Zachary Thomas
Created:     3/1/2022
Modified:    8/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

const etlProcessManagerSubdirectory = "etl-manager";

// Constants that are referenced throughout the ETL Process Manager.
module.exports = Object.freeze({
  RPM_DEV_API: "https://mhy7tft9mf.execute-api.us-west-2.amazonaws.com/dev",
  RPM_PROD_API: "https://8b1l4buaye.execute-api.us-west-2.amazonaws.com/prod",
  ETL_DEV_API: "https://kcnkbkgl11.execute-api.us-west-2.amazonaws.com/dev",
  ETL_PROD_API: "https://fxbgubr7a9.execute-api.us-west-2.amazonaws.com/prod",
  SUBDIRECTORY: etlProcessManagerSubdirectory,
  LINKS: [
    { name: "Process Page", url: `/${etlProcessManagerSubdirectory}/process` },
    { name: "Endpoint Page", url: `/${etlProcessManagerSubdirectory}/endpoint` }
  ],
  RPM_PROCESS_TYPES: [
    {
      processType: "azure_extract",
      hasFilter: false,
      filterLabel: "",
      endpointCollectionLabel: "Endpoint Maps",
      hasEndpointList: false,
      sourceEndpointTypes: "resource_type",
      targetEndpointTypes: "table"
    }, {
      processType: "epicor_extract",
      hasFilter: false,
      filterLabel: "",
      endpointCollectionLabel: "Endpoint Maps",
      hasEndpointList: false,
      sourceEndpointTypes: "endpoint",
      targetEndpointTypes: "table"
    }, {
      processType: "database_query",
      hasFilter: false,
      filterLabel: "",
      endpointCollectionLabel: "Endpoints",
      hasEndpointList: true,
      sourceEndpointTypes: "procedure",
      targetEndpointTypes: "procedure"
    }, {
      processType: "database_ingest",
      hasFilter: false,
      filterLabel: "",
      endpointCollectionLabel: "Endpoint Maps",
      hasEndpointList: false,
      sourceEndpointTypes: "file",
      targetEndpointTypes: "table"
    }, {
      processType: "database_extract",
      hasFilter: false,
      filterLabel: "",
      endpointCollectionLabel: "Endpoint Maps",
      hasEndpointList: false,
      sourceEndpointTypes: "procedure",
      targetEndpointTypes: "table"
    }, {
      processType: "azure_batch_creation",
      hasFilter: false,
      filterLabel: "",
      endpointCollectionLabel: "Endpoint Maps",
      hasEndpointList: false,
      sourceEndpointTypes: "file_type",
      targetEndpointTypes: "s3"
    }, {
      processType: "package_management",
      hasFilter: false,
      filterLabel: "",
      endpointCollectionLabel: "Endpoint Maps",
      hasEndpointList: false,
      sourceEndpointTypes: "path",
      targetEndpointTypes: "path"
    }, {
      processType: "database_to_file",
      hasFilter: false,
      filterLabel: "",
      endpointCollectionLabel: "Endpoints",
      hasEndpointList: true,
      sourceEndpointTypes: "procedure",
      targetEndpointTypes: "file_type"
    }
  ],
  MIN_PROCESS_TITLE_LENGTH: 5,
  MAX_PROCESS_TITLE_LENGTH: 255,
  MIN_ENDPOINT_TITLE_LENGTH: 2,
  MAX_ENDPOINT_TITLE_LENGTH: 255,
});