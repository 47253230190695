/*
Author:      Zachary Thomas
Created:     8/26/2021
Modified:    4/15/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { SHOW_TOAST_MILLISECONDS } from "../../utilities/constants";
import "./Toast.scss";

// A toast that displays a message.
export default function Toast(props) {
  // Hide the toast automatically after it has been shown for some time.
  useEffect(() => {
    if (props.show) {
      const newTimerId = setTimeout(() => props.onClose(), SHOW_TOAST_MILLISECONDS);
      return () => {
        clearTimeout(newTimerId);
      };
    }
  }, [props.show]);

  return (
    (props.show === true &&
      <div
        className={
          `toast ${props.show === true ? "show" : "hide"}`
          + ` ${props.type === "error" ? "error-toast" : ""}`
          + ` ${props.type === "success" ? "success-toast" : ""}`
        }
        onClick={() => props.onClose()}
        role="alert"
      >
        <div className="toast-header">
          <strong className="me-auto">{props.title}</strong>
        </div>

        <div className="toast-body">
          {props.message}
        </div>
      </div>
    )
  );
}

Toast.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string
};