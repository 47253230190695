/*
Author:      Zachary Thomas
Created:     3/8/2022
Modified:    6/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import formatTimeToLocal from "../../../../utilities/formatTimeToLocal";
import Modal from "../../../../components/Modal/Modal";
import ModalHeader from "../../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../../components/ModalFooter/ModalFooter";
import "./HistoryModal.scss";

// Modal for viewing history and for downloading files.
export default function HistoryModal(props) {
  return (
    <div className="history-modal">
      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        size="lg"
        backdropClassName={"history-top-level-backdrop"}
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title">
            View History
          </h5>
        </ModalHeader>

        <ModalBody>
          <div className="history-data-block mx-5 my-3">
            <label className="font-weight-bold">Direction</label>
            <p>{props.selectedRecord.direction}</p>
          </div>

          <div className="history-data-block mx-5 my-3">
            <label className="font-weight-bold">Event</label>
            <p>{props.selectedRecord.event}</p>
          </div>

          <div className="history-data-block mx-5 my-3">
            <label className="font-weight-bold">File Location</label>
            <p>{props.selectedRecord.location}</p>
          </div>

          <div className="history-data-block mx-5 my-3">
            <label className="font-weight-bold">Scope</label>
            <p>{props.selectedRecord.scope}</p>
          </div>

          <div className="history-data-block mx-5 my-3">
            <label className="font-weight-bold">Event Date & Time</label>
            <p>{formatTimeToLocal(`${props.selectedRecord.arrivalTime}.000Z`)}</p>
          </div>

          {props.selectedRecord.data !== null && (
            <div className="mx-5 mt-3 mb-4">
              <label className="font-weight-bold">Data</label>
              <pre className="json-query-display mt-2 mb-0 px-4 py-3">
                {JSON.stringify(props.selectedRecord.data, null, 2)}
              </pre>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.selectedRecord.signedUrl.length > 0 && (
            <a href={props.selectedRecord.signedUrl} download>
              <button
                type="button"
                className="btn btn-success me-2"
              >
                Download File
              </button>
            </a>
          )}

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => props.onClose()}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

HistoryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  selectedRecord: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};