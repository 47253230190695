/*
Author:      KC Willard
Created:     2/25/2022
Modified:    6/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext } from "react";
import PropTypes from "prop-types";
import formatTimeToLocal from "../../../utilities/formatTimeToLocal";
import formatTitleCase from "../../../utilities/formatTitleCase";
import { Tab, Tabs } from "react-bootstrap";
import Context from "../../../components/Context/Context";
import "./ProcessList.scss";

// A list of processes that support creation, updating, and deletion.
export default function ProcessList(props) {
  const context = useContext(Context);

  // A function to handle the switching of organization by tabs
  function changeOrganization(key) {
    if (key === "rpm") {
      props.onChangeIsRpm(true);
    }
    if (key === "etl") {
      props.onChangeIsRpm(false);
    }
  }

  return (
    <div className="process-list">
      <div className="list-header p-4"
        style={context.isProd ? { background: "var(--prod-background)" } : { background: "var(--dev-background)" }}
      >
        <div className="row align-items-top">
          <div className="list-title col">
            <span className="align-top">Processes</span>
          </div>

          {/* Button for creating new processes. */}
          <div className="col">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => props.onSelect(0)}
            >
              <i className="fa fa-fw fa-plus fa-1x" />
            </button>
          </div>
        </div>
      </div>
      <div className="organization-tabs"
        style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}>
        <Tabs defaultActiveKey={"rpm"} onSelect={(eventKey) => changeOrganization(eventKey)}>
          <Tab eventKey={"rpm"} title={"RPM"}>

          </Tab>
          <Tab eventKey={"etl"} title={"ETL"}></Tab>
        </Tabs>
      </div>

      <div className="list-body">
        <div className="table-responsive">
          <table className="table table-hover mb-0" >
            <thead>
              <tr>
                <th scope="col" >Process Name</th>
                <th scope="col" >Status</th>
                <th scope="col" >Last Run Time (Local Time)</th>
              </tr>
            </thead>
            <tbody >
              {/* List of interactive processes. */}
              {!props.loading && props.processes.map(process =>
                <tr
                  key={process.processId}
                  onClick={() => props.onSelect(process.processId)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{formatTitleCase(process.processName)}</td>
                  {process.processIsEnabled ? (
                    <td>Enabled</td>
                  ) : (
                    <td>Disabled</td>
                  )}
                  {process.processLastRunUtc.length > 0 ? (
                    <td>{formatTimeToLocal(`${process.processLastRunUtc.replace(" ", "T")}.000Z`)}</td>
                  ) : (
                    <td>Process has not run</td>
                  )}

                </tr>
              )}
            </tbody>
          </table>

          {props.processes.length === 0 && !props.loading && (
            <div className="empty-list py-5 px-4">
              There are no processes to display
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ProcessList.propTypes = {
  loading: PropTypes.bool,
  processes: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isRpm: PropTypes.bool.isRequired,
  onChangeIsRpm: PropTypes.func.isRequired
};