/*
Author:      Zachary Thomas
Created:     4/6/2022
Modified:    4/13/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext } from "react";
import useApi from "../../hooks/useApi";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import PageTitle from "../../components/PageTitle/PageTitle";
import AudienceSelect from "./AudienceSelect/AudienceSelect";
import SubsystemList from "./SubsystemList/SubsystemList";
import Context from "../../components/Context/Context";
import { LINKS, PROD_API, DEV_API } from "../../utilities/constantsNotificationManager";
import "./NotificationManagerSubsystemsPage.scss";

// Page for viewing the list of subsystems.
export default function NotificationManagerSubsystemsPage() {
  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [audienceType, setAudienceType] = useState("");
  const [endUserSubsystems, setEndUserSubsystems] = useState([]);
  const [operationSubsystems, setOperationSubsystems] = useState([]);
  const context = useContext(Context);

  // Setting the title and the links to be displayed in the navbar for the current page.
  useEffect(() => {
    context.setTitle("Notification Manager");
    context.setLinks(LINKS);
  }, []);

  // Get all subsystems from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/subsystem`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        setEndUserSubsystems(responseBody.rpmCustomerSubsystems);
        setOperationSubsystems(responseBody.operationSubsystems);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="page-note-subsystems mb-5">
        <Spinner loading={loading} />

        <PageTitle title="Manage Subsystems" />

        <AudienceSelect
          audienceType={audienceType}
          onChange={(audienceType) => setAudienceType(audienceType)}
        />

        {audienceType.length > 0 && (
          <SubsystemList
            loading={false}
            audienceType={audienceType}
            subsystems={audienceType === "operations" ? operationSubsystems : endUserSubsystems}
          />
        )}
      </div>
    )
  );
}

