/*
Author:      KC Willard
Created:     2/25/2022
Modified:    6/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React, { useState, useEffect, Fragment } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import ProcessForm from "./ProcessForm/ProcessForm";
import PropTypes from "prop-types";
import apiRequest from "../../../utilities/apiRequest";
import EndpointList from "./ProcessForm/EndpointList/EndpointList";
import { MIN_PROCESS_TITLE_LENGTH, MAX_PROCESS_TITLE_LENGTH } from "../../../utilities/constantsEtlProcessManager";
import "./ProcessModal.scss";
import ProcessConflictList from "./ProcessForm/ProcessConflictList/ProcessConflictList";
import deepCopy from "../../../utilities/deepCopy";

// Modal for creating, editing, and deleting processes.
export default function ProcessModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [processName, setProcessName] = useState("");
  const [processTypeId, setProcessTypeId] = useState(0);
  const [processTypeName, setProcessTypeName] = useState("");
  const [processIsEnabled, setProcessIsEnabled] = useState(false);
  const [processEndpoints, setProcessEndpoints] = useState([]);
  const [processEndpointMaps, setProcessEndpointMaps] = useState([]);
  const [processConflictList, setProcessConflictList] = useState([]);
  const [processInterval, setProcessInterval] = useState("");
  const [processLastRunUtc, setProcessLastRunUtc] = useState("");
  const [processEstimatedRunTime, setProcessEstimatedRunTime] = useState("");
  const [processSettingsJson, setProcessSettingsJson] = useState("");
  const [processEndpointMapIndex, setProcessEndpointMapIndex] = useState(0);
  const [processEndpointsIndex, setProcessEndpointsIndex] = useState(0);
  const [processConflictListIndex, setProcessConflictListIndex] = useState(0);


  // Update state when selected process prop changes.
  useEffect(() => {
    let processMapsIndex = 1;
    let processEndpointsIndex = 1;
    const processEndpointMapsCopy = deepCopy(props.selectedProcess.processEndpointMaps);
    const processEndpointsCopy = deepCopy(props.selectedProcess.processEndpoints);
    let processConflictListIndex = 1;
    const processConflictListCopy = deepCopy(props.selectedProcess.processConflictList);

    setProcessName(props.selectedProcess.processName);
    setProcessTypeId(props.selectedProcess.processTypeId);
    setProcessTypeName(props.selectedProcess.processTypeName);
    setProcessIsEnabled(props.selectedProcess.processIsEnabled);
    setProcessInterval(String(props.selectedProcess.processInterval));
    setProcessLastRunUtc(props.selectedProcess.processLastRunUtc);
    setProcessEstimatedRunTime(String(props.selectedProcess.processEstimatedRunTime));
    setProcessSettingsJson(props.selectedProcess.processSettingsJson);

    processEndpointMapsCopy.forEach(element => {
      element.processEndpointMapIndex = processMapsIndex;
      processMapsIndex++;
    });
    setProcessEndpointMapIndex(processMapsIndex);
    setProcessEndpointMaps(processEndpointMapsCopy);

    processEndpointsCopy.forEach(element => {
      element.processEndpointsIndex = processEndpointsIndex;
      processEndpointsIndex++;
    });
    setProcessEndpointsIndex(processEndpointsIndex);
    setProcessEndpoints(processEndpointsCopy);

    processConflictListCopy.forEach(element => {
      element.processConflictListIndex = processConflictListIndex;
      processConflictListIndex++;
    });
    setProcessConflictListIndex(processConflictListIndex);
    setProcessConflictList(processConflictListCopy);

  }, [JSON.stringify(props.selectedProcess)]);

  // Validate the process.
  function processIsValid() {
    if (processName.length < MIN_PROCESS_TITLE_LENGTH || processName.length > MAX_PROCESS_TITLE_LENGTH) {
      setErrorMessage(
        `The title must be between ${MIN_PROCESS_TITLE_LENGTH}`
        + ` and ${MAX_PROCESS_TITLE_LENGTH} characters long.`
      );
      return false;
    } else if (processInterval <= 0) {
      setErrorMessage(
        `The interval must be greater than 0`
      );
      return false;
    } else if (processEstimatedRunTime <= 0) {
      setErrorMessage(
        `The estimated run time must be greater than 0`
      );
      return false;
    } else if (processEndpointMaps.length === 0 && processEndpoints.length === 0) {
      setErrorMessage(
        `Processes must have at least one endpoint associated with them`
      );
      return false;
    } else if (
      processEndpointMaps.some(e => e.sourceEndpointId === -1) ||
      processEndpointMaps.some(e => e.targetEndpointId === -1) ||
      processEndpoints.some(e => e.endpointId === -1)
    ) {
      setErrorMessage(
        `Please select an endpoint.`
      );
      return false;
    } else {
      setErrorMessage("");
      setProcessEstimatedRunTime(parseInt(processEstimatedRunTime));
      setProcessInterval(parseInt(processInterval));
      return true;
    }
  }

  // Create a process.
  async function createProcess() {
    if (processIsValid()) {
      setLoading(true);
      const newProcess = {
        processName: processName,
        processTypeId: processTypeId,
        processTypeName: processTypeName,
        processIsEnabled: processIsEnabled,
        processEndpoints: processEndpoints,
        processEndpointMaps: processEndpointMaps,
        processInterval: processInterval,
        processEstimatedRunTime: processEstimatedRunTime,
        processConflictList: processConflictList,
        processLastRunUtc: processLastRunUtc,
        processSettingsJson: processSettingsJson
      };
      // Make API request to post the new process
      const [response, responseBody] = await apiRequest(
        `${props.apiPath}/process`,
        "POST",
        newProcess
      );
      setLoading(false);

      if (response.ok) {
        newProcess.processId = responseBody.processId;
        props.onAction("CREATE_PROCESS", newProcess);
        setErrorMessage("");
        setLoading(false);
        props.onClose();
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to create process.");

        } else {
          setErrorMessage(responseBody.error);
        }
      }
    }
  }

  // Edit a process.
  async function editProcess(processId) {
    if (processIsValid()) {
      setLoading(true);
      const updatedProcess = {
        processId: processId,
        processName: processName,
        processTypeId: processTypeId,
        processTypeName: processTypeName,
        processIsEnabled: processIsEnabled,
        processEndpoints: processEndpoints,
        processEndpointMaps: processEndpointMaps,
        processInterval: processInterval,
        processEstimatedRunTime: processEstimatedRunTime,
        processConflictList: processConflictList,
        processLastRunUtc: processLastRunUtc,
        processSettingsJson: processSettingsJson
      };
      //  Make API request to update the process
      const [response, responseBody] = await apiRequest(
        `${props.apiPath}/process/${processId}`,
        "PUT",
        updatedProcess
      );
      setLoading(false);

      if (response.ok) {
        props.onAction("UPDATE_PROCESS", updatedProcess);
        setLoading(false);
        setErrorMessage("");
        props.onClose();
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to update process.");

        } else {
          setErrorMessage(responseBody.error);
        }
      }

    }
  }

  // Delete a process.
  async function deleteProcess(processId) {
    setLoading(true);
    // Make API request to delete process
    const [response, responseBody] = await apiRequest(
      `${props.apiPath}/process/${processId}`,
      "DELETE",
      null
    );
    setLoading(false);
    if (response.ok) {
      props.onAction("DELETE_PROCESS", { processId: processId });
      setLoading(false);
      setErrorMessage("");
      props.onClose();
      setShowConfirmDelete(false);
    } else {
      if (response.status >= 500) {
        setErrorMessage("Internal server error. Unable to delete process.");

      } else {
        setErrorMessage(responseBody.error);
      }
    }
    setShowConfirmDelete(false);
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal() {
    let processEndpointMapsEqual = true;
    let processEndpointsEqual = true;
    let processConflictListEqual = true;

    // Check all processEndpoint maps against the prop
    if (processEndpointMaps.length !== props.selectedProcess.processEndpointMaps.length) {
      processEndpointMapsEqual = false;
    } else {
      for (let i = 0; i < processEndpointMaps.length; i++) {
        if (
          processEndpointMaps[i].sourceEndpointId !== props.selectedProcess.processEndpointMaps[i].sourceEndpointId ||
          processEndpointMaps[i].targetEndpointId !== props.selectedProcess.processEndpointMaps[i].targetEndpointId
        ) {
          processEndpointMapsEqual = false;
        }
      }
    }

    // Check all processEndpoints against the prop
    if (processEndpoints.length !== props.selectedProcess.processEndpoints.length) {
      processEndpointsEqual = false;
    } else {
      for (let i = 0; i < processEndpoints.length; i++) {
        if (
          processEndpoints[i].endpointId !== props.selectedProcess.processEndpoints[i].endpointId ||
          processEndpoints[i].endpointDirection !== props.selectedProcess.processEndpoints[i].endpointDirection
        ) {
          processEndpointsEqual = false;
        }
      }
    }

    // Check all processConflictList against the prop
    if (processConflictList.length !== props.selectedProcess.processConflictList.length) {
      processConflictListEqual = false;
    } else {
      for (let i = 0; i < processConflictList.length; i++) {
        if (
          processConflictList[i].processId !== props.selectedProcess.processConflictList[i].processId
        ) {
          processConflictListEqual = false;
        }
      }
    }

    // Check to see if state is the same as props.
    if (
      processName === props.selectedProcess.processName
      && processTypeName === props.selectedProcess.processTypeName
      && processIsEnabled === props.selectedProcess.processIsEnabled
      && processInterval === String(props.selectedProcess.processInterval)
      && processEstimatedRunTime === String(props.selectedProcess.processEstimatedRunTime)
      && processSettingsJson === props.selectedProcess.processSettingsJson
      && processEndpointMapsEqual
      && processEndpointsEqual
      && processConflictListEqual
    ) {
      // Since there have been no changes we can safely exit.
      dontSaveChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges() {
    if (props.mode === "create") {
      createProcess();
    } else {
      editProcess(props.selectedProcess.processId);
    }
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setErrorMessage("");
    props.onClose();
  }

  // Add a new (blank for now) endpoint to the list
  async function addEndpointListEndpoint() {
    const endpointListCopy = deepCopy(processEndpoints);
    setProcessEndpointsIndex(processEndpointsIndex + 1);
    endpointListCopy.unshift({
      processEndpointsIndex: processEndpointsIndex,
      endpointId: -1,
      endpointName: "",
      endpointTypeId: -1,
      endpointTypeName: "",
      endpointDirection: "source"
    });
    setProcessEndpoints(endpointListCopy);
  }

  // Add a new (blank for now) endpoint to the endpoint map
  async function addEndpointMapEndpoint() {
    const endpointMapCopy = deepCopy(processEndpointMaps);
    setProcessEndpointMapIndex(processEndpointMapIndex + 1);
    endpointMapCopy.unshift({
      processEndpointMapIndex: processEndpointMapIndex,
      sourceEndpointId: -1,
      sourceEndpointName: "",
      sourceEndpointTypeId: -1,
      sourceEndpointTypeName: "",
      targetEndpointId: -1,
      targetEndpointName: "",
      targetEndpointTypeId: -1,
      targetEndpointTypeName: ""
    });
    setProcessEndpointMaps(endpointMapCopy);
  }

  // Add a new (blank for now) conflicting process to the conflict list
  async function addProcessConflict() {
    const processConflictListCopy = deepCopy(processConflictList);
    setProcessConflictListIndex(processConflictListIndex + 1);
    processConflictListCopy.unshift({
      processConflictListIndex: processConflictListIndex,
      processId: -1,
      processName: ""
    });
    setProcessConflictList(processConflictListCopy);
  }


  return (
    <div className="process-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            {props.mode === "create" ? (
              <span>Create Process</span>
            ) : (
              <span>Edit Process</span>
            )}
          </h5>
        </ModalHeader>

        <ModalBody>
          <ProcessForm
            mode={props.mode}
            processName={processName}
            processTypeName={processTypeName}
            processInterval={String(processInterval)}
            processIsEnabled={processIsEnabled}
            processLastRunUtc={processLastRunUtc}
            processEstimatedRunTime={String(processEstimatedRunTime)}
            processSettingsJson={processSettingsJson}
            processTypes={props.processTypes}
            onChangeProcessName={processName => setProcessName(processName)}
            onChangeProcessTypeName={processTypeName => setProcessTypeName(processTypeName)}
            onChangeProcessTypeId={processTypeId => setProcessTypeId(processTypeId)}
            onChangeProcessIsEnabled={processIsEnabled => setProcessIsEnabled(processIsEnabled)}
            onChangeProcessInterval={processInterval => setProcessInterval(processInterval)}
            onChangeProcessEstimatedRunTime={processEstimatedRunTime => setProcessEstimatedRunTime(processEstimatedRunTime)}
            onChangeProcessSettingsJson={processSettingsJson => setProcessSettingsJson(processSettingsJson)}
            setErrorMessage={errorMessage => setErrorMessage(errorMessage)}
          />

          {processTypeName.length > 0 && (
            <EndpointList
              processEndpointMaps={processEndpointMaps}
              processEndpoints={processEndpoints}
              endpointTypes={props.endpointTypes}
              endpoints={props.endpoints}
              processTypeName={processTypeName}
              processTypes={props.processTypes}
              addEndpointListEndpoint={() => addEndpointListEndpoint()}
              addEndpointMapEndpoint={() => addEndpointMapEndpoint()}
              onChangeProcessEndpointList={endpoints => setProcessEndpoints(endpoints)}
              onChangeProcessEndpointMaps={endpointMap => setProcessEndpointMaps(endpointMap)}
              setErrorMessage={errorMessage => setErrorMessage(errorMessage)}
            />
          )}

          <ProcessConflictList
            processes={props.processes}
            selectedProcessName={processName}
            processConflictList={processConflictList}
            addProcessConflict={() => addProcessConflict()}
            onChangeProcessConflictList={processConflictList => setProcessConflictList(processConflictList)}
            setErrorMessage={errorMessage => setErrorMessage(errorMessage)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.mode === "create" ? (
            <Fragment>
              <button className="btn btn-success" type="button" onClick={() => createProcess()}>
                Create Process
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button className="btn btn-danger me-auto" type="button" onClick={() => setShowConfirmDelete(true)}>
                Delete
              </button>

              <button className="btn btn-success" type="button" onClick={() => editProcess(props.selectedProcess.processId)}>
                Save Changes
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title="Deletion Confirmation"
        content={"Are you sure that you want to delete the process? This action cannot be undone."}
        yesText="Delete Process"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteProcess(props.selectedProcess.processId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

ProcessModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  showModal: PropTypes.bool.isRequired,
  apiPath: PropTypes.string.isRequired,
  selectedProcess: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  endpoints: PropTypes.array.isRequired,
  endpointTypes: PropTypes.array.isRequired,
  processes: PropTypes.array.isRequired,
  processTypes: PropTypes.array.isRequired
};