/*
Author:      Grayson Fleming
Created:     3/3/2022
Modified:    4/1/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useEffect, useReducer, useState } from "react";
import EndpointList from "./EndpointList/EndpointList";
import EndpointModal from "./EndpointModal/EndpointModal";
import Context from "../../components/Context/Context";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import deepCopy from "../../utilities/deepCopy";
import "./EtlProcessManagerEndpointPage.css";
import { RPM_DEV_API, RPM_PROD_API, ETL_DEV_API, ETL_PROD_API, LINKS } from "../../utilities/constantsEtlProcessManager";
import useApi from "../../hooks/useApi";

// ETL manager endpoint page
export default function EtlProcessManagerEndpointPage() {
  const types = {
    SET_ENDPOINT: "SET_ENDPOINT",
    CREATE_ENDPOINT: "CREATE_ENDPOINT",
    UPDATE_ENDPOINT: "UPDATE_ENDPOINT",
    DELETE_ENDPOINT: "DELETE_ENDPOINT"
  };
  const initialEndpoint = {
    endpointId: 0,
    endpointName: "",
    endpointTypeId: 0,
    endpointTypeName: "",
  };

  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [mode, setMode] = useState("create");
  const [selectedId, setSelectedId] = useState(-1);
  const [selectedEndpoint, setSelectedEndpoint] = useState(initialEndpoint);
  const [endpoints, dispatch] = useReducer(endpointReducer, []);
  const [endpointTypes, setEndpointTypes] = useState([]);
  const [isRpm, setIsRpm] = useState(true);
  const [apiPath, setApiPath] = useState("");
  const context = useContext(Context);

  // Setting the title and the links to be displayed in the navbar for the ETL Manager Endpoint Page
  useEffect(() => {
    context.setTitle("ETL Manager");
    context.setLinks(LINKS);
  }, []);
  // Ensuring that the proper organization is set when the organization tab or environment switch is flipped
  useEffect(() => {
    if (context.isProd === true && isRpm === true) {
      setApiPath(RPM_PROD_API);
    } else if (context.isProd === true && isRpm === false) {
      setApiPath(ETL_PROD_API);
    } else if (context.isProd === false && isRpm === false) {
      setApiPath(ETL_DEV_API);
    } else {
      setApiPath(RPM_DEV_API);
    }
  }, [context.isProd, isRpm]);


  useApi(
    () => {
      setLoading(true);
      // The opening function will only continue to the API call if we return true.
      // This allows us to perform a check to validate form data or whatever else we
      // want to do before calling the API.
      return true;
    },
    {
      method: "GET",
      url: `${apiPath}/endpoint`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        dispatch({ type: types.SET_ENDPOINT, payload: responseBody.endpoints });
        setEndpointTypes(responseBody.endpointTypes);
      } else {
        // If the API did not give a 2xx status code response,
        // then we can decide how we want to deal with errors.
        // In this example we want to display the 500 error page since we
        // are unable to get the list of applications.
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [apiPath]
  );

  // Sort endpoints by id.
  function sortEndpoints(endpoints) {
    return endpoints.sort((a, b) => {
      if (a.endpointName[0].toLowerCase() < b.endpointName[0].toLowerCase()) {
        return -1;
      } else if (a.endpointName[0].toLowerCase() > b.endpointName[0].toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // If the selected ID changes, update the selected endpoint.
  useEffect(() => {
    const index = endpoints.findIndex(endpoint =>
      endpoint.endpointId === selectedId
    );
    if (index === -1) {
      setSelectedEndpoint(initialEndpoint);
    } else {
      setSelectedEndpoint(endpoints[index]);
    }

    if (selectedId === 0) {
      setMode("create");
    } else if (selectedId > 0) {
      setMode("edit");
    }

  }, [selectedId, JSON.stringify(endpoints)]);

  // endpoint reducer.
  function endpointReducer(state, action) {
    switch (action.type) {
      case types.SET_ENDPOINT: {
        action.payload = sortEndpoints(action.payload);
        return action.payload;
      }

      case types.CREATE_ENDPOINT: {
        let stateShallowCopy = [...state, action.payload];
        stateShallowCopy = sortEndpoints(stateShallowCopy);
        return stateShallowCopy;
      }

      case types.UPDATE_ENDPOINT: {
        let stateDeepCopy = deepCopy(state);
        const endpointIndex = state.findIndex(endpoint =>
          endpoint.endpointId === action.payload.endpointId
        );
        if (endpointIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(endpointIndex, 1, action.payload);
          stateDeepCopy = sortEndpoints(stateDeepCopy);
          return stateDeepCopy;
        }
      }

      case types.DELETE_ENDPOINT: {
        const stateDeepCopy = deepCopy(state);
        const endpointIndex = state.findIndex(endpoint =>
          endpoint.endpointId === action.payload.endpointId
        );
        if (endpointIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(endpointIndex, 1);
          return stateDeepCopy;
        }
      }

      default: {
        return state;
      }
    }
  }

  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="page-crud mb-4 mt-3">
        {/* Spinner that shows when API data is loading. */}
        <Spinner loading={loading} />
        <EndpointList
          endpoints={endpoints}
          onSelect={selectedId => setSelectedId(selectedId)}
          isRpm={isRpm}
          onChangeIsRpm={isRpm => setIsRpm(isRpm)}
        />

        <EndpointModal
          mode={mode}
          apiPath={apiPath}
          showModal={selectedId !== -1}
          selectedEndpoint={selectedEndpoint}
          endpoints={endpoints}
          endpointTypes={endpointTypes}
          onClose={() => setSelectedId(-1)}
          onAction={(type, payload) => dispatch({ type: type, payload: payload })}
        />
      </div>
    )

  );
}

