/*
Author:      KC Willard
Created:     1/11/2022
Modified:    3/31/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React, { useContext, useEffect, useReducer, useState } from "react";
import ProcessList from "./ProcessList/ProcessList";
import ProcessModal from "./ProcessModal/ProcessModal";
import Context from "../../components/Context/Context";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import deepCopy from "../../utilities/deepCopy";
import EtlProcessManagerCleanProcessTypes from "../../api/EtlProcessManagerCleanProcessTypes";
import "./EtlProcessManagerProcessPage.scss";
import { RPM_DEV_API, RPM_PROD_API, LINKS, ETL_PROD_API, ETL_DEV_API } from "../../utilities/constantsEtlProcessManager";
import useApi from "../../hooks/useApi";

// A page displaying all processes that are in the database and the ability to create new ones
export default function EtlManagerHomePage() {
  const types = {
    SET_PROCESS: "SET_PROCESS",
    CREATE_PROCESS: "CREATE_PROCESS",
    UPDATE_PROCESS: "UPDATE_PROCESS",
    DELETE_PROCESS: "DELETE_PROCESS"
  };
  const initialProcess = {
    processId: 0,
    processName: "",
    processInterval: "",
    processIsEnabled: false,
    processTypeId: 0,
    processTypeName: "",
    processLastRunUtc: "",
    processEstimatedRunTime: "",
    processSettingsJson: "",
    processEndpointMaps: [],
    processEndpoints: [],
    processConflictList: []
  };

  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [mode, setMode] = useState("create");
  const [selectedId, setSelectedId] = useState(-1);
  const [selectedProcess, setSelectedProcess] = useState(initialProcess);
  const [processes, dispatch] = useReducer(processReducer, []);
  const [endpoints, setEndpoints] = useState([]);
  const [endpointTypes, setEndpointTypes] = useState([]);
  const [processTypes, setProcessTypes] = useState([]);
  const [isRpm, setIsRpm] = useState(true);
  const [apiPath, setApiPath] = useState("");
  const context = useContext(Context);

  // Setting the title and the links to be displayed in the navbar for the ETL Manager Home page
  useEffect(() => {
    context.setTitle("ETL Manager");
    context.setLinks(LINKS);
  }, []);

  // Ensuring that the proper organization is set when the organization tab or environment switch is flipped
  useEffect(() => {
    if (context.isProd === true && isRpm === true) {
      setApiPath(RPM_PROD_API);
    } else if (context.isProd === true && isRpm === false) {
      setApiPath(ETL_PROD_API);
    } else if (context.isProd === false && isRpm === false) {
      setApiPath(ETL_DEV_API);
    } else {
      setApiPath(RPM_DEV_API);
    }
  }, [context.isProd, isRpm]);

  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${apiPath}/process`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        dispatch({ type: types.SET_PROCESS, payload: responseBody.processes });
        setProcessTypes(EtlProcessManagerCleanProcessTypes(responseBody));
        setEndpoints(responseBody.endpoints);
        setEndpointTypes(responseBody.endpointTypes);
      } else {
        // If the API did not give a 2xx status code response,
        // then we can decide how we want to deal with errors.
        // In this example we want to display the 500 error page since we
        // are unable to get the list of applications.
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [apiPath]
  );

  // Sort processes by title.
  function sortProcesses(processes) {
    return processes.sort((a, b) => {
      if (a.processName[0].toLowerCase() < b.processName[0].toLowerCase()) {
        return -1;
      } else if (a.processName[0].toLowerCase() > b.processName[0].toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // If the selected ID changes, update the selected process, and mode.
  useEffect(() => {
    const index = processes.findIndex(process =>
      process.processId === selectedId
    );

    if (index === -1) {
      setSelectedProcess(initialProcess);
    } else {
      setSelectedProcess(processes[index]);
    }

    if (selectedId === 0) {
      setMode("create");
    } else if (selectedId > 0) {
      setMode("edit");
    }
  }, [selectedId, JSON.stringify(processes)]);

  // Process reducer.
  function processReducer(state, action) {
    switch (action.type) {
      case types.SET_PROCESS: {
        action.payload = sortProcesses(action.payload);
        return action.payload;
      }

      case types.CREATE_PROCESS: {
        let stateShallowCopy = [...state, action.payload];
        stateShallowCopy = sortProcesses(stateShallowCopy);
        return stateShallowCopy;
      }

      case types.UPDATE_PROCESS: {
        let stateDeepCopy = deepCopy(state);
        const processIndex = state.findIndex(process =>
          process.processId === action.payload.processId
        );
        if (processIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(processIndex, 1, action.payload);
          stateDeepCopy = sortProcesses(stateDeepCopy);
          return stateDeepCopy;
        }
      }

      case types.DELETE_PROCESS: {
        const stateDeepCopy = deepCopy(state);
        const processIndex = state.findIndex(process =>
          process.processId === action.payload.processId
        );
        if (processIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(processIndex, 1);
          return stateDeepCopy;
        }
      }

      default: {
        return state;
      }
    }
  }



  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      < div className="page-crud mb-4 mt-3" >
        {/* Spinner that shows when API data is loading. */}
        <Spinner loading={loading} />
        {/* Process List header */}
        <ProcessList
          processes={processes}
          onSelect={selectedId => setSelectedId(selectedId)}
          isRpm={isRpm}
          onChangeIsRpm={isRpm => setIsRpm(isRpm)}
        />

        <ProcessModal
          mode={mode}
          showModal={selectedId !== -1}
          apiPath={apiPath}
          selectedProcess={selectedProcess}
          processes={processes}
          endpoints={endpoints}
          endpointTypes={endpointTypes}
          processTypes={processTypes}
          onClose={() => setSelectedId(-1)}
          onAction={(type, payload) => dispatch({ type: type, payload: payload })}
        />
      </div >
    )
  );

}

