/*
Author:      KC Willard
Created:     4/22/2022
Modified:    5/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React from "react";
import PropTypes from "prop-types";
import { MAX_USER_NAME_LENGTH, MAX_USER_EMAIL_LENGTH, ROLES } from "../../../../utilities/constantsRpmAdministrativeConsole";
import "./UserForm.scss";

// Form for creating and editing a user.
export default function UserForm(props) {

  return (
    <div className="user-form">
      <div className="row align-items-center">
        <div className="col mb-3 text-center">
          <label >
            User Name
          </label>
          <input
            className="form-control mx-auto"
            type="text"
            maxLength={MAX_USER_NAME_LENGTH}
            value={props.userName}
            onChange={(e) => props.onChangeUserName(e.target.value)}
          />
        </div>
        {/* If the mode is create, user must input email, otherwise diable the field*/}
        {props.mode === "create" ? (
          <div className="col mb-3 text-center">
            <label >
              User Email
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              maxLength={MAX_USER_EMAIL_LENGTH}
              value={props.userEmail}
              onChange={(e) => props.onChangeUserEmail(e.target.value)}
            />
          </div>
        ) : (
          <div className="col-4 mb-3 text-center">
            <label >
              User Email
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              maxLength={MAX_USER_EMAIL_LENGTH}
              value={props.userEmail}
              disabled={true}
            />
          </div>
        )}
        {/* If the mode is create, disable the user role field, otherwise, make it a dropdown*/}
        {props.mode === "create" ? (
          <span></span>
        ) : (
          <div className="col-4 mb-3 text-center">
            <label>
              User Role
            </label>
            <select
              className="form-select"
              value={props.userRole}
              onChange={(event) => props.onChangeUserRole(event.target.value)}
            >
              {ROLES.map((role, i) => {
                return <option
                  value={role.code}
                  key={i}
                >
                  {role.code}
                </option>;
              })}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

UserForm.propTypes = {
  mode: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userState: PropTypes.string.isRequired,
  onChangeUserName: PropTypes.func.isRequired,
  onChangeUserRole: PropTypes.func.isRequired,
  onChangeUserEmail: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired
};