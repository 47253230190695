/*
Author:      Zachary Thomas
Created:     2/28/2022
Modified:    8/25/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

const iotManagerSubdirectory = "iot-manager";

// Constants that are referenced throughout the IoT Manager.
module.exports = Object.freeze({
  DEV_API: "https://dbjktqx8mi.execute-api.us-west-2.amazonaws.com/dev",
  PROD_API: "https://lav2s5lzmd.execute-api.us-west-2.amazonaws.com/dev",
  SUBDIRECTORY: iotManagerSubdirectory,
  LINKS: [
    { name: "Find Devices", url: `/${iotManagerSubdirectory}/find-devices` }
  ],
  MAX_RESOURCE_DESCRIPTION_LENGTH: 500,
  TOPIC_CODES: ["FOTA", "QUERY"]
});