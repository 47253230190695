/*
Author:      KC Willard
Created:     1/11/2022
Modified:    3/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import { Route, Switch, Redirect, } from "react-router-dom";
import IotManagerFindDevicesPage from "../../pages/IotManagerFindDevicesPage/IotManagerFindDevicesPage";
import IotManagerDevicePage from "../../pages/IotManagerDevicePage/IotManagerDevicePage";
import Error404Page from "../../pages/Error404Page/Error404Page";
import { SUBDIRECTORY } from "../../utilities/constantsIotManager";

// Handles page routing for IoT Manager Dashboard.
export default function IotManagerRouter() {
  return (
    <Switch>
      <Route exact path={`/${SUBDIRECTORY}/`}>
        <Redirect to={`/${SUBDIRECTORY}/find-devices`} />
      </Route>

      <Route path={`/${SUBDIRECTORY}/find-devices`}>
        <IotManagerFindDevicesPage />
      </Route>

      <Route path={`/${SUBDIRECTORY}/device/:deviceType/:deviceClass/:deviceIdentifier`}>
        <IotManagerDevicePage />
      </Route>

      <Route path={`/${SUBDIRECTORY}/device/:deviceType/:deviceClass`}>
        <IotManagerDevicePage />
      </Route>

      <Route path="*">
        <Error404Page />
      </Route>
    </Switch>
  );
}


