/*
Author:      Zachary Thomas
Created:     11/4/2021
Modified:    5/20/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, useContext } from "react";
import { DEV_API, PROD_API, LINKS } from "../../utilities/constantsEventlogViewer";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import DataQuery from "../../components/DataQuery/DataQuery";
import LineChart from "./LineChart/LineChart";
import Error500Page from "../Error500Page/Error500Page";
import apiRequest from "../../utilities/apiRequest";
import Card from "../../components/Card/Card";
import "./EventlogViewerLineChartsPage.css";
import Context from "../../components/Context/Context";

// Page for displaying event log data with line charts.
export default function EventlogViewerLineChartsPage() {
  const initialSeries = [{ name: "", data: [], color: "#000000" }];
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [series, setSeries] = useState(initialSeries);
  const [applicationName, setApplicationName] = useState("");
  const context = useContext(Context);
  const [application, setApplication] = useState("none");
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [eventType, setEventType] = useState("");
  const [recordLimit, setRecordLimit] = useState("50");

  // Setting the title and the links to be displayed in the navbar for the Eventlog Viewer Dashboard
  useEffect(() => {
    context.setTitle("Eventlog Viewer");
    context.setLinks(LINKS);
  }, []);

  useEffect(() => {
    setSeries(initialSeries);
  }, [context.isProd]);

  // Get the list of applications when the page first loads.
  // This is a custom API hook that takes an opening function,
  // an object that describes the API call, a closing function, and a dependency array.
  useApi(
    () => {
      setLoading(true);
      // The opening function will only continue to the API call if we return true.
      // This allows us to perform a check to validate form data or whatever else we
      // want to do before calling the API.
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/application`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      // If the API response is a 2xx status, then we can perform whatever action we want.
      if (response.ok && responseBody) {
        // Clean up the application data until there is a better format.
        const applicationCodes = responseBody.items;
        let applicationList = [];

        // Build the list of applications
        applicationCodes.forEach(application =>
          applicationList.push({
            appId: application.id,
            name: application.name,
          })
        );

        // Sort List of Application Names Alphabetically
        applicationList = applicationList.sort((a, b) => {
          const applicationNameA = a.name.toUpperCase();
          const applicationNameB = b.name.toUpperCase();
          if (applicationNameA < applicationNameB) {
            return -1;
          } else if (applicationNameB > applicationNameA) {
            return 1;
          } else {
            return 0;
          }
        });
        const savedApplicationState = localStorage.getItem("application");
        const savedEventTypeState = localStorage.getItem("eventType");
        const savedRecordLimit = localStorage.getItem("recordLimit");

        if (savedApplicationState) {
          const applicationExists = applicationList.find(application => application.name === savedApplicationState);
          if (applicationExists) {
            setApplication(savedApplicationState);
            setApplicationName(savedApplicationState);
          }
          if (!applicationExists) {
            setApplication(applicationList[0].name);
            setApplicationName(applicationList[0].name);
          }
          if (savedEventTypeState) {
            setEventType(savedEventTypeState);
          }
          if (savedRecordLimit) {
            setRecordLimit(savedRecordLimit);
          }
        }
        setApplicationList(applicationList);
        setFinishedLoading(true);
      } else {
        // If the API did not give a 2xx status code response,
        // then we can decide how we want to deal with errors.
        // In this example we want to display the 500 error page since we
        // are unable to get the list of applications.
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  // Perform a data query against the API.
  async function handleQuery(eventThreadApp, eventType) {
    // If nothing is selected, set all values to zero.
    if (eventThreadApp === "none" && eventType === "none") {
      setSeries(initialSeries);
      return;
    }
    // Get the application's name.
    setApplicationName(applicationName);
    const application = applicationList.find(element => element.name === eventThreadApp);

    const requestBody = null;
    // Call the API.
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/count/application/${application.name}`,
      "GET",
      requestBody
    );
    setLoading(false);

    if (response.ok) {
      const exceptionData = [];
      const businessData = [];
      const informationData = [];
      const verboseData = [];
      const alertData = [];

      // Set exceptionData
      responseBody.items.forEach(item => {
        if (item.eventCounts.length > 0) {
          exceptionData.push(
            [new Date(item.hour), item.eventCounts[0].events[0].count]
          );
        }
      });
      // Set businessData
      responseBody.items.forEach(item => {
        if (item.eventCounts.length > 0) {
          businessData.push(
            [new Date(item.hour), item.eventCounts[0].events[3].count]
          );
        }
      });
      // Set informationData
      responseBody.items.forEach(item => {
        if (item.eventCounts.length > 0) {
          informationData.push(
            [new Date(item.hour), item.eventCounts[0].events[1].count]
          );
        }
      });
      // Set verboseData
      responseBody.items.forEach(item => {
        if (item.eventCounts.length > 0) {
          verboseData.push(
            [new Date(item.hour), item.eventCounts[0].events[4].count]
          );
        }
      });
      // Set alertData
      responseBody.items.forEach(item => {
        if (item.eventCounts.length > 0) {
          alertData.push(
            [new Date(item.hour), item.eventCounts[0].events[2].count]
          );
        }
      });

      const exception = {
        name: "Exception",
        data: exceptionData,
        color: "var(--exception-light)"
      };
      const business = {
        name: "Business Error",
        data: businessData,
        color: "var(--business-light)"
      };
      const information = {
        name: "Information",
        data: informationData,
        color: "var(--information-light)"
      };
      const verbose = {
        name: "Information Verbose",
        data: verboseData,
        color: "var(--verbose-light)"
      };
      const alert = {
        name: "Alert",
        data: alertData,
        color: "var(--alert-light)"
      };

      setSeries([exception, business, information, verbose, alert]);

    } else {
      if (response.status >= 500) {
        setErrorMessage("Internal server error. Unable to get data.");

      } else {
        setErrorMessage(responseBody.error);
      }
    }
  }

  // Everything inside the return is the JSX that we render.
  // Please only keep JSX here. Avoid passing JSX around outside of the return.
  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="page-line-chart mb-4">
        {/* Spinner that shows when API data is loading. */}
        <Spinner loading={loading} />

        {/* Simple page title and icon. */}
        <TextBlurb
          title="Line Chart Data"
          paragraph="Select an application to see how many times each event type has been called across time."
          icon="line-chart"
        />

        {/* Tool for customizing the query. */}
        <DataQuery
          title={"View Data"}
          allowApplication={true}
          allowEvent={false}
          allowLimit={false}
          allowToggle={false}
          allowTimeStart={false}
          allowTimeEnd={false}
          allowIsTest={false}
          error={errorMessage}
          applications={applicationList}
          isProd={context.isProd}
          eventType={eventType}
          recordLimit={recordLimit}
          onChangeEventType={() => { }}
          onChangeRecordLimit={() => { }}
          onQuery={(toggleIsTest, application, eventType) =>
            handleQuery(application, eventType)
          }
          application={application}
          onChangeApplication={(application) => setApplication(application)}
          finishedLoading={finishedLoading}
        />

        {/* Column chart to display event counts. */}
        <div className="line-chart-container mt-5" >
          {series.length > 1 ? (
            <LineChart
              title={`${applicationName} Events`}
              series={series}
              isProd={context.isProd}
            />
          ) : (
            <Card title="No event data found" isProd={context.isProd}>
              <div className="text-center my-5 h5" >
                {applicationList.length > 0 ? (
                  `No event data was found for ${applicationName}.`
                ) : (
                  "Please make a selection and press 'View Data' to get started."
                )}
              </div>
            </Card>
          )}
        </div>
      </div>
    )
  );
}

