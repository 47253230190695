/*
Author:      Zachary Thomas
Created:     3/1/2022
Modified:    8/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

const eventlogViewerSubdirectory = "eventlog-viewer";

// Constants that are referenced throughout the Eventlog Viewer.
module.exports = Object.freeze({
  DEV_API: "https://rnlyz5vcdh.execute-api.us-west-2.amazonaws.com/dev",
  PROD_API: "https://dm41iu6cf5.execute-api.us-west-2.amazonaws.com/prod",
  SUBDIRECTORY: eventlogViewerSubdirectory,
  LINKS: [
    { name: "Records Page", url: `/${eventlogViewerSubdirectory}/records` },
    { name: "Line Charts Page", url: `/${eventlogViewerSubdirectory}/line-charts` }
  ]
});