/*
Author:      Zachary Thomas
Created:     2/22/2022
Modified:    6/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import formatTimeToLocal from "../../../../../utilities/formatTimeToLocal";
import "./Resource.scss";

// A single S3 bucket resource.
export default function Resource(props) {
  return (
    <tr
      className={`iot-resource ${props.selectedResource === props.location ? "selected" : ""}`}
      onClick={() => props.onSelect(props.location)}
    >
      <td className="align-middle">{props.name}</td>
      <td className="align-middle"><small>{props.location}</small></td>
      <td className="align-middle">{props.code}</td>
      <td className="align-middle">{formatTimeToLocal(`${props.arrivalTime}.000Z`)}</td>
    </tr>
  );
}

Resource.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  arrivalTime: PropTypes.string.isRequired,
  selectedResource: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};