/*
Author:      KC Willard
Created:     2/21/2022
Modified:    7/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import RpmAdministrativeConsoleSendNotificationsPage from "../../pages/RpmAdministrativeConsoleSendNotificationsPage/RpmAdministrativeConsoleSendNotificationsPage";
import RpmAdministrativeConsoleManageUsersPage from "../../pages/RpmAdministrativeConsoleManageUsersPage/RpmAdministrativeConsoleManageUsersPage";
import RpmAdministrativeConsoleManageCompaniesPage from "../../pages/RpmAdministrativeConsoleManageCompaniesPage/RpmAdministrativeConsoleManageCompaniesPage";
import RpmAdministrativeConsoleManageDevicesPage from "../../pages/RpmAdministrativeConsoleManageDevicesPage/RpmAdministrativeConsoleManageDevicesPage";
import Error404Page from "../../pages/Error404Page/Error404Page";
import { SUBDIRECTORY } from "../../utilities/constantsRpmAdministrativeConsole";
import {
  ADMIN_ROLE,
} from "../../utilities/constants";
import Context from "../../components/Context/Context";

// Handles page routing for RPM Admin Dashboard.
export default function RpmAdministrativeConsoleRouter() {
  const context = useContext(Context);

  return (
    <Switch>
      <Route exact path={`/${SUBDIRECTORY}/`}>
        <Redirect to={`/${SUBDIRECTORY}/manage-companies`} />
      </Route>

      {[ADMIN_ROLE].includes(context.role) &&
        <Route path={`/${SUBDIRECTORY}/send-notification`}>
          <RpmAdministrativeConsoleSendNotificationsPage />
        </Route>
      }

      {[ADMIN_ROLE].includes(context.role) &&
        <Route path={`/${SUBDIRECTORY}/manage-users`}>
          <RpmAdministrativeConsoleManageUsersPage />
        </Route>
      }

      <Route path={`/${SUBDIRECTORY}/manage-companies`}>
        <RpmAdministrativeConsoleManageCompaniesPage />
      </Route>

      <Route path={`/${SUBDIRECTORY}/manage-devices/:companyId`}>
        <RpmAdministrativeConsoleManageDevicesPage />
      </Route>

      <Route path="*">
        <Error404Page />
      </Route>
    </Switch>
  );
}


