/*
Author:      Zachary Thomas
Created:     3/1/2022
Modified:    8/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

const rpmAdministrativeConsoleSubdirectory = "rpm-admin-console";

// Constants that are referenced throughout the Eventlog Viewer.
module.exports = Object.freeze({
  DEV_API: "https://8b9l4o1tre.execute-api.us-west-2.amazonaws.com/dev",
  PROD_API: "https://vzlwcrcde9.execute-api.us-west-2.amazonaws.com/prod",
  SUBDIRECTORY: rpmAdministrativeConsoleSubdirectory,
  LINKS: [
    { name: "Manage Companies", url: `/${rpmAdministrativeConsoleSubdirectory}/manage-companies` },
    { name: "Send Systemwide Alert", url: `/${rpmAdministrativeConsoleSubdirectory}/send-notification` },
    { name: "Manage RPM Users", url: `/${rpmAdministrativeConsoleSubdirectory}/manage-users` }
  ],
  OPERATIONS_LINKS: [
    { name: "Manage Companies", url: `/${rpmAdministrativeConsoleSubdirectory}/manage-companies` }
  ],
  ALERT_EMERGENCY: "emergency",
  ALERT_HIGH: "high",
  ALERT_MEDIUM: "medium",
  ALERT_LOW: "low",
  ALERT_INFO: "info",
  ROLES: [
    { code: "RPM_USER", name: "RPM User" },
    { code: "RPM_ADMIN", name: "RPM Admin" }
  ],
  MIN_USER_NAME_LENGTH: 3,
  MAX_USER_NAME_LENGTH: 100,
  MIN_USER_EMAIL_LENGTH: 3,
  MAX_USER_EMAIL_LENGTH: 100
});