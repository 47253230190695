/*
Author:      Zachary Thomas
Created:     2/16/2022
Modified:    3/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext } from "react";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import PageTitle from "../../components/PageTitle/PageTitle";
import Card from "../../components/Card/Card";
import Error from "../../components/Error/Error";
import Context from "../../components/Context/Context";
import apiRequest from "../../utilities/apiRequest";
import { Link } from "react-router-dom";
import {
  LINKS,
  DEV_API,
  PROD_API,
  SUBDIRECTORY
} from "../../utilities/constantsIotManager";
import "./IotManagerFindDevicesPage.scss";

// Page for searching devices.
export default function IotManagerFindDevicesPage() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [companies, setCompanies] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceClasses, setDeviceClasses] = useState([]);
  const [companyCode, setCompanyCode] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceClass, setDeviceClass] = useState("");
  const [deviceIdentifier, setDeviceIdentifier] = useState("");
  const [devices, setDevices] = useState([]);
  const context = useContext(Context);

  // Setting the title and the links to be displayed in the navbar for the current page.
  useEffect(() => {
    context.setTitle("IoT Manager");
    context.setLinks(LINKS);
  }, []);

  // Load search settings when the page first loads.
  useEffect(() => {
    const iotSearchJson = localStorage.getItem("iotSearch");
    if (iotSearchJson !== null) {
      const iotSearch = JSON.parse(iotSearchJson);
      if (iotSearch.fields && iotSearch.fields.length === 8) {
        setCompanyCode(iotSearch.fields[0]);
        setDeviceType(iotSearch.fields[1]);
        setDeviceClass(iotSearch.fields[2]);
        setDeviceIdentifier(iotSearch.fields[3]);
      }
    }
  }, []);

  // Save search settings when a change is made to a field.
  useEffect(() => {
    if (companyCode !== ""
      || deviceType !== ""
      || deviceClass !== ""
      || deviceIdentifier !== "") {
      localStorage.setItem("iotSearch",
        `{"fields": ["${companyCode}", "${deviceType}", "${deviceClass}", "${deviceIdentifier}"]}`
      );
    }
  }, [companyCode, deviceType, deviceClass, deviceIdentifier]);

  // Get search data that will be used for selects.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/search/map`,
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        setCompanies(responseBody.companies);
        setDeviceTypes(responseBody.deviceTypes);
        setDeviceClasses([]);
      } else {
        console.error("Internal server error. Unable to retrieve search fields.");
      }
      setLoading(false);
    },
    []
  );

  // Search for devices.
  async function searchDevices() {
    const requestBody = {
      companyCode: companyCode,
      deviceType: deviceType,
      deviceClass: deviceClass,
      deviceIdentifier: deviceIdentifier
    };

    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/search`,
      "POST",
      requestBody
    );
    setLoading(false);

    if (response.ok && responseBody) {
      responseBody.devices.forEach(device =>
        device.class = "COPILOT"
      );
      setDevices(responseBody.devices);
    } else {
      setErrorMessage("Internal server error. Unable to retrieve search results.");
    }
  }

  // Clear search settings.
  function clearSearch() {
    setCompanyCode("");
    setDeviceType("");
    setDeviceClass("");
    setDeviceIdentifier("");
    localStorage.removeItem("iotSearch");
  }

  // Check if the search is clear.
  function searchIsClear() {
    return companyCode === ""
      && deviceType === ""
      && deviceClass === ""
      && deviceIdentifier === "";
  }

  return (
    <div className="page-iot-find-devices mb-5">
      <Spinner loading={loading} />
      <PageTitle title="Device Selection" />
      <Card title="Filter on One or More Devices">
        <div className="search-query-container px-5 pt-3 pb-4 my-0">
          <div className="form-group my-3">
            <label className="my-2">By Company Code</label>
            <input
              className="form-control"
              list="datalist-companies"
              value={companyCode}
              onChange={(e) => setCompanyCode(e.target.value)}
            />
            <datalist id="datalist-companies">
              {companies.map(company =>
                <option key={company} value={company} />
              )}
            </datalist>
          </div>

          <div className="form-group my-3">
            <label className="my-2">By Device Type</label>
            <select
              className="form-select"
              value={deviceType}
              onChange={(e) => setDeviceType(e.target.value)}
            >
              <option value="">Any</option>
              {deviceTypes.map(deviceType =>
                <option key={deviceType} value={deviceType}>{deviceType}</option>
              )}
            </select>
          </div>

          <div className="form-group my-3">
            <label className="my-2">By Device Class</label>
            <select
              className="form-select"
              value={deviceType}
              onChange={(e) => setDeviceClass(e.target.value)}
            >
              <option value="">Any</option>
              {deviceClasses.map(deviceClass =>
                <option key={deviceClass} value={deviceClass}>{deviceClass}</option>
              )}
            </select>
          </div>

          <div className="form-group my-3">
            <label className="my-2">By Device Identifier</label>
            <input
              className="form-control"
              value={deviceIdentifier}
              onChange={(e) => setDeviceIdentifier(e.target.value)}
            />
          </div>

          <div className="row text-center mt-4 mb-2">
            <div className="col">
              <button
                type="button"
                className="search-devices-btn btn btn-success"
                onClick={() => searchDevices()}
              >
                Apply Filter
              </button>
            </div>

            <div className="col">
              <button
                type="button"
                className={`search-devices-btn btn btn-secondary ${searchIsClear() ? "disabled" : ""}`}
                onClick={() => clearSearch()}
              >
                Clear Fields
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Error message={errorMessage} />
            </div>
          </div>
        </div>
        <div className="devices-list">
          {devices.map(device =>
            <div key={device.deviceId} className="device list-item px-4 py-2">
              {device.model !== device.class && (
                <span>{device.model} / </span>
              )}
              <Link to={`/${SUBDIRECTORY}/device/${device.model}/${device.class}`} className="link">
                {device.class}
              </Link>
              <span> / </span>
              <Link to={`/${SUBDIRECTORY}/device/${device.model}/${device.class}/${device.identifier}`} className="link">
                {device.identifier}
              </Link>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

