/*
Author:      Zachary Thomas
Created:     10/29/2021
Modified:    5/20/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useContext, useEffect } from "react";
import { DEV_API, PROD_API, LINKS } from "../../utilities/constantsEventlogViewer";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import DataQuery from "../../components/DataQuery/DataQuery";
import Error500Page from "../Error500Page/Error500Page";
import apiRequest from "../../utilities/apiRequest";
import "./EventlogViewerRecordsPage.css";
import Card from "../../components/Card/Card";
import Record from "./Record/Record";
import RecordModal from "./RecordModal/RecordModal";
import Context from "../../components/Context/Context";

// Page for displaying event log data with column charts.
export default function EventlogViewerRecordsPage() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [records, setRecords] = useState([]);
  const [eventThreadApp, setEventThreadApp] = useState("");
  const [eventType, setEventType] = useState("");
  const [selectedRecordId, setSelectedRecordId] = useState(0);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const context = useContext(Context);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [application, setApplication] = useState("none");
  const [recordLimit, setRecordLimit] = useState("50");

  // Setting the title and the links to be displayed in the navbar for the Eventlog Viewer Dashboard
  useEffect(() => {
    context.setTitle("Eventlog Viewer");
    context.setLinks(LINKS);
  }, []);

  useEffect(() => {
    setRecords([]);
  }, [context.isProd]);

  // Get the list of applications when the page first loads.
  // This is a custom API hook that takes an opening function,
  // an object that describes the API call, a closing function, and a dependency array.
  useApi(
    () => {
      setLoading(true);
      // The opening function will only continue to the API call if we return true.
      // This allows us to perform a check to validate form data or whatever else we
      // want to do before calling the API.
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/application`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      // If the API response is a 2xx status, then we can perform whatever action we want.
      if (response.ok && responseBody) {
        // Clean up the application data until there is a better format.
        const applicationCodes = responseBody.items;
        let applicationList = [];
        // Add the all option to application list
        applicationList.push({
          appId: "none",
          appName: "All",
          eventThreadAppId: "none",
          name: "All"
        });
        // Build the list of applications
        applicationCodes.forEach(application =>
          applicationList.push({
            appId: application.id,
            name: application.name
          })
        );

        // Sort List of Application Names Alphabetically
        applicationList = applicationList.sort((a, b) => {
          const applicationNameA = a.name.toUpperCase();
          const applicationNameB = b.name.toUpperCase();
          if (applicationNameA < applicationNameB) {
            return -1;
          } else if (applicationNameB > applicationNameA) {
            return 1;
          } else {
            return 0;
          }
        });
        const savedApplicationState = localStorage.getItem("application");
        const savedEventTypeState = localStorage.getItem("eventType");
        const savedRecordLimit = localStorage.getItem("recordLimit");

        if (savedApplicationState) {
          const applicationExists = applicationList.find(application => application.name === savedApplicationState);
          if (applicationExists) {
            setApplication(savedApplicationState);
          }
          if (!applicationExists) {
            setApplication(applicationList[0].name);
          }
        }
        if (savedEventTypeState) {
          setEventType(savedEventTypeState);
        }
        if (savedRecordLimit) {
          setRecordLimit(savedRecordLimit);
        }
        setApplicationList(applicationList);
        setFinishedLoading(true);
      } else {
        // If the API did not give a 2xx status code response,
        // then we can decide how we want to deal with errors.
        // In this example we want to display the 500 error page since we
        // are unable to get the list of applications.
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  function isQueryValid(eventThreadApp, eventType, recordLimit) {
    if (eventThreadApp === "none") {
      setErrorMessage("You must select an application");
      return false;
    }
    if (eventType === "none") {
      setErrorMessage("You must select an eventType");
      return false;
    }
    if (recordLimit === "none") {
      setErrorMessage("You must select the amount of records to be fetched");
      return false;
    }
    return true;
  }

  // Perform a data query against the API.
  async function handleQuery(toggleIsTest, eventThreadApp, eventType, recordLimit, timeStart, timeEnd) {
    if (isQueryValid(eventThreadApp, eventType, recordLimit)) {
      setErrorMessage("");
      setEventThreadApp(eventThreadApp);
      setEventType(eventType);
      const requestBody = null;
      let response = null;
      let responseBody = null;

      // Call the API.
      setLoading(true);
      [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/records/application/${eventThreadApp}/eventtype/${eventType}/timestart/${timeStart}/timeend/${timeEnd}/recordlimit/${recordLimit}`,
        "GET",
        requestBody
      );
      setLoading(false);

      if (response.ok) {
        // If toggleIsTest is true, show all records. If it is not, show only records were isTest is false
        const recordList = [];
        if (toggleIsTest) {
          setRecords(responseBody.items.slice(0, recordLimit));
        } else {
          responseBody.items.forEach(record => {
            if (!record.isTest) {
              recordList.push(record);
            }
          });
          setRecords(recordList.slice(0, recordLimit));
        }
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to get data.");

        } else {
          setErrorMessage(responseBody.error);
        }
      }
    }
  }

  // Show the selected record information in a modal
  function showMoreInformation(recordId) {
    setSelectedRecordId(recordId);
    setShowRecordModal(true);
  }

  // Everything inside the return is the JSX that we render.
  // Please only keep JSX here. Avoid passing JSX around outside of the return.
  return (
    failedToLoad ? (
      <Error500Page isProd={context.isProd} />
    ) : (
      <div className="page-records mb-4">
        {/* Spinner that shows when API data is loading. */}
        <Spinner loading={loading} />

        {/* Simple page title and icon. */}
        <TextBlurb
          title="Records"
          paragraph="Select the application you are interested in and specify an event type to see filtered results. Records are displayed from newest to oldest."
          icon="table"
        />

        {/* Tool for customizing the query. */}
        <DataQuery
          title={"View Data"}
          isProd={context.isProd}
          allowApplication={true}
          allowEvent={true}
          allowLimit={true}
          allowToggle={true}
          allowTimeStart={true}
          allowTimeEnd={true}
          allowIsTest={true}
          error={errorMessage}
          applications={applicationList}
          onQuery={(toggleIsTest, eventThreadApp, eventType, recordLimit, timeStart, timeEnd) => handleQuery(toggleIsTest, eventThreadApp, eventType, recordLimit, timeStart, timeEnd)}
          finishedLoading={finishedLoading}
          application={application}
          onChangeApplication={(application) => setApplication(application)}
          eventType={eventType}
          onChangeEventType={(eventType) => setEventType(eventType)}
          recordLimit={recordLimit}
          onChangeRecordLimit={(recordLimit) => setRecordLimit(recordLimit)}
        />
        {/* Records. */}
        <div className="table-responsive">
          {records.length ? (
            <Card title={`${eventType.charAt(0).toUpperCase() + eventType.slice(1)} events for ${eventThreadApp}`} isProd={context.isProd}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" >Application Name</th>
                    <th scope="col" >Event</th>
                    <th scope="col" >Record Arrival (Local Time)</th>
                    <th scope="col" >Record Arrival (UTC)</th>
                    <th scope="col" ></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, i) =>
                    <Record key={i} record={record} index={i} onShowMore={() => showMoreInformation(i)} />
                  )}
                </tbody>
              </table >
              <RecordModal record={records[selectedRecordId]} showRecordModal={showRecordModal} onClose={() => setShowRecordModal(false)}></RecordModal>
            </Card >
          ) : (
            <Card title="No event data found" isProd={context.isProd}>
              <div className="text-center my-5 h5">
                {eventThreadApp.length > 0 ? (
                  `No event data was found for application ${eventThreadApp} with event type: ${eventType}.`
                ) : (
                  "Please make a selection and press 'View Data' to get started."
                )}
              </div>
            </Card>
          )}
        </div >
      </div >
    )
  );
}

