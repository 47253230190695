/*
Author:      Zachary Thomas
Created:     3/8/2022
Modified:    4/15/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext } from "react";
import Context from "../../../components/Context/Context";
import PropTypes from "prop-types";
import Spinner from "../../../components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import Success from "../../../components/Success/Success";
import { TOPIC_CODES, DEV_API, PROD_API } from "../../../utilities/constantsIotManager";
import "./DeviceUpload.scss";

// Form for uploading a file.
export default function DeviceUpload(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [fileData, setFileData] = useState(null);
  const [topicCode, setTopicCode] = useState("");
  const [event, setEvent] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const context = useContext(Context);

  // Update the event based on the current topic code.
  useEffect(() => {
    switch (topicCode) {
      case "FOTA":
        setEvent("Firmware Update");
        break;

      case "QUERY":
        setEvent("Stored Query");
        break;

      default:
        setEvent("");
    }
  }, [topicCode]);

  // If an input field changes, clear error and success messages.
  useEffect(() => {
    setSuccessMessage("");
    setErrorMessage("");
  }, [fileData, topicCode, event, fileDescription]);

  // Check if the file information is valid.
  function fileIsValid() {
    if (fileData === null) {
      setSuccessMessage("");
      setErrorMessage("You must select a file.");
      return false;

    } else if (topicCode.length === 0) {
      setSuccessMessage("");
      setErrorMessage("You must select a topic code.");
      return false;

    } else if (event.length === 0) {
      setSuccessMessage("");
      setErrorMessage("You must enter an event.");
      return false;

    } else {
      return true;
    }
  }

  // Save the file to remote storage.
  async function saveFile() {
    if (!fileIsValid()) {
      return;
    }

    // Multipart/form-data.
    const formData = new FormData();
    formData.append("fileName", fileData.name);
    formData.append("fileData", fileData);
    formData.append("code", topicCode);
    formData.append("class", props.deviceClass);
    formData.append("deviceType", props.deviceType);
    formData.append("fileDescription", fileDescription);
    formData.append("event", event);
    if (props.deviceIdentifier) {
      formData.append("deviceIdentifier", props.deviceIdentifier);
    } else {
      formData.append("deviceIdentifier", null);
    }

    setLoading(true);
    const method = "PUT";
    const url = `${context.isProd ? PROD_API : DEV_API}/resource`;
    const response = await fetch(url, {
      method: method,
      credentials: "include",
      body: formData
    });
    setLoading(false);

    if (response.ok) {
      setSuccessMessage("File saved successfully.");
      setErrorMessage("");
    } else {
      setSuccessMessage("");
      setErrorMessage("Internal server error. Unable to save file.");
    }
  }

  return (
    <div className="iot-device-file-upload">
      <Spinner loading={loading} />
      <div className="device-container px-5 pt-3 pb-4 my-0">
        <div className="mt-4 mb-2">
          <input
            className="form-control mb-3 mx-auto"
            type="file"
            onInput={(e) => setFileData(e.target.files[0])}
          />

          <label>
            Topic Code
          </label>
          <select
            className="form-select mb-3"
            value={topicCode}
            onChange={(e) => setTopicCode(e.target.value)}
          >
            <option value="" disabled>Select a Code...</option>
            {TOPIC_CODES.map(code =>
              <option key={code} value={code}>{code}</option>
            )}
          </select>

          <label>
            Event
          </label>
          <input
            className="form-control mb-3 mx-auto"
            type="text"
            value={event}
            onChange={(e) => setEvent(e.target.value)}
          />

          <label>
            File Description (optional)
          </label>
          <input
            className="form-control mb-4 mx-auto"
            type="text"
            value={fileDescription}
            onChange={(e) => setFileDescription(e.target.value)}
          />

          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="submit-btn btn btn-success mx-auto"
              onClick={() => saveFile()}
            >
              Save
            </button>
          </div>

          <div className="d-flex justify-content-center pt-4">
            <Error message={errorMessage} />
            <Success message={successMessage} />
          </div>
        </div>
      </div>
    </div>
  );
}

DeviceUpload.propTypes = {
  deviceType: PropTypes.string.isRequired,
  deviceClass: PropTypes.string.isRequired,
  deviceIdentifier: PropTypes.string
};