/*
Author:      Zachary Thomas
Created:     9/1/2021
Modified:    5/20/2022

Copyright 2021-2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Error from "../Error/Error";
import "./DataQuery.scss";

// Interactive menu that allows users to query data.
function DataQuery(props) {
  const todaysDate = getDates(0);
  const oneWeekAgo = getDates(7);
  const [toggleIsTest, setToggleIsTest] = useState(false);
  const [timeStart, setTimeStart] = useState(oneWeekAgo);
  const [timeEnd, setTimeEnd] = useState(todaysDate);

  // Set selections to local storage
  useEffect(() => {
    if (props.finishedLoading) {
      localStorage.setItem("application", `${props.application}`);
      localStorage.setItem("eventType", `${props.eventType}`);
      localStorage.setItem("recordLimit", `${props.recordLimit}`);
    }
  }, [
    props.application,
    props.eventType,
    props.finishedLoading,
    props.recordLimit
  ]);

  // A function that returns a date in the format of YYYY-MM-DD
  function getDates(offset) {
    const date = new Date();
    date.setDate(date.getDate() - offset);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = date.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }

  return (
    <div
      className="data-query-tools card container-fluid w-100 p-3 mt-4 mb-4"
      style={props.isProd ? { background: "var(--prod-background)" } : { background: "var(--dev-background)" }}
    >
      <div className={props.allowToggle && props.allowIsTest && props.allowTimeStart && props.allowTimeEnd ? "row" : "row mb-3"}>
        {props.allowApplication && (
          <Fragment>
            <div className={props.allowApplication && props.allowEvent && props.allowLimit ? "col" : "col-10"}>
              <label className="control-label w-80" htmlFor="applicationSelect"><strong>Application</strong></label>
              <select
                className="data-query-form-select w-100"
                value={props.application}
                onChange={(event) => props.onChangeApplication(event.target.value)}
                aria-label="select application"
                id="applicationSelect"
              >
                <option value="none" disabled>Select an application...</option>
                {props.applications.map((application, i) =>
                  <option
                    value={application.code}
                    key={i}
                  >
                    {application.name}
                  </option>
                )}
              </select>
            </div>

          </Fragment>
        )}

        {/* Event Type Select */}
        {props.allowEvent && (
          <div className={props.allowApplication && props.allowEvent && props.allowLimit ? "col" : "col-12"}>
            <label className="control-label w-80" htmlFor="eventTypeSelect"><strong>Event Type</strong></label>
            <select
              className="data-query-form-select w-100"
              value={props.eventType}
              onChange={(event) => props.onChangeEventType(event.target.value)}
              aria-label="select event type"
              id="eventTypeSelect"
            >
              <option value="none" disabled>Select an event type...</option>
              <option value="alert">Alert</option>
              <option value="business-error">Business Error</option>
              <option value="exception">Exception</option>
              <option value="information">Information</option>
              <option value="information-verbose">Information Verbose</option>
            </select>
          </div>
        )}

        {/* Record Limit Select Select */}
        {props.allowLimit && (
          <div className={props.allowApplication && props.allowEvent && props.allowLimit ? "col" : "col-12"}>
            <label className="control-label" htmlFor="limitSelect"><strong>Record No.</strong></label>
            <select
              className="data-query-form-select"
              value={props.recordLimit}
              onChange={(event) => props.onChangeRecordLimit(event.target.value)}
              aria-label="Select # of records to display"
              id="limitSelect"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="400">400</option>
              <option value="500">500</option>
            </select>
          </div>
        )}

        {/* Submit query button. */}
        {(
          <div className="col-2 mx-auto">
            <label className="control-label" htmlFor="viewDataBtn"></label>
            <button
              type="button"
              className="data-query-button btn btn-dark"
              onClick={() => props.onQuery(toggleIsTest, props.application, props.eventType, props.recordLimit, timeStart, timeEnd)}
              id="viewDataBtn"
            >
              View Data
            </button>
          </div>
        )}
      </div>

      {props.allowToggle && props.allowIsTest && props.allowTimeStart && props.allowTimeEnd ?
        (<div className={props.allowToggle && props.allowIsTest && props.allowTimeStart && props.allowTimeEnd ? "row" : ""} >

          {/* Application or EventThread Select */}
          {props.allowToggle && props.allowIsTest && props.allowTimeStart && props.allowTimeEnd && (
            <>
              <div className={props.allowToggle && props.allowIsTest && props.allowTimeStart && props.allowTimeEnd ? "col-4 col-xxl-8" : ""}>

                <label className="control-label mt-3 mx-1" htmlFor="toggleIsTest"><strong>Is testEvent</strong></label>
                <div>
                  <input
                    className="form-check-input mt-2 mx-1"
                    type="checkbox"
                    id="toggleIsTest"
                    onClick={(event) => setToggleIsTest(event.target.checked)}
                  />
                </div>
              </div>
              <div className="col-3 col-xxl-2 mt-2" id="timeStartDatePickerDiv">

                <label className="control-label px-1" htmlFor="timeStartDatePicker"><strong>Start</strong></label>
                <div>
                  <input
                    className="mt-1 mx-1"
                    type="date"
                    id="timeStartDatePicker"
                    value={`${timeStart}`}
                    min={`${oneWeekAgo}`}
                    max={`${todaysDate}`}
                    onChange={(event) => setTimeStart(event.target.value)}
                  />
                </div>
              </div>
              <div className="col-4 col-xxl-2 mt-2" id="timeEndDatePickerDiv">
                <label className="control-label px-1" htmlFor="timeEndDatePicker"><strong>End (Full 24hrs)</strong></label>
                <div>
                  <input
                    className="mt-1 mx-1"
                    type="date"
                    id="timeEndDatePicker"
                    value={`${timeEnd}`}
                    min={`${oneWeekAgo}`}
                    max={`${todaysDate}`}
                    onChange={(event) => setTimeEnd(event.target.value)}
                  />
                </div>
              </div>
            </>
          )}
        </div>) : (
          <span></span>
        )}

      {/* Error message. */}
      <div className={`row ${props.error.length ? "mt-4" : ""}`}>
        <div className="col text-center">
          <Error message={props.error} />
        </div>
      </div>
    </div >
  );
}

export default DataQuery;

DataQuery.propTypes = {
  allowToggle: PropTypes.bool.isRequired,
  allowIsTest: PropTypes.bool.isRequired,
  allowTimeStart: PropTypes.bool.isRequired,
  allowTimeEnd: PropTypes.bool.isRequired,
  allowApplication: PropTypes.bool.isRequired,
  allowEvent: PropTypes.bool.isRequired,
  allowLimit: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  onQuery: PropTypes.func.isRequired,
  applications: PropTypes.array.isRequired,
  isProd: PropTypes.bool.isRequired,
  finishedLoading: PropTypes.bool.isRequired,
  application: PropTypes.string.isRequired,
  onChangeApplication: PropTypes.func.isRequired,
  eventType: PropTypes.string.isRequired,
  onChangeEventType: PropTypes.func.isRequired,
  recordLimit: PropTypes.string.isRequired,
  onChangeRecordLimit: PropTypes.func.isRequired
};