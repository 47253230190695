/*
Author:      KC Willard
Created:     4/22/2022
Modified:    8/25/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  DEV_API,
  PROD_API,
  LINKS
} from "../../utilities/constantsRpmAdministrativeConsole";
import UserList from "./UserList/UserList";
import UserModal from "./UserModal/UserModal";
import Context from "../../components/Context/Context";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import Toast from "../../components/Toast/Toast";
import deepCopy from "../../utilities/deepCopy";
import useApi from "../../hooks/useApi";
import "./RpmAdministrativeConsoleManageUsersPage.scss";

// A page displaying all users that are in the database and the ability to create new ones
export default function RpmAdministrativeConsoleManageUsersPage() {
  const types = {
    SET_USER: "SET_USER",
    CREATE_USER: "CREATE_USER",
    UPDATE_USER: "UPDATE_USER",
    DELETE_USER: "DELETE_USER"
  };
  const initialUser = {
    userId: 0,
    userName: "",
    userState: "",
    userEmail: "",
    userIsEnabled: false,
    userRoleId: 0,
    userRole: "",
  };

  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [mode, setMode] = useState("create");
  const [selectedId, setSelectedId] = useState(-1);
  const [selectedUser, setSelectedUser] = useState(initialUser);
  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [users, dispatch] = useReducer(userReducer, []);
  const context = useContext(Context);

  // Setting the title and the links to be displayed in the navbar RPM Administrative Console Manage Users Page
  useEffect(() => {
    setLoading(true);
    setFailedToLoad(false);
    context.setTitle("RPM Administrative Console");
    context.setLinks(LINKS);
    setLoading(false);
  }, []);

  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/users`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        dispatch({ type: types.SET_USER, payload: responseBody });
      } else {
        // If the API did not give a 2xx status code response,
        // then we can decide how we want to deal with errors.
        // In this example we want to display the 500 error page since we
        // are unable to get the list of applications.
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  // Sort users by Name.
  function sortUsers(users) {
    return users.sort((a, b) => {
      if (a.userName.length < 1 || b.userName.length < 1) {
        return 0;
      } else if (a.userName[0].toLowerCase() < b.userName[0].toLowerCase()) {
        return -1;
      } else if (a.userName[0].toLowerCase() > b.userName[0].toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // If the selected ID changes, update the selected user, and mode.
  useEffect(() => {
    const index = users.findIndex(user =>
      user.userId === selectedId
    );

    if (index === -1) {
      setSelectedUser(initialUser);
    } else {
      setSelectedUser(users[index]);
    }

    if (selectedId === 0) {
      setMode("create");
    } else if (selectedId > 0) {
      setMode("edit");
    }
  }, [selectedId, JSON.stringify(users)]);

  // User reducer.
  function userReducer(state, action) {
    switch (action.type) {
      case types.SET_USER: {
        action.payload = sortUsers(action.payload);
        return action.payload;
      }

      case types.CREATE_USER: {
        let stateShallowCopy = [...state, action.payload];
        stateShallowCopy = sortUsers(stateShallowCopy);
        return stateShallowCopy;
      }

      case types.UPDATE_USER: {
        let stateDeepCopy = deepCopy(state);
        const userIndex = state.findIndex(user =>
          user.userId === action.payload.userId
        );
        if (userIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(userIndex, 1, action.payload);
          stateDeepCopy = sortUsers(stateDeepCopy);
          return stateDeepCopy;
        }
      }

      case types.DELETE_USER: {
        const stateDeepCopy = deepCopy(state);
        const userIndex = state.findIndex(user =>
          user.userId === action.payload.userId
        );
        if (userIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(userIndex, 1);
          return stateDeepCopy;
        }
      }

      default: {
        return state;
      }
    }
  }



  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      < div className="page-crud pt-5 mb-4" >
        {/* Spinner that shows when API data is loading. */}
        <Spinner loading={loading} />

        <UserList
          users={users}
          onSelect={selectedId => setSelectedId(selectedId)}
          loading={loading}
        />

        <UserModal
          mode={mode}
          showModal={selectedId !== -1}
          selectedUser={selectedUser}
          onClose={() => setSelectedId(-1)}
          onChangeToastMessage={(toastMessage) => setToastMessage(toastMessage)}
          onChangeToastTitle={(toastTitle) => setToastTitle(toastTitle)}
          onAction={(type, payload) => dispatch({ type: type, payload: payload })}
        />

        <Toast
          show={toastMessage.length > 0}
          title={toastTitle}
          message={toastMessage}
          type={"success"}
          onClose={() => setToastMessage("")}
        />
      </div >

    )
  );

}

