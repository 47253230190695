/*
Author:      Grayson Fleming
Created:     3/3/2022
Modified:    4/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, Fragment } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import EndpointForm from "./EndpointForm/EndpointForm";
import PropTypes from "prop-types";
import apiRequest from "../../../utilities/apiRequest";
import {
  MIN_ENDPOINT_TITLE_LENGTH,
  MAX_ENDPOINT_TITLE_LENGTH
} from "../../../utilities/constantsEtlProcessManager";
import "./EndpointModal.scss";

// Modal for creating, editing, and deleting endpoints.
export default function EndpointModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [endpointName, setEndpointName] = useState("");
  const [endpointTypeName, setEndpointTypeName] = useState("");
  const [endpointTypeId, setEndpointTypeId] = useState(0);



  // Update state when selected endpoint prop changes.
  useEffect(() => {

    if (props.showModal) {
      setEndpointName(props.selectedEndpoint.endpointName);
      setEndpointTypeName(props.selectedEndpoint.endpointTypeName);
      setEndpointTypeId(props.selectedEndpoint.endpointTypeId);
    }
  }, [props.showModal, JSON.stringify(props.selectedEndpoint)]);



  // Validate the endpoint.
  function endpointIsValid() {
    if (endpointName.length < MIN_ENDPOINT_TITLE_LENGTH || endpointName.length > MAX_ENDPOINT_TITLE_LENGTH) {
      setErrorMessage(
        `The title must be between ${MIN_ENDPOINT_TITLE_LENGTH}`
        + `and ${MAX_ENDPOINT_TITLE_LENGTH} characters long.`
      );
      return false;
    } else {
      return true;
    }
  }

  // Create a endpoint.
  async function createEndpoint() {
    if (endpointIsValid()) {
      setLoading(true);
      const newEndpoint = {
        endpointName: endpointName,
        endpointTypeName: endpointTypeName,
        endpointTypeId: endpointTypeId
      };

      const [response, responseBody] = await apiRequest(
        `${props.apiPath}/endpoint`,
        "POST",
        newEndpoint
      );
      setLoading(false);
      if (response.ok) {
        newEndpoint.endpointId = responseBody.endpointId;
        props.onAction("CREATE_ENDPOINT", newEndpoint);
        setErrorMessage("");
        props.onClose();
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to create endpoint.");

        } else {
          setErrorMessage(responseBody.error);
        }
      }
    }
  }

  // Edit a endpoint.
  async function editEndpoint(endpointId) {
    if (endpointIsValid()) {
      setLoading(true);
      const updatedEndpoint = {
        endpointId: endpointId,
        endpointName: endpointName,
        endpointTypeName: endpointTypeName,
        endpointTypeId: endpointTypeId
      };

      const [response, responseBody] = await apiRequest(
        `${props.apiPath}/endpoint/${endpointId}`,
        "PUT",
        updatedEndpoint
      );
      setLoading(false);
      if (response.ok) {
        props.onAction("UPDATE_ENDPOINT", updatedEndpoint);
        setErrorMessage("");
        setErrorMessage("");
        props.onClose();
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to update endpoint.");

        } else {
          setErrorMessage(responseBody.error);
        }
      }
    }
  }

  // Delete a endpoint.
  async function deleteEndpoint(endpointId) {
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${props.apiPath}/endpoint/${endpointId}`,
      "DELETE",
      null
    );
    setLoading(false);
    if (response.ok) {
      props.onAction("DELETE_ENDPOINT", { endpointId: endpointId });
      setErrorMessage("");
      setLoading(false);
      setShowConfirmDelete(false);
      props.onClose();
    } else {
      if (response.status >= 500) {
        setErrorMessage("Internal server error. Unable to delete endpoint.");

      } else {
        setErrorMessage(responseBody.error);
      }
    }

  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal() {
    // Check to see if state is the same as props.
    if (
      endpointName === props.selectedEndpoint.endpointName
      && endpointTypeName === props.selectedEndpoint.endpointTypeName
    ) {
      // Since there have been no changes we can safely exit.
      dontSaveChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges() {
    if (props.mode === "create") {
      createEndpoint();
    } else {
      editEndpoint(props.selectedEndpoint.endpointId);
    }
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div className="endpoint-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            {props.mode === "create" ? (
              <span>Create Endpoint</span>
            ) : (
              <span>Edit Endpoint</span>
            )}
          </h5>
        </ModalHeader>

        <ModalBody>
          <EndpointForm
            endpointName={endpointName}
            endpointType={endpointTypeName}
            endpointTypes={props.endpointTypes}
            onChangeEndpointName={endpointName => setEndpointName(endpointName)}
            onChangeEndpointTypeName={endpointTypeName => setEndpointTypeName(endpointTypeName)}
            onChangeEndpointTypeId={endpointTypeId => setEndpointTypeId(endpointTypeId)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.mode === "create" ? (
            <Fragment>
              <button className="btn btn-success" type="button" onClick={() => createEndpoint()}>
                Create Endpoint
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button className="btn btn-danger me-auto" type="button" onClick={() => setShowConfirmDelete(true)}>
                Delete
              </button>

              <button className="btn btn-success" type="button" onClick={() => editEndpoint(props.selectedEndpoint.endpointId)}>
                Save Changes
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title="Delete Endpoint?"
        content={"Are you sure that you want to delete the Endpoint? This action cannot be undone."}
        yesText="Delete Endpoint"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteEndpoint(props.selectedEndpoint.endpointId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

EndpointModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  showModal: PropTypes.bool.isRequired,
  apiPath: PropTypes.string.isRequired,
  selectedEndpoint: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  endpoints: PropTypes.array.isRequired,
  endpointTypes: PropTypes.array.isRequired
};