/*
Author:      KC Willard
Created:     1/11/2022
Modified:    3/1/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import { Route, Switch, Redirect, } from "react-router-dom";
import EventlogViewerRecordsPage from "../../pages/EventlogViewerRecordsPage/EventlogViewerRecordsPage";
import EventlogViewerLineChartsPage from "../../pages/EventlogViewerLineChartsPage/EventlogViewerLineChartsPage";
import Error404Page from "../../pages/Error404Page/Error404Page";
import { SUBDIRECTORY } from "../../utilities/constantsEventlogViewer";

// Handles page routing for Eventlog Viewer Dashboard.
export default function EventlogViewerRouter() {
  return (
    <Switch>
      <Route exact path={`/${SUBDIRECTORY}/`}>
        <Redirect to={`/${SUBDIRECTORY}/records`} />
      </Route>

      <Route path={`/${SUBDIRECTORY}/records`}>
        <EventlogViewerRecordsPage />
      </Route>

      <Route path={`/${SUBDIRECTORY}/line-charts`}>
        <EventlogViewerLineChartsPage />
      </Route>

      <Route path="*">
        <Error404Page />
      </Route>
    </Switch>
  );
}
