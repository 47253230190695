/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    5/20/2022

Copyright 2021-2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext } from "react";
import PropTypes from "prop-types";
import NavbarLink from "./NavbarLink/NavbarLink";
import { Link } from "react-router-dom";
import SignOutButton from "./SignOutButton/SignOutButton";
import Context from "../Context/Context";
import "./Navbar.scss";

// General navbar.
export default function Navbar(props) {
  const context = useContext(Context);

  // Updating the context isProd value and save to local storage
  function updateIsProd(isProd) {
    context.setIsProd(isProd);
    localStorage.setItem("isProd", isProd);
  }

  return (
    <nav
      className="navbar navbar-expand navbar-custom"
      style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
    >
      <Link
        className="navbar-brand mx-2 px-3"
        style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }} to={props.currentPage === "/login" ? "/login" : "/"}
      >
        {context.title}
      </Link>

      <div className="collapse navbar-collapse">
        {props.currentPage !== "/login" && (

          <ul className="navbar-nav me-auto" >
            {context.links.map((link, i) =>
              <NavbarLink key={i} name={link.name} targetPage={link.url} currentPage={props.currentPage} />
            )}
          </ul>
        )}

        <div className="form-check form-switch mx-3 mt-1 ">
          {context.isProd ? (
            <label className="control-label " htmlFor="toggleEnvironment"><strong>Environment: Prod</strong></label>
          ) : (
            <label className="control-label" htmlFor="toggleEnvironment"><strong>Environment: Dev</strong></label>
          )}
          <input
            className="form-check-input"
            type="checkbox"
            id="toggleEnvironment"
            checked={context.isProd}
            onChange={(event) => updateIsProd(event.target.checked)}
          >
          </input>
        </div>

        {props.currentPage !== "/login" && (
          <div className="mx-2">
            <SignOutButton />
          </div>
        )}
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  currentPage: PropTypes.string.isRequired,
};