/*
Author:      Zachary Thomas
Created:     4/7/2022
Modified:    6/16/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext, Fragment } from "react";
import useApi from "../../hooks/useApi";
import apiRequest from "../../utilities/apiRequest";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import PermanentlyDeleteModal from "../../components/PermanentlyDeleteModal/PermanentlyDeleteModal";
import TemplateList from "./TemplateList/TemplateList";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmationButtons from "../../components/ConfirmationButtons/ConfirmationButtons";
import Context from "../../components/Context/Context";
import Toast from "../../components/Toast/Toast";
import { LINKS, SUBDIRECTORY, PROD_API, DEV_API } from "../../utilities/constantsNotificationManager";
import { useParams, useHistory } from "react-router-dom";
import "./NotificationManagerSubsystemPage.scss";

// Page for viewing a single subsystem.
export default function NotificationManagerSubsystemPage() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [tempName, setTempName] = useState("");
  const [description, setDescription] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [tempDefaultEmail, setTempDefaultEmail] = useState("");
  const [defaultSms, setDefaultSms] = useState("");
  const [tempDefaultSms, setTempDefaultSms] = useState("");
  const [templates, setTemplates] = useState([]);
  const [urgencies, setUrgencies] = useState([]);
  const [deleteError, setDeleteError] = useState("");
  const [allowUpdate, setAllowUpdate] = useState(false);
  const { audienceType, subsystemId } = useParams();
  const context = useContext(Context);
  const history = useHistory();

  // Setting the title and the links to be displayed in the navbar for the current page.
  useEffect(() => {
    context.setTitle("Notification Manager");
    context.setLinks(LINKS);
  }, []);

  // Get info about the current subsystem from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/subsystem/${audienceType}/${subsystemId}`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        setCode(responseBody.code);
        setDefaultEmail(responseBody.defaultEmail);
        setTempDefaultEmail(responseBody.defaultEmail);
        setDefaultSms(responseBody.defaultSms);
        setTempDefaultSms(responseBody.defaultSms);
        setName(responseBody.name);
        setTempName(responseBody.name);
        setDescription(responseBody.description);
        setTempDescription(responseBody.description);
        setTemplates(responseBody.templates);
        setUrgencies(responseBody.urgencies);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  // Update any changes that are saved.
  useEffect(() => {
    async function updateSubsystem() {
      const requestBody = {
        code: code,
        name: name,
        description: description,
        defaultEmail: defaultEmail,
        defaultSms: defaultSms
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/subsystem/${audienceType}/${subsystemId}`,
        "PUT",
        requestBody
      );
      setLoading(false);

      if (!response.ok) {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to update subsystem.");
        }
      }
    }

    if (allowUpdate) {
      updateSubsystem();
    }
  }, [code, name, description, defaultEmail, defaultSms, allowUpdate]);

  // Delete a subsystem.
  async function deleteSubsystem() {
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/subsystem/${audienceType}/${subsystemId}`,
      "DELETE",
      null
    );
    setLoading(false);

    if (response.ok) {
      setShowConfirmDelete(false);
      history.push(`/${SUBDIRECTORY}/manage-subsystems`);

    } else {
      if (response.status < 500 && responseBody.error) {
        setDeleteError(responseBody.error);
      } else {
        setDeleteError("Internal server error. Unable to update subsystem.");
      }
    }
  }

  // Accept a value for updating.
  function acceptValue(setFunction, value) {
    setFunction(value);
    setAllowUpdate(true);
  }

  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="page-note-subsystem mb-5">
        <Spinner loading={loading} />

        <PageTitle title="Edit Subsystem" />

        <div className="row">
          <div className="col">
            <BreadCrumbs
              title="Edit Subsystem"
              links={[
                {
                  title: "Manage Subsystems",
                  url: `/${SUBDIRECTORY}/manage-subsystems`
                }
              ]}
            />
          </div>

          <div className="col">
            <button
              className="delete-btn btn btn-danger float-end"
              onClick={() => setShowConfirmDelete(true)}
            >
              Delete Subsystem
            </button>
          </div>
        </div>

        <label className="font-weight-bold mb-2">
          Code
        </label>
        <input
          className="form-control mx-auto"
          type="text"
          value={code}
          disabled
        />

        <label className="font-weight-bold mt-4 mb-2">
          Name
        </label>
        <input
          className="form-control mx-auto"
          type="text"
          value={tempName}
          onChange={e => setTempName(e.target.value)}
        />

        {name !== tempName && (
          <ConfirmationButtons
            onAccept={() => acceptValue(setName, tempName)}
            onCancel={() => setTempName(name)}
          />
        )}

        {audienceType === "operations" && (
          <Fragment>
            <label className="font-weight-bold mt-4 mb-2">
              Default Sender Email
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={tempDefaultEmail}
              onChange={e => setTempDefaultEmail(e.target.value)}
            />

            {defaultEmail !== tempDefaultEmail && (
              <ConfirmationButtons
                onAccept={() => acceptValue(setDefaultEmail, tempDefaultEmail)}
                onCancel={() => setTempDefaultEmail(defaultEmail)}
              />
            )}

            <label className="font-weight-bold mt-4 mb-2">
              Default Sender SMS
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={tempDefaultSms}
              onChange={e => setTempDefaultSms(e.target.value)}
            />

            {defaultSms !== tempDefaultSms && (
              <ConfirmationButtons
                onAccept={() => acceptValue(setDefaultSms, tempDefaultSms)}
                onCancel={() => setTempDefaultSms(defaultSms)}
              />
            )}
          </Fragment>
        )}

        <label className="font-weight-bold mt-4 mb-2">
          Description
        </label>
        <input
          className="form-control mx-auto"
          type="text"
          value={tempDescription}
          onChange={e => setTempDescription(e.target.value)}
        />

        {description !== tempDescription && (
          <ConfirmationButtons
            onAccept={() => acceptValue(setDescription, tempDescription)}
            onCancel={() => setTempDescription(description)}
          />
        )}

        <TemplateList
          loading={false}
          audienceType={audienceType}
          subsystemId={parseInt(subsystemId, 10)}
          templates={templates}
          urgencies={urgencies}
        />

        <Toast
          title="Failed to Update Subsystem"
          message={errorMessage}
          show={errorMessage.length > 0}
          onClose={() => setErrorMessage("")}
          type="error"
        />

        <PermanentlyDeleteModal
          showModal={showConfirmDelete}
          title={`Delete Subsystem`}
          content={
            `Are you sure that you want to delete the subsystem '${code}'?`
            + ` All associated templates will also be deleted.`
          }
          onDelete={() => deleteSubsystem()}
          onCancel={() => setShowConfirmDelete(false)}
          errorMessage={deleteError}
        />
      </div>
    )
  );
}