/*
Author:      KC Willard
Created:     1/11/2022
Modified:    3/25/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import { Route, Switch, Redirect, } from "react-router-dom";
import EtlProcessManagerProcessPage from "../../pages/EtlProcessManagerProcessPage/EtlProcessManagerProcessPage";
import EtlProcessManagerEndpointPage from "../../pages/EtlProcessManagerEndpointPage/EtlProcessManagerEndpointPage";
import Error404Page from "../../pages/Error404Page/Error404Page";
import { SUBDIRECTORY } from "../../utilities/constantsEtlProcessManager";

// Handles page routing for ETL Manager Dashboard.
export default function EtlManagerRouter() {
  return (
    <Switch>
      <Route exact path={`/${SUBDIRECTORY}/`}>
        <Redirect to={`/${SUBDIRECTORY}/process`} />
      </Route>

      <Route path={`/${SUBDIRECTORY}/process`}>
        <EtlProcessManagerProcessPage />
      </Route>

      <Route path={`/${SUBDIRECTORY}/endpoint`}>
        <EtlProcessManagerEndpointPage />
      </Route>

      <Route path="*">
        <Error404Page />
      </Route>
    </Switch>
  );
}


