/*
Author:      KC Willard
Created:     1/11/2022
Modified:    4/7/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import { Route, Switch, Redirect, } from "react-router-dom";
import NotificationManagerSubsystemsPage from "../../pages/NotificationManagerSubsystemsPage/NotificationManagerSubsystemsPage";
import NotificationManagerSubsystemPage from "../../pages/NotificationManagerSubsystemPage/NotificationManagerSubsystemPage";
import NotificationManagerTemplatePage from "../../pages/NotificationManagerTemplatePage/NotificationManagerTemplatePage";
import Error404Page from "../../pages/Error404Page/Error404Page";
import { SUBDIRECTORY } from "../../utilities/constantsNotificationManager";

// Handles page routing for Notification Manager Dashboard.
export default function NotificationManagerRouter() {
  return (
    <Switch>
      <Route exact path={`/${SUBDIRECTORY}/`}>
        <Redirect to={`/${SUBDIRECTORY}/manage-subsystems`} />
      </Route>

      <Route path={`/${SUBDIRECTORY}/manage-subsystems`}>
        <NotificationManagerSubsystemsPage />
      </Route>

      <Route path={`/${SUBDIRECTORY}/subsystem/:audienceType/:subsystemId/template/:templateId`}>
        <NotificationManagerTemplatePage />
      </Route>

      <Route path={`/${SUBDIRECTORY}/subsystem/:audienceType/:subsystemId`}>
        <NotificationManagerSubsystemPage />
      </Route>

      <Route path="*">
        <Error404Page />
      </Route>
    </Switch>
  );
}

