/*
Author:      KC Willard
Created:     2/21/2022
Modified:    5/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useContext, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Context from "../../components/Context/Context";
import apiRequest from "../../utilities/apiRequest";
import "./RpmAdministrativeConsoleSendNotificationsPage.css";
import {
  DEV_API,
  PROD_API,
  LINKS,
} from "../../utilities/constantsRpmAdministrativeConsole";
import Error404Page from "../Error404Page/Error404Page";
import Error500Page from "../Error500Page/Error500Page";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import NotificationInput from "./NotificationInput/NotificationInput";
import MessageHistoryList from "./MessageHistoryList/MessageHistoryList";
import useApi from "../../hooks/useApi";


// RPM Administrative Console Send notification page
export default function RpmAdministrativeConsoleSendNotificationsPage() {
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmSend, setShowConfirmSend] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [urgency, setUrgency] = useState("INFO");
  const [messageList, setMessageList] = useState([]);
  const context = useContext(Context);

  async function sendMessage() {
    setShowConfirmSend(false);
    const requestBody = {
      alertCode: "ANNOUNCEMENT",
      urgency: urgency,
      alertMessage: alertMessage,
      isEnabled: "1"
    };

    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/alert`,
      "POST",
      requestBody
    );
    setLoading(false);

    if (response.ok) {
      setErrorMessage("");
      setSuccessMessage("Alert has been sent to all users");

    } else {
      if (response.status < 500 && responseBody.error) {
        setErrorMessage(responseBody.error);
      } else {
        setErrorMessage("Internal server error. Unable to send alert message.");
      }
    }
  }

  // Setting the title and the links to be displayed in the navbar for the RPM administrative console page
  useEffect(() => {
    context.setTitle("RPM Administrative Console");
    context.setLinks(LINKS);
  }, []);

  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/alert`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        setMessageList(responseBody);
      } else {
        // If the API did not give a 2xx status code response,
        // then we can decide how we want to deal with errors.
        // In this example we want to display the 500 error page since we
        // are unable to get the list of applications.
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );
  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="pt-5 mb-4">
        <Spinner loading={loading} />
        <NotificationInput
          alertMessage={alertMessage}
          urgency={urgency}
          successMessage={successMessage}
          onChangeAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
          onChangeUrgency={(urgency) => setUrgency(urgency)}
          showConfirmSend={(value) => setShowConfirmSend(value)}
        />


        <MessageHistoryList
          messages={messageList}
          loading={loading}
        />

        < ConfirmModal
          showModal={showConfirmSend}
          title="Send Alert Confirmation"
          content={"Are you sure you'd like to send this alert to all RPM Users"}
          yesText="Send Alert"
          noText="Cancel"
          danger={true}
          onClose={() => setShowConfirmSend(false)}
          onYes={() => sendMessage()}
          onNo={() => setShowConfirmSend(false)}
        />


        {errorMessage.length > 0 && (
          <div className="row ">
            <div className="col mt-4 mx-2">
              <Error404Page message={errorMessage} />
            </div>
          </div>
        )}

      </div>
    )
  );
}

