/*
Author:      KC Willard
Created:     10/19/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./TestMessageList.scss";

// A list of messages.
export default function TestMessageList(props) {

  return (
    <div className="messageList">
      {props.messageList.map((message, i) =>
        <div className="row mt-2 mb-2" key={message.messageListId}>
          <div className="col-1">
            <label>
              Line {i}
            </label>
          </div>
          <div className="col">
            <input
              className="form-control mx-auto"
              type="text"
              value={message.message}
              onChange={(e) => props.onAction("UPDATE_MESSAGELIST", { messageListId: message.messageListId, message: e.target.value })}
            />
          </div>
          <div className="col-1">
            <button
              className="delete-btn btn btn-danger float-end"
              onClick={() => props.onAction("DELETE_MESSAGELIST", message.messageListId)}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        </div>
      )}
    </div >
  );
}

TestMessageList.propTypes = {
  messageList: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired
};