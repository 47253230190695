/*
Author:      KC Willard
Created:     10/19/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Convert local timestamp to UTC timestamp.
export default function formatTimeToUtc(timestamp) {
  return new Date(timestamp.getUTCFullYear(),
    timestamp.getUTCMonth(),
    timestamp.getUTCDate(),
    timestamp.getUTCHours(),
    timestamp.getUTCMinutes(),
    timestamp.getUTCSeconds());
}