/*
Author:      KC Willard
Created:     12/20/2021
Modified:    1/10/2022

Copyright 2021-2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./RecordModal.css";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";

// Example component to be used as a simple template for other components.
export default function RecordModal(props) {

  return (
    <Modal
      show={props.showRecordModal}
      onHide={() => props.onClose()}
      size="l"
      animation
      centered
      id="recordModal"
    >
      <ModalHeader>
        <h5 className="modal-title font-weight-bold">
          Event Thread: {props.record.eventThread}
        </h5>
      </ModalHeader>

      <ModalBody>
        <div>
          <code>
            <p>&quot;eventThreadKey&quot;: {props.record.eventThreadKey}</p>
            <p>&quot;recordArrivalUTC&quot;: {props.record.recordArrivalUTC}</p>
            <p>&quot;event&quot;: {props.record.event}</p>
            <p>&quot;eventThreadApp&quot;: {props.record.eventThreadApp}</p>
            <p>&quot;messageCreationUTC&quot;: {props.record.messageCreationUTC}</p>
            <p>&quot;eventType&quot;: {props.record.eventType}</p>
            <p>&quot;application&quot;: {props.record.application}</p>
            <p>&quot;description&quot;: {props.record.description}</p>
            <p>&quot;isTest&quot;: {props.record.isTest ? "true" : "false"}</p>
          </code>
        </div>
      </ModalBody>

    </Modal>

  );
}

RecordModal.propTypes = {
  record: PropTypes.object.isRequired,
  showRecordModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};