/*
Author:      Zachary Thomas
Created:     3/8/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import deepCopy from "../utilities/deepCopy";
import changeKeyName from "../utilities/changeKeyName";

// Clean up and format GET device class history API response body.
export default function IotManagerCleanDeviceClassHistory(responseBody) {
  let responseBodyDeepCopy = deepCopy(responseBody);

  // Wrap the response.
  responseBodyDeepCopy = {
    history: responseBodyDeepCopy
  };

  // Update the UTC timestamp to have the correct format.
  responseBodyDeepCopy.history.forEach(record => {
    changeKeyName(record, "recordArrivalUTC", "arrivalTime");
    const lu = record.arrivalTime;
    const arrivalTime = `${lu.slice(0, 4)}-${lu.slice(4, 6)}-${lu.slice(6, 11)}:${lu.slice(11, 13)}:${lu.slice(13)}`;
    record.arrivalTime = arrivalTime;

    if (record.deviceIdentifier) {
      record.scope = "Device";
    } else {
      record.scope = "Class";
    }

    if (!record.location) {
      record.location = "None";
    }

    if (!record.signedUrl) {
      record.signedUrl = "";
    }

    if (!record.data) {
      record.data = null;
    }

    if (!record.event) {
      record.event = "Not recorded";
    }
  });

  console.log("Processed Response Body: ", responseBodyDeepCopy);
  return responseBodyDeepCopy;
}