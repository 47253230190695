/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    2/14/2022

Copyright 2021-2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./TitleBar.css";
import Context from "../../components/Context/Context";

// Simple bar with title.
export default function TitleBar(props) {
  const context = useContext(Context);
  return (
    <div
      className={`title-bar title-bar-size-${props.size} py-3`}
      style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
    >
      {props.title}
    </div>
  );
}

TitleBar.propTypes = {
  size: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};