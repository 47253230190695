/*
Author:      Zachary Thomas
Created:     4/6/2022
Modified:    4/12/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import TitleBar from "../../../components/TitleBar/TitleBar";
import "./AudienceSelect.scss";

// Drop down for selecting a target audience.
export default function AudienceSelect(props) {
  return (
    <div className="audience-select">
      <TitleBar size={2} title="Audience" />
      <select
        className="form-select"
        value={props.audienceType}
        onChange={(e) => props.onChange(e.target.value)}
      >
        <option value="" disabled>Please select an audience...</option>
        <option value="operations">Operations</option>
        <option value="rpm-customers">RPM Customers</option>
      </select>
    </div>
  );
}

AudienceSelect.propTypes = {
  audienceType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};