/*
Author:      KC Willard
Created:     4/22/2022
Modified:    5/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React, { useState, useEffect, Fragment, useContext } from "react";
import Context from "../../../components/Context/Context";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import UserForm from "./UserForm/UserForm";
import PropTypes from "prop-types";
import apiRequest from "../../../utilities/apiRequest";
import {
  MIN_USER_NAME_LENGTH,
  MAX_USER_NAME_LENGTH,
  MIN_USER_EMAIL_LENGTH,
  MAX_USER_EMAIL_LENGTH,
  DEV_API,
  PROD_API,
} from "../../../utilities/constantsRpmAdministrativeConsole";
import "./UserModal.scss";

// Modal for creating, editing, and deleting users.
export default function UserModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userState, setUserState] = useState("");
  const context = useContext(Context);

  // Update state when selected user prop changes.
  useEffect(() => {
    setUserName(props.selectedUser.userName);
    setUserRole(props.selectedUser.userRole);
    setUserEmail(props.selectedUser.userEmail);
    setUserState(props.selectedUser.userState);
  }, [JSON.stringify(props.selectedUser)]);

  // Validate the user.
  function userIsValid() {
    if (userName.length < MIN_USER_NAME_LENGTH || userName.length > MAX_USER_NAME_LENGTH) {
      setErrorMessage(
        `The user name must be between ${MIN_USER_NAME_LENGTH}`
        + ` and ${MAX_USER_NAME_LENGTH} characters long.`
      );
      return false;
    } else if (userEmail.length < MIN_USER_EMAIL_LENGTH || userEmail.length > MAX_USER_EMAIL_LENGTH) {
      setErrorMessage(
        `The email must be between ${MIN_USER_EMAIL_LENGTH}`
        + ` and ${MAX_USER_EMAIL_LENGTH} characters long.`
      );
      return false;
    } else {
      setErrorMessage("");
      return true;
    }
  }

  // Create a user.
  async function createUser() {
    if (userIsValid()) {
      const newUser = {
        userName: userName,
        userEmail: userEmail,
      };
      setLoading(true);
      // Make API request to post the new user
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/users`,
        "POST",
        newUser
      );
      setLoading(false);
      if (response.ok) {
        newUser.userId = responseBody.userId;
        newUser.userRole = responseBody.userRole;
        newUser.userState = responseBody.userState;
        props.onAction("CREATE_USER", newUser);
        props.onChangeToastMessage(context.isProd ? `User ${userName} has been added to rpm-squared.com` : `User ${userName} has been added to rpm-squared-dev.com`);
        props.onChangeToastTitle("Added User");
        setUserName("");
        setUserEmail("");
        setErrorMessage("");
        props.onClose();
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to create user.");

        } else {
          setErrorMessage("");
        }
      }
    }
  }

  // Edit a user.
  async function editUser(userId) {
    if (userIsValid()) {
      setLoading(true);
      const updatedUser = {
        userId: userId,
        userName: userName,
        userEmail: userEmail,
        userRole: userRole,
        userState: userState
      };
      //  Make API request to update the user
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/users/${userId}`,
        "PUT",
        updatedUser
      );
      setLoading(false);

      if (response.ok) {
        props.onAction("UPDATE_USER", updatedUser);
        props.onChangeToastMessage(context.isProd ? `User ${userName} has been updated on rpm-squared.com` : `User ${userName} has been updated on rpm-squared-dev.com`);
        props.onChangeToastTitle("Updated User");
        setLoading(false);
        setErrorMessage("");
        props.onClose();
      } else {
        if (response.status >= 500) {
          setErrorMessage("Internal server error. Unable to update user.");

        } else {
          setErrorMessage(responseBody.error);
        }
      }

    }
  }

  // Delete a user.
  async function deleteUser(userId) {
    setLoading(true);
    const deletedUser = {
      userEmail: userEmail
    };
    // Make API request to delete user
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/users/${userId}`,
      "DELETE",
      deletedUser
    );
    setLoading(false);
    if (response.ok) {
      props.onAction("DELETE_USER", { userId: userId });
      props.onChangeToastMessage(context.isProd ? `User ${userName} has been deleted from rpm-squared.com` : `User ${userName} has been deleted from rpm-squared-dev.com`);
      props.onChangeToastTitle("Deleted User");
      setLoading(false);
      setErrorMessage("");
      props.onClose();
      setShowConfirmDelete(false);
    } else {
      if (response.status >= 500) {
        setErrorMessage("Internal server error. Unable to delete user.");
        setLoading(false);

      } else {
        setErrorMessage(responseBody.error);
        setLoading(false);
      }
    }
    setShowConfirmDelete(false);
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal() {

    // Check to see if state is the same as props.
    if (
      userName === props.selectedUser.userName
      && userRole === props.selectedUser.userRole
    ) {
      // Since there have been no changes we can safely exit.
      dontSaveChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges() {
    if (props.mode === "create") {
      createUser();
    } else {
      editUser(props.selectedUser.userId);
    }
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setErrorMessage("");
    props.onClose();
  }


  return (
    <div className="user-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            {props.mode === "create" ? (
              <span>Create user</span>
            ) : (
              <span>Edit user</span>
            )}
          </h5>
        </ModalHeader>

        <ModalBody>
          <UserForm
            mode={props.mode}
            userName={userName}
            userRole={userRole}
            userEmail={userEmail}
            userState={userState}
            onChangeUserName={userName => setUserName(userName)}
            onChangeUserRole={userRole => setUserRole(userRole)}
            onChangeUserEmail={userEmail => setUserEmail(userEmail)}
            setErrorMessage={errorMessage => setErrorMessage(errorMessage)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.mode === "create" ? (
            <Fragment>
              <button className="btn btn-success" type="button" onClick={() => createUser()}>
                Create user
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button className="btn btn-danger me-auto" type="button" onClick={() => setShowConfirmDelete(true)}>
                Delete
              </button>

              <button className="btn btn-success" type="button" onClick={() => editUser(props.selectedUser.userId)}>
                Save Changes
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title="Deletion Confirmation"
        content={"Are you sure that you want to delete this user? This action cannot be undone."}
        yesText="Delete User"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteUser(props.selectedUser.userId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

UserModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  showModal: PropTypes.bool.isRequired,
  selectedUser: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  onChangeToastMessage: PropTypes.func.isRequired,
  onChangeToastTitle: PropTypes.func.isRequired,
};