/*
Author:      KC Willard
Created:     3/2/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./EndpointList.scss";
import deepCopy from "../../../../../utilities/deepCopy";
import formatTitleCase from "../../../../../utilities/formatTitleCase";

// A list of endpoints that support creation, updating, and deletion.
export default function EndpointList(props) {
  const [sourceFilteredEndpointList, setSourceFilteredEndpointList] = useState([]);
  const [targetFilteredEndpointList, setTargetFilteredEndpointList] = useState([]);
  const [sourceEndpointType, setSourceEndpointType] = useState("");
  const [targetEndpointType, setTargetEndpointType] = useState("");
  const [endpointCollectionLabel, setEndpointCollectionLabel] = useState("");
  const [showEndpointList, setShowEndpointList] = useState(false);

  useEffect(() => {
    const index = props.processTypes.findIndex(processType =>
      processType.processType === props.processTypeName
    );

    const processSourceEndpointType = props.processTypes[index].sourceEndpointTypes;
    const sourceEndpointList = props.endpoints.filter(endpoint =>
      endpoint.endpointTypeName === processSourceEndpointType
    );
    setEndpointCollectionLabel(props.processTypes[index].endpointCollectionLabel);
    setShowEndpointList(props.processTypes[index].hasEndpointList);
    setSourceEndpointType(processSourceEndpointType);
    setSourceFilteredEndpointList(sourceEndpointList);
  }, [props.processTypeName]);

  // Update list of available endpoints when processType changes
  useEffect(() => {
    const index = props.processTypes.findIndex(processType =>
      processType.processType === props.processTypeName
    );
    const processTargetEndpointType = props.processTypes[index].targetEndpointTypes;
    const targetEndpointList = props.endpoints.filter(endpoint =>
      endpoint.endpointTypeName === processTargetEndpointType
    );
    setTargetEndpointType(processTargetEndpointType);
    setTargetFilteredEndpointList(targetEndpointList);
  }, [props.processTypeName]);

  // Update an endpoint in the endpoint Map
  async function updateEndpointMapEndpoint(value, index, direction) {
    const endpointMapCopy = deepCopy(props.processEndpointMaps);
    if (direction === "source") {
      endpointMapCopy[index].sourceEndpointId = value;
    } else {
      endpointMapCopy[index].targetEndpointId = value;
    }
    props.onChangeProcessEndpointMaps(endpointMapCopy);
  }

  // Update an endpoint in the endpoint list
  async function updateEndpointListEndpoint(value, index, direction) {
    const endpointListCopy = deepCopy(props.processEndpoints);
    endpointListCopy[index].endpointId = value;
    endpointListCopy[index].endpointDirection = direction;
    props.onChangeProcessEndpointList(endpointListCopy);
  }

  // Delete the endpoint from the list or map at the given index
  async function deleteEndpoint(event, index) {
    if (showEndpointList) {
      let endpointListCopy = deepCopy(props.processEndpoints);
      endpointListCopy = endpointListCopy.filter(element =>
        element.processEndpointListIndex !== index
      );
      props.onChangeProcessEndpointList(endpointListCopy);
    } else {
      let endpointMapCopy = deepCopy(props.processEndpointMaps);
      endpointMapCopy = endpointMapCopy.filter(element =>
        element.processEndpointMapIndex !== index
      );
      props.onChangeProcessEndpointMaps(endpointMapCopy);
    }
  }

  return (
    <div className="process-form-endpoint-list">
      <div className="list-header p-4">
        <div className="row align-items-top">
          <div className="list-title col">
            <span className="align-top">{endpointCollectionLabel}</span>
          </div>
          {/* Button for creating new Endpoints. */}
          <div className="col">
            {showEndpointList ? (
              <button
                className="create-btn btn btn-success float-end"
                onClick={() => props.addEndpointListEndpoint()}
              >
                <i className="fa fa-fw fa-plus fa-1x" />
              </button>
            ) : (
              <button
                className="create-btn btn btn-success float-end"
                onClick={() => props.addEndpointMapEndpoint()}
              >
                <i className="fa fa-fw fa-plus fa-1x" />
              </button>
            )}

          </div>
        </div>
      </div>
      {/* If the process type requires an endpoint list, display that. Otherwise display endpoint map*/}
      {showEndpointList ? (
        <div className="table-responsive" >
          <table className="table" >
            <thead>
              <tr>
                <th scope="col" >{`${formatTitleCase(sourceEndpointType)} name: `}</th>
                <th scope="col" >Endpoint Direction</th>
                <th></th>
              </tr>
            </thead>
            <tbody >
              {/* For every pair of endpoints in an endpoint map create dropdowns to
              select the endpoints based on the set of endpoints in a given type*/}
              {props.processEndpoints.map((processEndpoint, i) =>
                <tr key={processEndpoint.processEndpointsIndex}>
                  {/* Set the source Endpoint*/}
                  <td>
                    {processEndpoint.endpointId === -1 ? (
                      <select
                        className="form-select"
                        value={-1}
                        onChange={(event) => updateEndpointListEndpoint(event.target.value, i, processEndpoint.endpointDirection)}
                      >
                        <option value={-1}>Select an endpoint...</option>
                        {sourceFilteredEndpointList.map((sourceFilteredEndpoint, i) => {
                          return <option
                            value={sourceFilteredEndpoint.endpointId}
                            key={i}
                          >
                            {sourceFilteredEndpoint.endpointName}
                          </option>;
                        }
                        )}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        value={processEndpoint.endpointId}
                        onChange={(event) => updateEndpointListEndpoint(event.target.value, i, processEndpoint.endpointDirection)}
                      >
                        {sourceFilteredEndpointList.map((sourceFilteredEndpoint, i) => {
                          return <option
                            value={sourceFilteredEndpoint.endpointId}
                            key={i}
                          >
                            {sourceFilteredEndpoint.endpointName}
                          </option>;
                        }
                        )}
                      </select>
                    )}
                  </td>
                  {/* Set the target Endpoint direction*/}
                  <td>
                    <select
                      className="form-select"
                      value={processEndpoint.endpointDirection}
                      onChange={(event) => updateEndpointListEndpoint(processEndpoint.endpointId, i, event.target.value)}
                    >
                      <option value="target"> Target</option>;
                      <option value="source"> Source</option>;
                    </select>
                  </td>
                  {/* Button to remove an endpoint from the map of endpoints*/}
                  <td>
                    <button
                      type="button"
                      className="btn btn-danger btn-floating"
                      onClick={(event) => deleteEndpoint(event, processEndpoint.processEndpointListIndex)}>
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {props.processEndpoints.length === 0 && (
            <div className="empty-list py-5 px-4">
              There are no endpoints to display
            </div>
          )}
        </div>
      ) : (
        <div className="table-responsive" >
          <table className="table" >
            <thead>
              <tr>
                <th scope="col" >{`Source  ${sourceEndpointType}`}</th>
                <th scope="col" >{`Target  ${targetEndpointType}`}</th>
                <th></th>
              </tr>
            </thead>
            <tbody >
              {/* For every pair of endpoints in an endpoint map create dropdowns to
              select the endpoints based on the set of endpoints in a given type*/}
              {props.processEndpointMaps.map((processEndpoint, i) =>
                <tr key={processEndpoint.processEndpointMapIndex}>
                  {/* Set the source Endpoint*/}
                  <td>
                    {processEndpoint.sourceEndpointId === -1 ? (
                      <select
                        className="form-select"
                        value={processEndpoint.sourceEndpointId}
                        onChange={(event) => updateEndpointMapEndpoint(event.target.value, i, "source")}
                      >
                        <option value={-1}>Select an endpoint...</option>
                        {sourceFilteredEndpointList.map((sourceFilteredEndpoint, i) => {
                          return <option
                            value={sourceFilteredEndpoint.endpointId}
                            key={i}
                          >
                            {sourceFilteredEndpoint.endpointName}
                          </option>;
                        }
                        )}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        value={processEndpoint.sourceEndpointId}
                        onChange={(event) => updateEndpointMapEndpoint(event.target.value, i, "source")}
                      >
                        {sourceFilteredEndpointList.map((sourceFilteredEndpoint, i) => {
                          return <option
                            value={sourceFilteredEndpoint.endpointId}
                            key={i}
                          >
                            {sourceFilteredEndpoint.endpointName}
                          </option>;
                        }
                        )}
                      </select>
                    )}
                  </td>

                  {/* Set the target Endpoint*/}
                  <td>
                    {processEndpoint.targetEndpointId === -1 ? (
                      <select
                        className="form-select"
                        value={processEndpoint.targetEndpointId}
                        onChange={(event) => updateEndpointMapEndpoint(event.target.value, i, "target")}
                      >
                        <option value={-1}>Select an endpoint...</option>
                        {targetFilteredEndpointList.map((targetFilteredEndpoint, i) => {
                          return <option
                            value={targetFilteredEndpoint.endpointId}
                            key={i}
                          >
                            {targetFilteredEndpoint.endpointName}
                          </option>;
                        }
                        )}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        value={processEndpoint.targetEndpointId}
                        onChange={(event) => updateEndpointMapEndpoint(event.target.value, i, "target")}
                      >
                        {targetFilteredEndpointList.map((targetFilteredEndpoint, i) => {
                          return <option
                            value={targetFilteredEndpoint.endpointId}
                            key={i}
                          >
                            {targetFilteredEndpoint.endpointName}
                          </option>;
                        }
                        )}
                      </select>
                    )}
                  </td>
                  {/* Button to remove an endpoint from the map of endpoints*/}
                  <td>
                    <button
                      type="button"
                      className="btn btn-danger btn-floating float-end"
                      onClick={(event) => deleteEndpoint(event, processEndpoint.processEndpointMapIndex)}>
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {props.processEndpointMaps.length === 0 && (
            <div className="empty-list py-5 px-4">
              There are no endpoints to display
            </div>
          )}
        </div>
      )}
    </div>
  );
}

EndpointList.propTypes = {
  processEndpoints: PropTypes.array.isRequired,
  processEndpointMaps: PropTypes.array.isRequired,
  processTypeName: PropTypes.string.isRequired,
  processTypes: PropTypes.array.isRequired,
  endpoints: PropTypes.array.isRequired,
  endpointTypes: PropTypes.array.isRequired,
  addEndpointListEndpoint: PropTypes.func.isRequired,
  addEndpointMapEndpoint: PropTypes.func.isRequired,
  onChangeProcessEndpointList: PropTypes.func.isRequired,
  onChangeProcessEndpointMaps: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired
};