/*
Author:      Tyler Petty
Created:     6/2/2022
Modified:    6/15/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState } from "react";
import Card from "../../../components/Card/Card";
import "./CompanyList.scss";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { SUBDIRECTORY } from "../../../utilities/constantsRpmAdministrativeConsole";

// RPM Administrative Console company list
export default function CompanyList(props) {
  const [filterName, setFilterName] = useState("");
  const history = useHistory();

  return (
    <div className="table-responsive">
      <Card title="Manage Companies">
        <label className="my-2">By Company Code</label>
        <input
          className="form-control"
          list="datalist-companies"
          value={props.companies.name}
          placeholder="Filter companies..."
          onChange={(e) => setFilterName(e.target.value.toLowerCase())}
        />
        {props.companies.length === 0 && !props.loading ? (
          <div className="empty-list py-5 px-4 text-center">
            There are no companies to display
          </div>
        ) : (
          <table className="table table-hover mb-0" >
            <thead>
              <tr>
                <th scope="col" >Company</th>
              </tr>
            </thead>
            <tbody>
              {props.companies.filter((company) => company.name.toLowerCase().includes(filterName)).map((company) =>
                <tr key={company.companyId}
                  onClick={() => { history.push(`/${SUBDIRECTORY}/manage-devices/${company.companyId}`); }}
                  style={{ cursor: "pointer" }}
                >
                  <td>{company.name}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </Card>
    </div>
  );
}


CompanyList.propTypes = {
  companies: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};