/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    6/4/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./Footer.css";

// General footer.
export default function Footer(props) {
  const date = new Date();

  return (
    <footer
      className="general-footer py-1 px-3"
      style={props.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
    >
      &#xA9; {date.getYear() + 1900}, Cornell Pump Company. All rights reserved.
    </footer>
  );
}

Footer.propTypes = {
  isProd: PropTypes.bool.isRequired,
};