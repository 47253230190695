/*
Author:      KC Willard
Created:     4/22/2022
Modified:    5/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext } from "react";
import PropTypes from "prop-types";
import Context from "../../../components/Context/Context";
import "./UserList.scss";

// A list of Users that support creation, updating, and deletion.
export default function UserList(props) {
  const context = useContext(Context);

  return (
    <div className="user-list">
      <div className="list-header p-4"
        style={context.isProd ? { background: "var(--prod-background)" } : { background: "var(--dev-background)" }}
      >
        <div className="row align-items-top">
          <div className="list-title col">
            <span className="align-top">Users</span>
          </div>

          {/* Button for creating new users. */}
          <div className="col">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => props.onSelect(0)}
            >
              <i className="fa fa-fw fa-plus fa-1x" />
            </button>
          </div>
        </div>
      </div>

      <div className="list-body text-center">
        <div className="table-responsive">
          <table className="table table-hover mb-0" >
            <thead>
              <tr>
                <th scope="col" >User Name</th>
                <th scope="col" >User Role</th>
                <th scope="col" >Is Enabled?</th>
              </tr>
            </thead>
            <tbody >
              {/* List of interactive users. */}
              {!props.loading && props.users.map(user =>
                <tr
                  key={user.userId}
                  onClick={() => props.onSelect(user.userId)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{user.userName}</td>
                  <td>{user.userRole}</td>
                  <td>{user.userState}</td>
                </tr>
              )}
            </tbody>
          </table>
          {props.users.length === 0 && !props.loading && (
            <div className="empty-list py-5 px-4">
              There are no users to display
            </div>
          )}
        </div>
      </div>

    </div >
  );
}

UserList.propTypes = {
  loading: PropTypes.bool,
  users: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};