/*
Author:      Zachary Thomas
Created:     4/7/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useState } from "react";
import Context from "../../../components/Context/Context";
import TemplateModal from "./TemplateModal/TemplateModal";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { SUBDIRECTORY } from "../../../utilities/constantsNotificationManager";
import "./TemplateList.scss";

// A list of templates.
export default function TemplateList(props) {
  const [showModal, setShowModal] = useState(false);
  const context = useContext(Context);
  const history = useHistory();

  // Given an ID, return the urgency value.
  function getUrgency(urgencyId) {
    const urgencyIndex = props.urgencies.findIndex(urgency => urgency.urgencyId === urgencyId);
    // If the process type is found in our master list then add all information to the array
    if (urgencyIndex !== -1) {
      return props.urgencies[urgencyIndex].value;
    } else {
      return "";
    }
  }

  return (
    <div className="template-list my-5">
      <div
        className="list-header p-4"
        style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
      >
        <div className="row align-items-top">
          <div className="list-title col">
            <span className="align-top">Templates</span>
          </div>

          <div className="col">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => setShowModal(true)}
            >
              <i className="fa fa-fw fa-plus fa-1x" />
            </button>
          </div>
        </div>
      </div>

      <div className="list-body">
        {!props.loading && (
          <table className="table mb-0 pb-0">
            <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Urgency</th>
                <th scope="col">Email Template</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              {props.templates.map(template =>
                <tr
                  className="list-item selectable-container py-2"
                  onClick={() => history.push(
                    `/${SUBDIRECTORY}/subsystem/${props.audienceType}/${props.subsystemId}/template/${template.templateId}`
                  )}
                  key={template.templateId}
                >
                  <td>{template.templateCode}</td>
                  <td>{getUrgency(template.defaultUrgencyId)}</td>
                  <td>{template.emailTemplate === "" ? "None" : template.emailTemplate}</td>
                  <td>{template.description}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {props.templates.length === 0 && !props.loading && (
          <div className="empty-list py-5 px-4">
            There are no templates to display
          </div>
        )}
      </div>

      <TemplateModal
        showModal={showModal}
        audienceType={props.audienceType}
        subsystemId={props.subsystemId}
        urgencies={props.urgencies}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

TemplateList.propTypes = {
  loading: PropTypes.bool.isRequired,
  audienceType: PropTypes.string.isRequired,
  subsystemId: PropTypes.number.isRequired,
  templates: PropTypes.array.isRequired,
  urgencies: PropTypes.array.isRequired
};