/*
Author:      Zachary Thomas
Created:     4/8/2022
Modified:    6/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useContext } from "react";
import apiRequest from "../../../../utilities/apiRequest";
import SaveChangesModal from "../../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../../components/Modal/Modal";
import ModalHeader from "../../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../../components/ModalFooter/ModalFooter";
import Spinner from "../../../../components/Spinner/Spinner";
import Error from "../../../../components/Error/Error";
import SubsystemForm from "./SubsystemForm/SubsystemForm";
import Context from "../../../../components/Context/Context";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  MIN_CODE_LENGTH,
  MAX_CODE_LENGTH,
  MIN_NAME_LENGTH,
  MAX_NAME_LENGTH,
  SUBDIRECTORY,
  PROD_API,
  DEV_API
} from "../../../../utilities/constantsNotificationManager";
import "./SubsystemModal.scss";

// Modal for creating subsystems.
export default function SubsystemModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [sms, setSms] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const context = useContext(Context);
  const history = useHistory();

  // Validate the subsystem.
  function subsystemIsValid() {
    if (code.length < MIN_CODE_LENGTH || code.length > MAX_CODE_LENGTH) {
      setErrorMessage(
        `The code must be between ${MIN_CODE_LENGTH} and ${MAX_CODE_LENGTH} characters long.`
      );
      return false;
    } else if (name.length < MIN_NAME_LENGTH || name.length > MAX_NAME_LENGTH) {
      setErrorMessage(
        `The name must be between ${MIN_NAME_LENGTH} and ${MAX_NAME_LENGTH} characters long.`
      );
      return false;
    } else if (props.audienceType === "operations" && email.length === 0) {
      setErrorMessage(
        `A default email is required.`
      );
      return false;
    } else if (props.audienceType === "operations" && sms.length === 0) {
      setErrorMessage(
        `A default SMS is required.`
      );
      return false;
    } else {
      return true;
    }
  }

  // Create a subsystem.
  async function createSubsystem() {
    setShowConfirmExit(false);
    if (subsystemIsValid()) {
      const requestBody = {
        code: code,
        name: name,
        description: description,
        defaultEmail: email,
        defaultSms: sms
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/subsystem/${props.audienceType}`,
        "POST",
        requestBody
      );
      setLoading(false);

      if (response.ok && responseBody) {
        history.push(`/${SUBDIRECTORY}/subsystem/${props.audienceType}/${responseBody.subsystemId}`);
      } else {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to create subsystem.");
        }
      }
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal() {
    // Check to see if state is the same as props.
    if (
      code.length === 0
      && name.length === 0
      && sms.length === 0
      && email.length === 0
      && description.length === 0
    ) {
      // Since there have been no changes we can safely exit.
      dontSaveChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setCode("");
    setName("");
    setSms("");
    setEmail("");
    setDescription("");
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div className="subsystem-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            <span>Create {props.audienceType === "operations" ? "Operation" : "RPM Customer"} Subsystem&nbsp;&nbsp;</span>
            <i className={`fa fa-fw fa-${props.audienceType === "operations" ? "wrench" : "users"} fa-1x`} />
          </h5>
        </ModalHeader>

        <ModalBody>
          <SubsystemForm
            code={code}
            name={name}
            sms={sms}
            email={email}
            description={description}
            audienceType={props.audienceType}
            onChangeCode={code => setCode(code)}
            onChangeName={name => setName(name)}
            onChangeSms={sms => setSms(sms)}
            onChangeEmail={email => setEmail(email)}
            onChangeDescription={description => setDescription(description)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-success" type="button" onClick={() => createSubsystem()}>
            Create Subsystem
          </button>

          <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => createSubsystem()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

SubsystemModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  audienceType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};