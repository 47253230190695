/*
Author:      Zachary Thomas
Created:     3/7/2022
Modified:    3/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import Resource from "./Resource/Resource";
import useApi from "../../../../hooks/useApi";
import Modal from "../../../../components/Modal/Modal";
import ModalHeader from "../../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../../components/ModalFooter/ModalFooter";
import Spinner from "../../../../components/Spinner/Spinner";
import Context from "../../../../components/Context/Context";
import IotManagerCleanDeviceResource from "../../../../api/IotManagerCleanDeviceResource";
import { DEV_API, PROD_API } from "../../../../utilities/constantsIotManager";
import "./SelectResourceModal.scss";

// Modal for selecting a resource.
export default function SelectResourceModal(props) {
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState("");
  const context = useContext(Context);

  // Set the selected resource when the modal is opened.
  useEffect(() => {
    if (props.showModal) {
      setSelectedResource(props.selectedResource);
    }
  }, [props.showModal, props.selectedResource]);

  // Get the list of selectable files.
  useApi(
    () => {
      if (props.showModal) {
        setLoading(true);
        return true;
      }
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/${props.deviceType}/${props.deviceClass}`
        + `${props.deviceIdentifier ? "/" + props.deviceIdentifier : ""}/resource`,
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        responseBody = IotManagerCleanDeviceResource(responseBody);
        setResources(responseBody.resources);
      } else {
        console.error("Internal server error. Unable to retrieve resource data.");
      }
      setLoading(false);
    },
    [props.showModal, props.deviceType, props.deviceClass, props.deviceIdentifier],
    () => {
      setLoading(false);
    },
  );

  // Select a resource.
  function selectResource(selectedResource) {
    if (selectedResource.length > 0) {
      const resource = selectedResource.split("/");
      if (resource.length > 1) {
        const resourceName = resource.pop();
        resource.pop();
        props.onClose();
        props.onSelect(resourceName, `${resource.join("/")}/`);
      }
    }
  }

  return (
    <div className="resource-modal">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        size="xl"
        backdropClassName={"resource-top-level-backdrop"}
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title">
            Select File
          </h5>
        </ModalHeader>

        <ModalBody>
          {loading && (
            <div className="no-files-message my-4 mx-5">
              Loading Files
            </div>
          )}

          {!loading && resources.length > 0 && (
            <Fragment>
              <div className="table-container table-responsive mb-0 pb-0">
                <table className="table mb-0 pb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Location</th>
                      <th scope="col">Code</th>
                      <th scope="col">Last Modified</th>
                    </tr>
                  </thead>
                  <tbody className="scrollable-body">
                    {resources.map(resource =>
                      <Resource
                        key={resource.location}
                        name={resource.name}
                        location={resource.location}
                        code={resource.code}
                        fileType={resource.fileType}
                        arrivalTime={resource.arrivalTime}
                        selectedResource={selectedResource}
                        onSelect={(resource) => setSelectedResource(resource)}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}

          {!loading && resources.length === 0 && (
            <div className="no-files-message my-4 mx-5">
              No Files Found
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            data-dismiss="modal"
            onClick={() => selectResource(selectedResource)}
            disabled={selectedResource.length === 0}
          >
            Select File
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

SelectResourceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  deviceClass: PropTypes.string.isRequired,
  deviceIdentifier: PropTypes.string,
  selectedResource: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};