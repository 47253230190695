/*
Author:      KC Willard
Created:     3/9/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React from "react";
import PropTypes from "prop-types";
import "./ProcessConflictList.scss";
import deepCopy from "../../../../../utilities/deepCopy";
import formatTitleCase from "../../../../../utilities/formatTitleCase";

// A list of process conflicts that support creation, updating, and deletion.
export default function ProcessConflictList(props) {

  // Delete the endpoint from the list or map at the given endpoint
  async function deleteProcessConflict(event, index) {
    let processConflictListCopy = deepCopy(props.processConflictList);
    processConflictListCopy = processConflictListCopy.filter(element =>
      element.processConflictListIndex !== index
    );
    props.onChangeProcessConflictList(processConflictListCopy);
  }

  // Update an endpoint in the endpoint list
  async function updateProcessConflict(value, index) {
    const processConflictListCopy = deepCopy(props.processConflictList);
    processConflictListCopy[index].processId = value;
    props.onChangeProcessConflictList(processConflictListCopy);
  }

  return (
    <div className="process-conflict-list">
      <div className="list-header p-4">
        <div className="row align-items-top">
          <div className="list-title col">
            <span className="align-top">Conflicting Processes</span>
          </div>
          {/* Button for creating new conflicts. */}
          <div className="col">
            <button
              className="create-btn btn btn-success float-end"
              onClick={() => props.addProcessConflict()}
            >
              <i className="fa fa-fw fa-plus fa-1x" />
            </button>
          </div>
        </div>
      </div>
      {/* If their are conflicts, display them. Otherwise, display nothing below the header */}
      {props.processConflictList.length > 0 ? (
        <div className="table-responsive" >
          <table className="table" >
            <thead>
              <tr>
                <th scope="col" >Process Name</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody >
              {/* Select existing processes to create a conflict*/}
              {props.processConflictList.map((conflictProcess, i) =>
                <tr key={conflictProcess.processConflictListIndex}>
                  <td>
                    {conflictProcess.processId === -1 ? (
                      <select
                        className="form-select"
                        value={conflictProcess.processId}
                        onChange={(event) => updateProcessConflict(event.target.value, i)}
                      >
                        <option value={-1}>Select a Process...</option>
                        {props.processes.map((process, i) => {
                          { /* Only display the process if it is not the currently selected process*/ }
                          if (process.processName !== props.selectedProcessName) {
                            return <option
                              value={process.processId}
                              key={i}
                            >
                              {formatTitleCase(process.processName)}
                            </option>;
                          }
                        }
                        )}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        value={conflictProcess.processId}
                        onChange={(event) => updateProcessConflict(event.target.value, i)}
                      >
                        {props.processes.map((process, i) => {
                          { /* Only display the process if it is not the currently selected process*/ }
                          if (process.processName !== props.selectedProcessName) {
                            return <option
                              value={process.processId}
                              key={i}
                            >
                              {formatTitleCase(process.processName)}
                            </option>;
                          }
                        }
                        )}
                      </select>
                    )}

                  </td>
                  {/* Button to remove an conflict from the list of conflicts*/}
                  <td>
                    <button
                      type="button"
                      className="btn btn-danger btn-floating float-end "
                      onClick={(event) => deleteProcessConflict(event, conflictProcess.processConflictListIndex)}>
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <span></span>
      )}
    </div>
  );
}

ProcessConflictList.propTypes = {
  processes: PropTypes.array.isRequired,
  processConflictList: PropTypes.array.isRequired,
  selectedProcessName: PropTypes.string.isRequired,
  addProcessConflict: PropTypes.func.isRequired,
  onChangeProcessConflictList: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired
};