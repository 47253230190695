/*
Author:      Zachary Thomas
Created:     4/7/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext, useReducer, Fragment } from "react";
import useApi from "../../hooks/useApi";
import apiRequest from "../../utilities/apiRequest";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import deepCopy from "../../utilities/deepCopy";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import PermanentlyDeleteModal from "../../components/PermanentlyDeleteModal/PermanentlyDeleteModal";
import AddresseeList from "./AddresseeList/AddresseeList";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmationButtons from "../../components/ConfirmationButtons/ConfirmationButtons";
import Context from "../../components/Context/Context";
import Toast from "../../components/Toast/Toast";
import TestMessageModal from "./TestMessageModal/TestMessageModal";
import { useParams, useHistory } from "react-router-dom";
import { LINKS, EMAIL_TEMPLATES, EMAIL_SHELL_TEMPLATES, SUBDIRECTORY, PROD_API, DEV_API } from "../../utilities/constantsNotificationManager";
import "./NotificationManagerTemplatePage.scss";

// Page for viewing a single template.
export default function NotificationManagerTemplatePage() {
  const types = {
    SET_ADDRESSEES: "SET_ADDRESSEE",
    CREATE_ADDRESSEE: "CREATE_ADDRESSEE",
    UPDATE_ADDRESSEE: "UPDATE_ADDRESSEE",
    DELETE_ADDRESSEE: "DELETE_ADDRESSEE",
    SAVE_ADDRESSEES: "SAVE_ADDRESSEES",
    REVERT_ADDRESSEES: "REVERT_ADDRESSEES"
  };
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [templateCode, setTemplateCode] = useState("");
  const [subsystemCode, setSubsystemCode] = useState("");
  const [description, setDescription] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [defaultUrgencyId, setDefaultUrgencyId] = useState(0);
  const [tempDefaultUrgencyId, setTempDefaultUrgencyId] = useState(0);
  const [minuteSeparation, setMinuteSeparation] = useState("");
  const [tempMinuteSeparation, setTempMinuteSeparation] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [tempDefaultEmail, setTempDefaultEmail] = useState("");
  const [defaultSms, setDefaultSms] = useState("");
  const [tempDefaultSms, setTempDefaultSms] = useState("");
  const [emailTemplate, setEmailTemplate] = useState("");
  const [tempEmailTemplate, setTempEmailTemplate] = useState("");
  const [emailShellTemplate, setEmailShellTemplate] = useState("");
  const [tempShellTemplate, setTempShellTemplate] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [tempIsGlobal, setTempIsGlobal] = useState(false);
  const [forceTransmission, setForceTransmission] = useState(false);
  const [tempForceTransmission, setTempForceTransmission] = useState(false);
  const [urgencies, setUrgencies] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [deleteError, setDeleteError] = useState("");
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [addresseeStore, dispatch] = useReducer(addresseeReducer, { tempAddressees: [], addressees: [] });
  const { audienceType, subsystemId, templateId } = useParams();
  const context = useContext(Context);
  const history = useHistory();
  const [showTestMessageModal, setShowTestMessageModal] = useState(false);

  // Setting the title and the links to be displayed in the navbar for the current page.
  useEffect(() => {
    context.setTitle("Notification Manager");
    context.setLinks(LINKS);
  }, []);

  // Get info about the current template from the API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/subsystem/${audienceType}/${subsystemId}/template/${templateId}`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        setTemplateCode(responseBody.templateCode);
        setSubsystemCode(responseBody.subsystemCode);
        setDescription(responseBody.description);
        setTempDescription(responseBody.description);
        setDefaultUrgencyId(responseBody.defaultUrgencyId);
        setTempDefaultUrgencyId(responseBody.defaultUrgencyId);
        setMinuteSeparation(responseBody.minuteSeparation);
        setTempMinuteSeparation(responseBody.minuteSeparation);
        setDefaultEmail(responseBody.defaultEmail);
        setTempDefaultEmail(responseBody.defaultEmail);
        setDefaultSms(responseBody.defaultSms);
        setTempDefaultSms(responseBody.defaultSms);
        if (responseBody.emailTemplate === null) {
          setEmailTemplate("");
          setTempEmailTemplate("");
        } else {
          setEmailTemplate(responseBody.emailTemplate);
          setTempEmailTemplate(responseBody.emailTemplate);
        }
        if (responseBody.emailShellTemplate === null) {
          setEmailShellTemplate("");
          setTempShellTemplate("");
        } else {
          setEmailShellTemplate(responseBody.emailShellTemplate);
          setTempShellTemplate(responseBody.emailShellTemplate);
        }
        setUrgencies(responseBody.urgencies);
        setNotificationTypes(responseBody.notificationTypes);
        setIsGlobal(responseBody.isGlobal);
        setTempIsGlobal(responseBody.isGlobal);
        setForceTransmission(responseBody.forceTransmission);
        setTempForceTransmission(responseBody.forceTransmission);
        dispatch({
          type: types.SET_ADDRESSEES,
          payload: {
            addressees: responseBody.addressees
          }
        });
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  // Reducer for addressees.
  function addresseeReducer(state, action) {
    switch (action.type) {
      case types.SET_ADDRESSEES: {
        const newState = {
          addressees: action.payload.addressees,
          tempAddressees: action.payload.addressees
        };
        return newState;
      }

      case types.CREATE_ADDRESSEE: {
        const stateDeepCopy = deepCopy(state);
        let nextId = 1;
        stateDeepCopy.tempAddressees.forEach(addressee => {
          if (addressee.addresseeId >= nextId) {
            nextId = addressee.addresseeId + 1;
          }
        });
        const newAddressee = {
          addresseeId: nextId,
          name: "",
          address: "",
          notificationType: "",
          minimumAlertUrgency: "",
          enabled: false
        };
        stateDeepCopy.tempAddressees = [...stateDeepCopy.tempAddressees, newAddressee];
        return stateDeepCopy;
      }

      case types.UPDATE_ADDRESSEE: {
        const stateDeepCopy = deepCopy(state);
        const addresseeIndex = stateDeepCopy.tempAddressees.findIndex(addressee =>
          addressee.addresseeId === action.payload.addresseeId
        );
        if (addresseeIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.tempAddressees.splice(addresseeIndex, 1, action.payload);
          return stateDeepCopy;
        }
      }

      case types.DELETE_ADDRESSEE: {
        const stateDeepCopy = deepCopy(state);
        const addresseeIndex = stateDeepCopy.tempAddressees.findIndex(addressee =>
          addressee.addresseeId === action.payload.addresseeId
        );
        if (addresseeIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.tempAddressees.splice(addresseeIndex, 1);
          return stateDeepCopy;
        }
      }

      case types.SAVE_ADDRESSEES: {
        const stateDeepCopy = deepCopy(state);
        const tempAddressees = deepCopy(state.tempAddressees);
        stateDeepCopy.addressees = tempAddressees;
        setAllowUpdate(true);
        return stateDeepCopy;
      }

      case types.REVERT_ADDRESSEES: {
        const stateDeepCopy = deepCopy(state);
        const addressees = deepCopy(state.addressees);
        stateDeepCopy.tempAddressees = addressees;
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Update any changes that are saved.
  useEffect(() => {
    async function updateTemplate() {
      let selectedTemplate = emailTemplate;
      let selectedEmailShellTemplate = emailShellTemplate;
      if (selectedTemplate.length === 0) {
        selectedTemplate = null;
      }
      if (selectedEmailShellTemplate.length === 0) {
        selectedEmailShellTemplate = null;
      }

      const requestBody = {
        templateCode: templateCode,
        description: description,
        defaultUrgencyId: defaultUrgencyId,
        minuteSeparation: minuteSeparation,
        defaultEmail: defaultEmail,
        defaultSms: defaultSms,
        emailTemplate: selectedTemplate,
        emailShellTemplate: selectedEmailShellTemplate,
        isGlobal: isGlobal,
        forceTransmission: forceTransmission,
        addressees: addresseeStore.addressees
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/subsystem/${audienceType}/${subsystemId}/template/${templateId}`,
        "PUT",
        requestBody
      );
      setLoading(false);

      if (!response.ok) {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to update template.");
        }
      }
    }

    if (allowUpdate) {
      updateTemplate();
    }
  }, [
    templateCode, description, defaultUrgencyId, minuteSeparation, defaultEmail, defaultSms, emailTemplate, emailShellTemplate,
    isGlobal, forceTransmission, JSON.stringify(addresseeStore.addressees), allowUpdate
  ]);

  // Delete a template.
  async function deleteTemplate() {
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/subsystem/${audienceType}/${subsystemId}/template/${templateId}`,
      "DELETE",
      null
    );
    setLoading(false);
    if (response.ok) {
      setShowConfirmDelete(false);
      history.push(`/${SUBDIRECTORY}/subsystem/${audienceType}/${subsystemId}`);

    } else {
      if (response.status < 500 && responseBody.error) {
        setDeleteError(responseBody.error);
      } else {
        setDeleteError("Internal server error. Unable to delete template.");
      }
    }
  }

  // Accept a value for updating.
  function acceptValue(setFunction, value) {
    setFunction(value);
    setAllowUpdate(true);
  }

  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="page-note-template mb-5">
        <Spinner loading={loading} />

        <PageTitle title="Edit Template" />

        <div className="row">
          <div className="col">
            <BreadCrumbs
              title="Edit Template"
              links={[
                {
                  title: "Manage Subsystems",
                  url: `/${SUBDIRECTORY}/manage-subsystems`
                },
                {
                  title: "Edit Subsystem",
                  url: `/${SUBDIRECTORY}/subsystem/${audienceType}/${subsystemId}`
                }
              ]}
            />
          </div>

          <div className="col">
            <button
              className="delete-btn btn btn-danger float-end"
              onClick={() => setShowConfirmDelete(true)}
            >
              Delete Template
            </button>
          </div>
        </div>

        <label className="font-weight-bold mb-2">
          Code
        </label>
        <input
          className="form-control mx-auto"
          type="text"
          value={templateCode}
          disabled
        />

        <label className="font-weight-bold mt-4 mb-2">
          Description
        </label>
        <input
          className="form-control mx-auto"
          type="text"
          value={tempDescription}
          onChange={e => setTempDescription(e.target.value)}
        />

        {description !== tempDescription && (
          <ConfirmationButtons
            onAccept={() => acceptValue(setDescription, tempDescription)}
            onCancel={() => setTempDescription(description)}
          />
        )}

        <label className="font-weight-bold mt-4 mb-2">
          Default Urgency
        </label>
        <select
          className="form-select"
          value={tempDefaultUrgencyId}
          onChange={(e) => setTempDefaultUrgencyId(parseInt(e.target.value, 10))}
        >
          {urgencies.map(urgency =>
            <option
              key={urgency.urgencyId}
              value={urgency.urgencyId}
            >
              {urgency.value}
            </option>
          )}
        </select>

        {defaultUrgencyId !== tempDefaultUrgencyId && (
          <ConfirmationButtons
            onAccept={() => acceptValue(setDefaultUrgencyId, tempDefaultUrgencyId)}
            onCancel={() => setTempDefaultUrgencyId(defaultUrgencyId)}
          />
        )}

        <label className="font-weight-bold mt-4 mb-2">
          Notification Minute Separation
        </label>
        <input
          className="form-control mx-auto"
          type="text"
          value={tempMinuteSeparation}
          onChange={e => setTempMinuteSeparation(e.target.value)}
        />

        {minuteSeparation !== tempMinuteSeparation && (
          <ConfirmationButtons
            onAccept={() => acceptValue(setMinuteSeparation, tempMinuteSeparation)}
            onCancel={() => setTempMinuteSeparation(minuteSeparation)}
          />
        )}

        <label className="font-weight-bold mt-4 mb-2">
          Default Email Template
        </label>
        <select
          className="form-select"
          value={tempEmailTemplate}
          onChange={(e) => setTempEmailTemplate(e.target.value)}
        >
          <option value="">None</option>
          {EMAIL_TEMPLATES.map(emailTemplate =>
            <option
              key={emailTemplate}
              value={emailTemplate}
            >
              {emailTemplate}
            </option>
          )}
        </select>

        {emailTemplate !== tempEmailTemplate && (
          <ConfirmationButtons
            onAccept={() => acceptValue(setEmailTemplate, tempEmailTemplate)}
            onCancel={() => setTempEmailTemplate(emailTemplate)}
          />
        )}

        <label className="font-weight-bold mt-4 mb-2">
          Default Shell Template
        </label>
        <select
          className="form-select"
          value={tempShellTemplate}
          onChange={(e) => setTempShellTemplate(e.target.value)}
        >
          <option value="">None</option>
          {EMAIL_SHELL_TEMPLATES.map(emailShellTemplate =>
            <option
              key={emailShellTemplate}
              value={emailShellTemplate}
            >
              {emailShellTemplate}
            </option>
          )}
        </select>

        {emailShellTemplate !== tempShellTemplate && (
          <ConfirmationButtons
            onAccept={() => acceptValue(setEmailShellTemplate, tempShellTemplate)}
            onCancel={() => setTempShellTemplate(emailShellTemplate)}
          />
        )}

        {audienceType === "operations" ? (
          <Fragment>
            <div className="row">
              <label className="font-weight-bold mt-4 mb-2">
                Force Transmission
              </label>
              <input
                className="form-check-input form-check-big mx-3"
                type="checkbox"
                checked={tempForceTransmission}
                onChange={e => setTempForceTransmission(e.target.checked)}
              />
              <div className="col">
                <button
                  type="button"
                  className="send-test-message-btn btn btn-success float-end"
                  onClick={() => setShowTestMessageModal(true)}
                >
                  Send Test Message
                </button>
              </div>
            </div>
            {forceTransmission !== tempForceTransmission && (
              <div className="mini-container">
                <ConfirmationButtons
                  onAccept={() => acceptValue(setForceTransmission, tempForceTransmission)}
                  onCancel={() => setTempForceTransmission(forceTransmission)}
                />
              </div>
            )}


            <AddresseeList
              loading={false}
              addressees={addresseeStore.tempAddressees}
              addresseesChanged={JSON.stringify(addresseeStore.addressees) !== JSON.stringify(addresseeStore.tempAddressees)}
              notificationTypes={notificationTypes}
              urgencies={urgencies}
              onAction={action => dispatch(action)}
            />
          </Fragment>

        ) : (
          <Fragment>
            <label className="font-weight-bold mt-4 mb-2">
              Default Sender Email
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={tempDefaultEmail}
              onChange={e => setTempDefaultEmail(e.target.value)}
            />

            {defaultEmail !== tempDefaultEmail && (
              <ConfirmationButtons
                onAccept={() => acceptValue(setDefaultEmail, tempDefaultEmail)}
                onCancel={() => setTempDefaultEmail(defaultEmail)}
              />
            )}

            <label className="font-weight-bold mt-4 mb-2">
              Default Sender SMS
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={tempDefaultSms}
              onChange={e => setTempDefaultSms(e.target.value)}
            />

            {defaultSms !== tempDefaultSms && (
              <ConfirmationButtons
                onAccept={() => acceptValue(setDefaultSms, tempDefaultSms)}
                onCancel={() => setTempDefaultSms(defaultSms)}
              />
            )}

            <div className="row">
              <div className="col-auto me-5">
                <label className="font-weight-bold mt-4 mb-2">
                  Global
                </label>
                <input
                  className="form-check-input form-check-big my-auto"
                  type="checkbox"
                  checked={tempIsGlobal}
                  onChange={e => setTempIsGlobal(e.target.checked)}
                />

                {isGlobal !== tempIsGlobal && (
                  <div className="mini-container">
                    <ConfirmationButtons
                      onAccept={() => acceptValue(setIsGlobal, tempIsGlobal)}
                      onCancel={() => setTempIsGlobal(isGlobal)}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-auto">
                  <label className="font-weight-bold mt-4 mb-2">
                    Force Transmission
                  </label>
                  <input
                    className="form-check-input form-check-big mx-3"
                    type="checkbox"
                    checked={tempForceTransmission}
                    onChange={e => setTempForceTransmission(e.target.checked)}
                  />
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="send-test-message-btn btn btn-success float-end"
                    onClick={() => setShowTestMessageModal(true)}
                  >
                    Send Test Message
                  </button>
                </div>
              </div>
              {forceTransmission !== tempForceTransmission && (
                <div className="mini-container">
                  <ConfirmationButtons
                    onAccept={() => acceptValue(setForceTransmission, tempForceTransmission)}
                    onCancel={() => setTempForceTransmission(forceTransmission)}
                  />
                </div>
              )}

            </div>
          </Fragment>
        )}

        <Toast
          title="Failed to Update Template"
          message={errorMessage}
          show={errorMessage.length > 0}
          onClose={() => setErrorMessage("")}
          type="error"
        />

        <PermanentlyDeleteModal
          showModal={showConfirmDelete}
          title={`Delete Template`}
          content={`Are you sure that you want to delete the template '${templateCode}'?`}
          onDelete={() => deleteTemplate()}
          onCancel={() => setShowConfirmDelete(false)}
          errorMessage={deleteError}
        />

        <TestMessageModal
          showModal={showTestMessageModal}
          subsystemCode={subsystemCode}
          templateCode={templateCode}
          onClose={() => setShowTestMessageModal(false)}
        />
      </div>
    )
  );
}