/*
Author:      Zachary Thomas
Created:     11/4/2021
Modified:    2/16/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import Card from "../../../components/Card/Card";

import moment from "moment";
import "./LineChart.css";

// Line chart for viewing data.
export default function LineChart(props) {
  const [options, setOptions] = useState(null);
  const now = new Date();
  const then = new Date(new Date().setDate(now.getDate() - 7));

  // Update options.
  useEffect(() => {
    setOptions({
      chart: {
        height: 350,
        type: "line",
        borderWidth: 0,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      grid: {
        row: {
          colors: ["var(--white)", "var(--chart-background)"]
        }
      },
      xaxis: {
        type: "datetime",
        min: then,
        max: now,
        tickAmount: 12,
        labels: {
          datetimeUTC: false,
          rotate: -15,
          rotateAlways: true,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format("MMM Do h:00 A");
          }
        },
      }
    });
  }, [JSON.stringify(props.series)]);

  return (
    options !== null && (
      <Card title={props.title}>
        <div className="mt-5 mb-3 mx-4">
          <Chart options={options} series={props.series} type="line" height={350} />
        </div>
      </Card>
    )
  );
}

LineChart.propTypes = {
  title: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
};