/*
Author:      Zachary Thomas
Created:     2/24/2022
Modified:    6/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import HistoryModal from "./HistoryModal/HistoryModal";
import formatTimeToLocal from "../../../utilities/formatTimeToLocal";
import Context from "../../../components/Context/Context";
import "./DeviceHistory.scss";

// Shows all device history records for the current device.
export default function DeviceHistory(props) {
  const initialRecord = {
    location: "",
    deviceClass: "",
    deviceIdentifier: "",
    event: "",
    data: "",
    direction: "",
    signedUrl: "",
    arrivalTime: ""
  };
  const [direction, setDirection] = useState("both");
  const [filteredHistoryRecords, setFilteredHistoryRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(initialRecord);
  const context = useContext(Context);

  // Filter history records based on direction.
  useEffect(() => {
    if (direction === "both") {
      setFilteredHistoryRecords(props.historyRecords);
    } else if (direction === "transmit") {
      setFilteredHistoryRecords(
        props.historyRecords.filter(historyRecord => historyRecord.direction === "TRANSMITTED")
      );
    } else if (direction === "receive") {
      setFilteredHistoryRecords(
        props.historyRecords.filter(historyRecord => historyRecord.direction === "RECEIVED")
      );
    }
  }, [direction, JSON.stringify(props.historyRecords)]);

  return (
    <div className="iot-device-history">
      <Card title="History">
        <div
          className="select-direction-container text-center"
          style={context.isProd ?
            { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }
          }
        >
          <select
            className="data-query-form-select text-center mb-4 w-50"
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
          >
            <option value="both">Show All</option>
            <option value="transmit">Only Show Transmitted</option>
            <option value="receive">Only Show Received</option>
          </select>
        </div>

        {filteredHistoryRecords.length > 0 ? (
          <table className="table mb-0 pb-0">
            <thead>
              <tr>
                <th scope="col">Direction</th>
                <th scope="col">Event</th>
                <th scope="col">Scope</th>
                <th scope="col">Event Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {filteredHistoryRecords.map((historyRecord, i) =>
                <tr
                  className="list-item selectable-container py-2"
                  onClick={() => setSelectedRecord(historyRecord)}
                  key={i}
                >
                  <td>{historyRecord.direction === null ? "NULL" : historyRecord.direction}</td>
                  <td>{historyRecord.event === null ? "NULL" : historyRecord.event}</td>
                  <td>{historyRecord.scope === null ? "NULL" : historyRecord.scope}</td>
                  <td>{formatTimeToLocal(`${historyRecord.arrivalTime}.000Z`)}</td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div className="empty-list py-5 px-4">
            No history is available for this device
          </div>
        )}

        <HistoryModal
          showModal={selectedRecord.location.length > 0}
          selectedRecord={selectedRecord}
          onClose={() => setSelectedRecord(initialRecord)}
        />
      </Card>
    </div>
  );
}

DeviceHistory.propTypes = {
  historyRecords: PropTypes.array.isRequired
};