/*
Author:      KC Willard
Created:     10/18/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./TestMessageForm.scss";
import TestMessageList from "./TestMessageList/TestMessageList";

// Form for sending a test message.
export default function TestMessageForm(props) {
  const [showDatetimePicker, setShowDatetimePicker] = useState(false);

  function onChangeShowDateTimePicker(value) {
    if (value === true) {
      setShowDatetimePicker(true);
      props.onChangeSendWithDelay(true);
    } else if (value === false) {
      setShowDatetimePicker(false);
      props.onChangeSendWithDelay(false);
      props.onChangeDateToSend("");
      props.onChangeTimeToSend("");
    }
  }

  return (
    <div className="template-form">
      <label className="font-weight-bold mb-2">
        Subsystem Code
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        value={props.subsystemCode}
        disabled
      />

      <label className="font-weight-bold mt-4 mb-2">
        Template Code
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        value={props.templateCode}
        disabled
      />


      <label className="font-weight-bold mt-4 mb-4">
        Message List
      </label>
      <button
        type="button"
        className="create-messagelist-btn btn btn-success mt-4 float-end"
        onClick={() => props.onAction("CREATE_MESSAGELIST", null)}
      >
        <i className="fa fa-plus" />
      </button>
      <TestMessageList
        messageList={props.messageList}
        onAction={props.onAction}
      />


      <label className="font-weight-bold mt-4 mb-2">
        Email Recipient
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        value={props.addresseeEmail}
        onChange={e => props.onChangeAddresseeEmail(e.target.value)}
      />

      <label className="font-weight-bold mt-4 mb-2">
        SMS Recipient
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        value={props.addresseeSms}
        onChange={e => props.onChangeAddresseeSms(e.target.value)}
      />


      <label className="font-weight-bold mt-4 mb-2">
        Send with Delay?
      </label>
      <input
        className="form-check-input form-check-small mt-4 mx-2"
        type="checkbox"
        checked={showDatetimePicker}
        onChange={e => onChangeShowDateTimePicker(e.target.checked)}
      />

      {showDatetimePicker === true && (
        <div className="row">
          <div className="col auto">
            <label className="font-weight-bold mt-4 mb-2">
              Date to send message
            </label>
            <input
              className="form-control mx-auto"
              type="date"
              value={props.dateToSend}
              onChange={e => props.onChangeDateToSend(e.target.value)}
            />
          </div>
          <div className="col auto">
            <label className="font-weight-bold mt-4 mb-2">
              Time to send message
            </label>
            <input
              className="form-control mx-auto"
              type="time"
              value={props.timeToSend}
              onChange={e => props.onChangeTimeToSend(e.target.value)}
            />
          </div>
        </div>
      )}

    </div>
  );
}

TestMessageForm.propTypes = {
  templateCode: PropTypes.string.isRequired,
  subsystemCode: PropTypes.string.isRequired,
  messageList: PropTypes.array.isRequired,
  addresseeEmail: PropTypes.string.isRequired,
  addresseeSms: PropTypes.string.isRequired,
  timeToSend: PropTypes.string.isRequired,
  dateToSend: PropTypes.string.isRequired,
  onChangeAddresseeEmail: PropTypes.func.isRequired,
  onChangeAddresseeSms: PropTypes.func.isRequired,
  onChangeTimeToSend: PropTypes.func.isRequired,
  onChangeDateToSend: PropTypes.func.isRequired,
  onChangeSendWithDelay: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired
};