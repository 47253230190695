/*
Author:      Zachary Thomas
Created:     4/6/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./BreadCrumbs.scss";

// Breadcrumb title with links to previous pages.
export default function BreadCrumbs(props) {
  return (
    <div className="bread-crumbs my-3">
      {props.links.map(link =>
        <Fragment key={link.url}>
          <Link to={link.url}>
            <span className="link-crumb">
              {link.title}
            </span>
          </Link>

          <span>&nbsp;&gt;&nbsp;</span>
        </Fragment>
      )}
      <span>{props.title}</span>
    </div>
  );
}

BreadCrumbs.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired
};