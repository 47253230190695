/*
Author:      Tyler Petty
Created:     6/7/2022
Modified:    7/7/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useEffect, useState } from "react";
import Error from "../../../components/Error/Error";
import "./AccountList.scss";
import PropTypes from "prop-types";
import Context from "../../../components/Context/Context";
import {
  DEV_API,
  PROD_API
} from "../../../utilities/constantsRpmAdministrativeConsole";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/apiRequest";
import { useParams } from "react-router-dom";

// An editable list of accounts associated with currently selected company

export default function AccountList(props) {
  const [filterId, setFilterId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const context = useContext(Context);
  const { companyId } = useParams();

  // Clear error messages when accounts are saved or being edited.
  useEffect(() => {
    setErrorMessage("");
  }, [props.accountChanged]);

  // Save changes to accounts.
  async function saveAccounts() {
    if (accountsAreValid()) {
      const apiSuccess = await apiPutAccounts(props);
      if (apiSuccess === true) {
        props.onAction({
          type: "SAVE_ACCOUNTS",
          payload: {}
        });
      }
    }
  }

  // Check if all accounts are valid.
  function accountsAreValid() {
    let valid = true;
    for (const account of props.companyAccounts) {
      if (account.name.length === 0) {
        setErrorMessage("Each account is required to have a name.");
        valid = false;
        break;
      } else if (account.identifier === "") {
        setErrorMessage("Each account is required to have a valid name.");
        valid = false;
        break;
      }
    }
    return valid;
  }

  // Attempt an api put request
  async function apiPutAccounts(state) {
    let successful = true;
    const accounts = state.companyAccounts.map((account) => (account.accountId));
    const requestBody = { accountIds: accounts };

    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${context.isProd ? PROD_API : DEV_API}/company/${companyId}/account`,
      "PUT",
      requestBody
    );
    setLoading(false);

    if (!response.ok) {
      successful = false;
      if (response.status < 500 && responseBody.error) {
        setErrorMessage(responseBody.error);
      } else {
        setErrorMessage("Internal server error. Unable to update accounts");
      }
    }
    return successful;
  }

  return (
    <div>
      <Spinner loading={loading} />

      <div className="mt-2">
        <div
          className="list-header p-2"
          style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
        >
          <div className="row align-items-top">
            <div className="list-title col">
              <span className="align-top">Associated Accounts</span>
              <input
                className="form-control"
                list="datalist-accounts"
                value={props.companyAccounts.name}
                placeholder="Filter by name"
                onChange={(e) => setFilterId(e.target.value.toLowerCase())}
              />
            </div>

            <div className="col">
              <button
                className="create-btn btn btn-light float-end my-auto"
                onClick={() => props.onAction({
                  type: "CREATE_ACCOUNT",
                  payload: {}
                })}
              >
                <i className="fa fa-fw fa-plus fa-1x" />
              </button>
            </div>
          </div>
        </div>
        {props.companyAccounts.length === 0 && props.accounts.length === 0 ? (
          <div className="empty-list py-5 px-4 text-center">
            There are no Accounts to display
          </div>
        ) : (
          <div className="account-list">
            <table className="table table-hover mb-0">
              <thead className="list-header">
                <tr>
                  <th scope="col-4">Name</th>
                  <th scope="col-3">Code</th>
                  <th scope="col-1">Delete</th>
                </tr>
              </thead>
              <tbody className="list-body">
                {props.companyAccounts.filter((account) => account.name.toLowerCase().includes(filterId)).map((account) =>
                  <tr key={account.tempIdentifier}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="list-item col-4">
                      <input
                        className="form-select"
                        type="text"
                        list="datalistCompanyAccounts"
                        autoComplete="off"
                        value={account.name}
                        onChange={e => props.onAction({
                          type: "UPDATE_ACCOUNT",
                          payload: {
                            accountId: account.accountId,
                            identifier: account.identifier,
                            name: e.target.value,
                            tempIdentifier: account.tempIdentifier
                          }
                        })}
                      />

                      {account.name.length > 0 && (
                        <datalist id="datalistCompanyAccounts">
                          {props.accounts.map(account =>
                            <option key={account.accountId} value={account.name} />
                          )}
                        </datalist>
                      )}
                    </td>
                    <td className="list-item col-3">
                      {account.identifier}
                    </td>
                    <td className="list-item col-1">
                      <button
                        type="button"
                        className="btn btn-danger btn-floating"
                        onClick={() => props.onAction({
                          type: "DELETE_ACCOUNT",
                          payload: { tempIdentifier: account.tempIdentifier }
                        })}
                      >
                        <i className="fa fa-times" />
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
              <tbody className="list-body">
                {props.companyAccounts.filter((account) => !account.name.toLowerCase().includes(filterId)).map((account) =>
                  <tr key={account.tempIdentifier}>
                    <td className="list-item col-4">
                      <input
                        className="form-select"
                        type="text"
                        value={account.name}
                        disabled
                      />
                    </td>
                    <td className="list-item col-3">
                      {account.identifier}
                    </td>
                    <td className="list-item col-1">
                      <button
                        type="button"
                        className="btn btn-danger btn-floating"
                        disabled
                      >
                        <i className="fa fa-times" />
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {props.accountChanged && (
        <div
          className="list-footer p-3"
          style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}
        >
          <div className="row align-items-center">
            <div className="col">
              {errorMessage.length > 0 && (
                <Error message={errorMessage} />
              )}
            </div>
            <div
              className="col-auto"
            >
              <button
                className="btn btn-light me-3"
                onClick={() => saveAccounts()}
              >
                Save
              </button>

              <button
                className="btn btn-light"
                onClick={() => props.onAction({
                  type: "REVERT_ACCOUNT",
                  payload: {}
                })}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}


AccountList.propTypes = {
  companyAccounts: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  accountChanged: PropTypes.bool.isRequired
};