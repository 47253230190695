/*
Author:      Zachary Thomas
Created:     2/24/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Convert UTC timestamp to human readable local time.
export default function formatTimeToLocal(timestamp) {
  return new Date(Date.parse(timestamp))
    .toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour12: true,
      hour: "numeric",
      minute: "numeric"
    });
}