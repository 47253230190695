/*
Author:      Tyler Petty
Created:     6/7/2022
Modified:    7/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useReducer, useEffect, useState, useContext } from "react";
import DeviceList from "./DeviceList/DeviceList";
import AccountList from "./AccountList/AccountList";
import Error500Page from "../Error500Page/Error500Page";
import Context from "../../components/Context/Context";
import deepCopy from "../../utilities/deepCopy";
import {
  DEV_API,
  PROD_API,
  LINKS,
  OPERATIONS_LINKS
} from "../../utilities/constantsRpmAdministrativeConsole";
import { OPERATIONS_ROLE } from "../../utilities/constants";
import useApi from "../../hooks/useApi";
import { useParams } from "react-router-dom";
import "./RpmAdministrativeConsoleManageDevicesPage.scss";
import Toast from "../../components/Toast/Toast";
import PageTitle from "../../components/PageTitle/PageTitle";
import Spinner from "../../components/Spinner/Spinner";

// RPM Administrative Console Manage companies through editable lists of associated accounts and devices
export default function RpmAdministrativeConsoleManageDevicesPage() {
  const types = {
    CREATE_DEVICE: "CREATE_DEVICE",
    DELETE_DEVICE: "DELETE_DEVICE",
    SET_DEVICES: "SET_DEVICE",
    UPDATE_DEVICE: "UPDATE_DEVICE",
    REVERT_DEVICES: "REVERT_DEVICES",
    SAVE_DEVICES: "SAVE_DEVICES",

    CREATE_ACCOUNT: "CREATE_ACCOUNT",
    DELETE_ACCOUNT: "DELETE_ACCOUNT",
    SET_ACCOUNTS: "SET_ACCOUNT",
    SAVE_ACCOUNTS: "SAVE_ACCOUNTS",
    UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
    REVERT_ACCOUNTS: "REVERT_ACCOUNT"
  };

  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const context = useContext(Context);
  const { companyId } = useParams();
  const [deviceStore, dispatchDevice] = useReducer(deviceReducer, { tempCompanyDevices: [], companyDevices: [], deviceTypes: [] });
  const [accountStore, dispatchAccount] = useReducer(accountReducer, { tempCompanyAccounts: [], companyAccounts: [], accounts: [] });
  const [errorMessage, setErrorMessage] = useState("");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    context.setTitle("RPM Administrative Console");
    if ([OPERATIONS_ROLE].includes(context.role)) {
      context.setLinks(OPERATIONS_LINKS);
    } else {
      context.setLinks(LINKS);
    }
  }, [context.role]);

  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/company/${companyId}/map`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        responseBody = formatDeviceData(responseBody);
        responseBody = formatAccountData(responseBody);
        setCompanyName(responseBody.companyName);
        dispatchDevice({
          type: types.SET_DEVICES,
          payload: {
            companyDevices: responseBody.companyDevices,
            deviceTypes: responseBody.deviceTypes
          }
        });
        dispatchAccount({
          type: types.SET_ACCOUNTS,
          payload: {
            companyAccounts: responseBody.companyAccountIds,
            accounts: responseBody.accounts
          }
        });

      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  function deviceReducer(state, action) {
    switch (action.type) {
      case types.SET_DEVICES: {
        const newState = {
          companyDevices: action.payload.companyDevices,
          deviceTypes: action.payload.deviceTypes,
          tempCompanyDevices: action.payload.companyDevices
        };
        return newState;
      }

      case types.CREATE_DEVICE: {
        const stateDeepCopy = deepCopy(state);
        let nextId = 1;
        stateDeepCopy.tempCompanyDevices.forEach(device => {
          if (device.tempIdentifier >= nextId) {
            nextId = device.tempIdentifier + 1;
          }
        });
        const newDevice = {
          identifier: "",
          deviceTypeId: "",
          model: "",
          tempIdentifier: nextId
        };
        stateDeepCopy.tempCompanyDevices = [newDevice, ...stateDeepCopy.tempCompanyDevices];
        return stateDeepCopy;
      }

      case types.DELETE_DEVICE: {
        const stateDeepCopy = deepCopy(state);
        const deviceIndex = stateDeepCopy.tempCompanyDevices.findIndex(device =>
          device.tempIdentifier === action.payload.tempIdentifier
        );
        if (deviceIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.tempCompanyDevices.splice(deviceIndex, 1);
          return stateDeepCopy;
        }
      }

      case types.UPDATE_DEVICE: {
        const stateDeepCopy = deepCopy(state);
        const deviceIndex = stateDeepCopy.tempCompanyDevices.findIndex(device =>
          device.tempIdentifier === action.payload.tempIdentifier
        );
        if (deviceIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.tempCompanyDevices.splice(deviceIndex, 1, action.payload);
          return stateDeepCopy;
        }
      }

      case types.REVERT_DEVICES: {
        const stateDeepCopy = deepCopy(state);
        const companyDevices = deepCopy(state.companyDevices);
        stateDeepCopy.tempCompanyDevices = companyDevices;
        return stateDeepCopy;
      }

      case types.SAVE_DEVICES: {
        const stateDeepCopy = deepCopy(state);
        stateDeepCopy.companyDevices = stateDeepCopy.tempCompanyDevices;
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  function formatDeviceData(responseBody) {
    if (responseBody.companyDevices !== undefined) {
      let nextId = 0;
      responseBody.companyDevices.forEach(companyDevice => {
        if (responseBody.deviceTypes !== undefined) {
          const deviceType = responseBody.deviceTypes.find(deviceType =>
            deviceType.deviceTypeId === companyDevice.deviceTypeId
          );
          nextId += 1;
          if (deviceType === undefined) {
            companyDevice.model = "";
          } else {
            companyDevice.model = deviceType.model;
          }
        } else {
          companyDevice.model = "";
        }
        companyDevice.tempIdentifier = nextId;
      });
    }
    return responseBody;
  }

  function accountReducer(state, action) {
    switch (action.type) {
      case types.SET_ACCOUNTS: {
        const newState = {
          companyAccounts: action.payload.companyAccounts,
          accounts: action.payload.accounts,
          tempCompanyAccounts: action.payload.companyAccounts
        };
        return newState;
      }

      case types.CREATE_ACCOUNT: {
        const stateDeepCopy = deepCopy(state);
        let nextId = 1;
        stateDeepCopy.tempCompanyAccounts.forEach(account => {
          if (account.tempIdentifier >= nextId) {
            nextId = account.tempIdentifier + 1;
          }
        });
        const newAccount = {
          accountId: "",
          identifier: "",
          name: "",
          tempIdentifier: nextId
        };
        stateDeepCopy.tempCompanyAccounts = [newAccount, ...stateDeepCopy.tempCompanyAccounts];
        return stateDeepCopy;
      }

      case types.DELETE_ACCOUNT: {
        const stateDeepCopy = deepCopy(state);
        const accountIndex = stateDeepCopy.tempCompanyAccounts.findIndex(account =>
          account.tempIdentifier === action.payload.tempIdentifier
        );
        if (accountIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.tempCompanyAccounts.splice(accountIndex, 1);
          return stateDeepCopy;
        }
      }

      case types.UPDATE_ACCOUNT: {
        const stateDeepCopy = deepCopy(state);
        const accountIndex = stateDeepCopy.tempCompanyAccounts.findIndex(account =>
          account.tempIdentifier === action.payload.tempIdentifier
        );
        if (accountIndex === -1) {
          return stateDeepCopy;
        } else {
          const accountInfo = stateDeepCopy.accounts.find(accountInfo =>
            accountInfo.name === action.payload.name
          );
          if (accountInfo !== undefined) {
            action.payload.identifier = accountInfo.identifier;
            action.payload.accountId = accountInfo.accountId;
          } else {
            action.payload.identifier = "";
          }
          stateDeepCopy.tempCompanyAccounts.splice(accountIndex, 1, action.payload);
          return stateDeepCopy;
        }
      }

      case types.REVERT_ACCOUNTS: {
        const stateDeepCopy = deepCopy(state);
        const companyAccounts = deepCopy(state.companyAccounts);
        stateDeepCopy.tempCompanyAccounts = companyAccounts;
        return stateDeepCopy;
      }

      case types.SAVE_ACCOUNTS: {
        const stateDeepCopy = deepCopy(state);
        stateDeepCopy.companyAccounts = stateDeepCopy.tempCompanyAccounts;
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  function formatAccountData(responseBody) {
    responseBody.companyAccountIds = responseBody.companyAccountIds.map((item) => { return ({ accountId: item }); });
    if (responseBody.companyAccountIds !== undefined) {
      let nextId = 0;
      responseBody.companyAccountIds.forEach(companyAccount => {
        if (responseBody.accounts !== undefined) {
          const account = responseBody.accounts.find(account =>
            account.accountId === companyAccount.accountId
          );
          nextId += 1;
          companyAccount.tempIdentifier = nextId;
          if (account === undefined) {
            companyAccount.identifier = "";
            companyAccount.name = "";
          } else {
            companyAccount.identifier = account.identifier;
            companyAccount.name = account.name;
          }
        } else {
          companyAccount.identifier = "";
          companyAccount.name = "";
        }
      });
    }
    return responseBody;
  }

  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="page-note-template mb-5">
        <Spinner loading={loading} />
        <PageTitle title={`Manage ${companyName}`} />
        <AccountList
          companyAccounts={accountStore.tempCompanyAccounts}
          accounts={accountStore.accounts}
          loading={loading}
          onAction={action => dispatchAccount(action)}
          accountChanged={JSON.stringify(accountStore.companyAccounts) !== JSON.stringify(accountStore.tempCompanyAccounts)}
        />
        <DeviceList
          companyDevices={deviceStore.tempCompanyDevices}
          deviceTypes={deviceStore.deviceTypes}
          loading={loading}
          onAction={action => dispatchDevice(action)}
          devicesChanged={JSON.stringify(deviceStore.companyDevices) !== JSON.stringify(deviceStore.tempCompanyDevices)}
        />
        <Toast
          title="Failed to Update Template"
          message={errorMessage}
          show={errorMessage.length > 0}
          onClose={() => setErrorMessage("")}
          type="error"
        />
      </div>
    )
  );
}