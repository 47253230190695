/*
Author:      Zachary Thomas
Created:     4/8/2022
Modified:    6/16/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  MAX_CODE_LENGTH,
  EMAIL_TEMPLATES,
  EMAIL_SHELL_TEMPLATES
} from "../../../../../utilities/constantsNotificationManager";
import "./TemplateForm.scss";

// Form for creating a template.
export default function TemplateForm(props) {
  const { audienceType } = useParams();

  return (
    <div className="template-form">
      <label className="font-weight-bold mb-2">
        Code
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        maxLength={MAX_CODE_LENGTH}
        value={props.code}
        onChange={e => props.onChangeCode(e.target.value)}
      />

      <label className="font-weight-bold mt-4 mb-2">
        Description
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        value={props.description}
        onChange={e => props.onChangeDescription(e.target.value)}
      />

      <label className="font-weight-bold mt-4 mb-2">
        Default Urgency
      </label>
      <select
        className="form-select"
        value={props.urgencyId}
        onChange={(e) => props.onChangeUrgencyId(parseInt(e.target.value, 10))}
      >
        <option value="0" disabled>Select a default urgency...</option>
        {props.urgencies.map(urgency =>
          <option
            key={urgency.urgencyId}
            value={urgency.urgencyId}
          >
            {urgency.value}
          </option>
        )}
      </select>

      <label className="font-weight-bold mt-4 mb-2">
        Notification Minute Separation
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        value={props.minuteSeparation}
        onChange={e => props.onChangeMinuteSeparation(e.target.value)}
      />

      <label className="font-weight-bold mt-4 mb-2">
        Email Template
      </label>
      <select
        className="form-select"
        value={props.emailTemplate}
        onChange={(e) => props.onChangeEmailTemplate(e.target.value)}
      >
        <option value="">None</option>
        {EMAIL_TEMPLATES.map(emailTemplate =>
          <option
            key={emailTemplate}
            value={emailTemplate}
          >
            {emailTemplate}
          </option>
        )}
      </select>

      <label className="font-weight-bold mt-4 mb-2">
        Shell Email Template
      </label>
      <select
        className="form-select"
        value={props.shellEmailTemplate}
        onChange={(e) => props.onChangeShellEmailTemplate(e.target.value)}
      >
        <option value="">None</option>
        {EMAIL_SHELL_TEMPLATES.map(shellTemplate =>
          <option
            key={shellTemplate}
            value={shellTemplate}
          >
            {shellTemplate}
          </option>
        )}
      </select>

      {audienceType === "operations" ? (
        <Fragment>
          <label className="font-weight-bold mt-4 mb-2">
            Force Transmission
          </label>
          <input
            className="form-check-input form-check-big my-auto"
            type="checkbox"
            checked={props.forceTransmission}
            onChange={e => props.onChangeForceTransmission(e.target.checked)}
          />
        </Fragment>

      ) : (
        <Fragment>
          <label className="font-weight-bold mt-4 mb-2">
            Default Sender Email
          </label>
          <input
            className="form-control mx-auto"
            type="text"
            value={props.defaultEmail}
            onChange={e => props.onChangeDefaultEmail(e.target.value)}
          />

          <label className="font-weight-bold mt-4 mb-2">
            Default Sender SMS
          </label>
          <input
            className="form-control mx-auto"
            type="text"
            value={props.defaultSms}
            onChange={e => props.onChangeDefaultSms(e.target.value)}
          />

          <div className="row">
            <div className="col-auto me-5">
              <label className="font-weight-bold mt-4 mb-2">
                Global
              </label>
              <input
                className="form-check-input form-check-big my-auto"
                type="checkbox"
                checked={props.isGlobal}
                onChange={e => props.onChangeIsGlobal(e.target.checked)}
              />
            </div>

            <div className="col-auto">
              <label className="font-weight-bold mt-4 mb-2">
                Force Transmission
              </label>
              <input
                className="form-check-input form-check-big my-auto"
                type="checkbox"
                checked={props.forceTransmission}
                onChange={e => props.onChangeForceTransmission(e.target.checked)}
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

TemplateForm.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  urgencyId: PropTypes.number.isRequired,
  emailTemplate: PropTypes.string.isRequired,
  shellEmailTemplate: PropTypes.string.isRequired,
  defaultEmail: PropTypes.string.isRequired,
  defaultSms: PropTypes.string.isRequired,
  isGlobal: PropTypes.bool.isRequired,
  forceTransmission: PropTypes.bool.isRequired,
  minuteSeparation: PropTypes.string.isRequired,
  urgencies: PropTypes.array.isRequired,
  onChangeCode: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onChangeUrgencyId: PropTypes.func.isRequired,
  onChangeEmailTemplate: PropTypes.func.isRequired,
  onChangeShellEmailTemplate: PropTypes.func.isRequired,
  onChangeDefaultEmail: PropTypes.func.isRequired,
  onChangeDefaultSms: PropTypes.func.isRequired,
  onChangeIsGlobal: PropTypes.func.isRequired,
  onChangeForceTransmission: PropTypes.func.isRequired,
  onChangeMinuteSeparation: PropTypes.func.isRequired
};