/*
Author:      Zachary Thomas
Created:     6/3/2021
Modified:    2/22/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import TitleBar from "../TitleBar/TitleBar";
import "./Card.scss";

// Simple card with title.
export default function Card(props) {
  return (
    <div
      className={`simple-card ${props.fullHeight ? "h-100" : ""} ${props.fullWidth ? "w-100" : ""}`}
    >
      <TitleBar size={2} title={props.title} />
      {props.children}
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node
};