/*
Author:      Tyler Petty
Created:     6/2/2022
Modified:    7/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, useContext } from "react";
import CompanyList from "./CompanyList/CompanyList";
import Error500Page from "../Error500Page/Error500Page";
import Context from "../../components/Context/Context";
import {
  DEV_API,
  PROD_API,
  LINKS,
  OPERATIONS_LINKS
} from "../../utilities/constantsRpmAdministrativeConsole";
import { OPERATIONS_ROLE } from "../../utilities/constants";
import useApi from "../../hooks/useApi";
import PageTitle from "../../components/PageTitle/PageTitle";

// RPM Administrative Console Select a company from a list to view and edit
export default function RpmAdministrativeConsoleManageCompaniesPage() {
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const context = useContext(Context);

  useEffect(() => {
    context.setTitle("RPM Administrative Console");
    if ([OPERATIONS_ROLE].includes(context.role)) {
      context.setLinks(OPERATIONS_LINKS);
    } else {
      context.setLinks(LINKS);
    }
  }, [context.role]);


  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${context.isProd ? PROD_API : DEV_API}/company`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        setCompanyList(responseBody.companies);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [context.isProd]
  );

  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="pt-5 mb-4">
        <PageTitle title="Manage Companies" />
        <CompanyList
          companies={companyList}
          loading={loading}
        />
      </div>
    )
  );
}