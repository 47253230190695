/*
Author:      Grayson Fleming
Created:     3/3/2022
Modified:    3/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import {
  MAX_PROCESS_TITLE_LENGTH
} from "../../../../utilities/constants";
import "./EndpointForm.scss";


// Form for writing a endpoint.
export default function EndpointForm(props) {

  // Finds the index matching the endpoint type by name and sets the id state
  function updateEndpointType(event) {
    const index = props.endpointTypes.findIndex(item => item.endpointTypeName === event);
    props.onChangeEndpointTypeName(event);
    props.onChangeEndpointTypeId(props.endpointTypes[index].endpointTypeId);
  }

  return (
    <div className="endpoint-form">
      <div className="row align-items-center">
        <div className="col-10 mb-2">
          <label className="mb-1">
            <strong>
              Endpoint Name
            </strong>
          </label>
          <div className="row">
            <input
              className="form-control mx-auto ms-3 w-75"
              type="text"
              maxLength={MAX_PROCESS_TITLE_LENGTH}
              value={props.endpointName}
              onChange={(e) => props.onChangeEndpointName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-10 mb-2">
          <label className="control-label mb-1" htmlFor="endpointSelect">
            <strong>
              Endpoint Type
            </strong>
          </label>
          <div className="row">
            <select
              className="data-query-form-select ms-3 w-50 border border-grey rounded p-2"
              value={props.endpointType}
              onChange={(e) => updateEndpointType(e.target.value)}
              aria-label="select Endpoint"
              id="endpointSelect"
            >
              <option value="" disabled>Select an Endpoint Type...</option>
              {props.endpointTypes.map((endpointType, i) =>
                <option
                  value={endpointType.endpointTypeName}
                  key={i}
                >
                  {endpointType.endpointTypeName}
                </option>
              )}


            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

EndpointForm.propTypes = {
  endpointName: PropTypes.string.isRequired,
  endpointType: PropTypes.string.isRequired,
  endpointTypes: PropTypes.array.isRequired,
  onChangeEndpointName: PropTypes.func.isRequired,
  onChangeEndpointTypeId: PropTypes.func.isRequired,
  onChangeEndpointTypeName: PropTypes.func.isRequired
};