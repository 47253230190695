/*
Author:      Zachary Thomas
Created:     6/8/2021
Modified:    7/28/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, useContext } from "react";
import Spinner from "../../components/Spinner/Spinner";
import PageTitle from "../../components/PageTitle/PageTitle";
import LoginForm from "./LoginForm/LoginForm";
import { useHistory } from "react-router-dom";
import { AUTH_API } from "../../utilities/constants";
import apiRequest from "../../utilities/apiRequest";
import Context from "../../components/Context/Context";
import "./LoginPage.css";

// Page for users to login to their account.
export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const context = useContext(Context);
  // Setting the title and the links to be displayed in the navbar for the Homepage
  useEffect(() => {
    context.setTitle("RPM Dashboards");
    context.setLinks([]);
  }, []);

  // Attempts to login a user using the Cognito API.
  async function submit(email, password) {
    if (!email.length) {
      setErrorMessage("Please enter an email.");
    } else if (!password.length) {
      setErrorMessage("Please enter a password.");
    } else {

      setLoading(true);
      const requestBody = {
        Username: email,
        Password: password
      };
      let response = null;
      let responseBody = null;
      localStorage.setItem("idToken", "");
      [response, responseBody] = await apiRequest(
        `${AUTH_API}/authenticate`,
        "POST",
        requestBody
      );
      setLoading(false);
      if (response.ok && responseBody) {
        localStorage.setItem("idToken", responseBody.items.token);
        context.setRole(responseBody.items.userRole);
        localStorage.setItem("userRole", responseBody.items.userRole);
        history.push("/");
      } else {
        setErrorMessage("Username and password are not valid");
      }

    }
  }

  return (
    <div className="page-login mb-4">
      <Spinner loading={loading} />

      <PageTitle title={`User Login`} />

      <LoginForm
        errorMessage={errorMessage}
        onSubmit={(email, password) => submit(email, password)}
      />
    </div>
  );
}

