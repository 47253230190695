/*
Author:      Zachary Thomas
Created:     4/8/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  MAX_CODE_LENGTH,
  MAX_NAME_LENGTH
} from "../../../../../utilities/constantsNotificationManager";
import "./SubsystemForm.scss";

// Form for creating a subsystem.
export default function SubsystemForm(props) {
  return (
    <div className="subsystem-form">
      <label className="font-weight-bold mb-2">
        Code
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        maxLength={MAX_CODE_LENGTH}
        value={props.code}
        onChange={e => props.onChangeCode(e.target.value)}
      />

      <label className="font-weight-bold mt-4 mb-2">
        Name
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        maxLength={MAX_NAME_LENGTH}
        value={props.name}
        onChange={e => props.onChangeName(e.target.value)}
      />

      {props.audienceType === "operations" && (
        <Fragment>
          <label className="font-weight-bold mt-4 mb-2">
            Default Sender Email
          </label>
          <input
            className="form-control mx-auto"
            type="text"
            value={props.email}
            onChange={e => props.onChangeEmail(e.target.value)}
          />

          <label className="font-weight-bold mt-4 mb-2">
            Default Sender SMS
          </label>
          <input
            className="form-control mx-auto"
            type="text"
            value={props.sms}
            onChange={e => props.onChangeSms(e.target.value)}
          />
        </Fragment>
      )}

      <label className="font-weight-bold mt-4 mb-2">
        Description
      </label>
      <input
        className="form-control mx-auto"
        type="text"
        value={props.description}
        onChange={e => props.onChangeDescription(e.target.value)}
      />
    </div>
  );
}

SubsystemForm.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sms: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  audienceType: PropTypes.string.isRequired,
  onChangeCode: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeSms: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired
};