/*
Author:      Zachary Thomas
Created:     2/24/2022
Modified:    3/10/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import "./DeviceDetails.scss";

// Display device details.
export default function DeviceDetails(props) {
  return (
    <div className="iot-device-details h-100">
      <Card title="Device Details" fullHeight>
        <div className="device-information my-4 mx-5">
          <div>
            <label className="font-weight-bold">Device Type</label>
            <p>{props.deviceType}</p>
          </div>

          <div>
            <label className="font-weight-bold">Device Class</label>
            <p>{props.deviceClass}</p>
          </div>

          <div>
            <label className="font-weight-bold">Device Identifier</label>
            <p>{props.deviceIdentifier ? props.deviceIdentifier : "None"}</p>
          </div>

          <div>
            <label className="font-weight-bold">Company</label>
            <p>{props.ownerCompany ? props.ownerCompany : "None"}</p>
          </div>

          <div>
            <label className="font-weight-bold">Asset Identifier</label>
            <p>{props.assetIdentifier ? props.assetIdentifier : "None"}</p>
          </div>
        </div>
      </Card>
    </div>
  );
}

DeviceDetails.propTypes = {
  deviceType: PropTypes.string.isRequired,
  deviceClass: PropTypes.string.isRequired,
  deviceIdentifier: PropTypes.string,
  ownerCompany: PropTypes.string,
  assetIdentifier: PropTypes.string
};