/*
Author:      KC Willard
Created:     3/16/2022
Modified:    3/16/2022
Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import deepCopy from "../utilities/deepCopy";
import { RPM_PROCESS_TYPES } from "../utilities/constantsEtlProcessManager";

export default function EtlProcessManagerCleanProcessTypes(responseBody) {
  const responseBodyDeepCopy = deepCopy(responseBody);

  // Make sure there are processTypes
  if (responseBodyDeepCopy.processTypes === undefined) {
    responseBodyDeepCopy.processTypes = [];
  }

  responseBodyDeepCopy.processTypes.forEach(processType => {
    const foundProcess = RPM_PROCESS_TYPES.findIndex(item => item.processType === processType.processName);
    // If the process type is found in our master list then add all information to the array
    if (foundProcess !== -1) {
      processType.processType = RPM_PROCESS_TYPES[foundProcess].processType;
      processType.hasFilter = RPM_PROCESS_TYPES[foundProcess].hasFilter;
      processType.filterLabel = RPM_PROCESS_TYPES[foundProcess].filterLabel;
      processType.endpointCollectionLabel = RPM_PROCESS_TYPES[foundProcess].endpointCollectionLabel;
      processType.hasEndpointList = RPM_PROCESS_TYPES[foundProcess].hasEndpointList;
      processType.sourceEndpointTypes = RPM_PROCESS_TYPES[foundProcess].sourceEndpointTypes;
      processType.targetEndpointTypes = RPM_PROCESS_TYPES[foundProcess].targetEndpointTypes;
    }
  });
  return responseBodyDeepCopy.processTypes;
}
