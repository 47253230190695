/*
Author:      Grayson Fleming
Created:     3/3/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./EndpointList.scss";
import Context from "../../../components/Context/Context";
import { Tab, Tabs } from "react-bootstrap";


// A list of endpoints that support creation, updating, and deletion.
export default function EndpointList(props) {
  const context = useContext(Context);

  // A function to handle the switching of organization by tabs
  function changeOrganization(key) {
    if (key === "rpm") {
      props.onChangeIsRpm(true);
    }
    if (key === "etl") {
      props.onChangeIsRpm(false);
    }
  }

  return (
    <div className="endpoint-list">
      <div className="list-header p-4"
        style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}>
        <div className="row align-items-top">
          <div className="list-title col">
            <span className="align-top">Endpoints</span>
          </div>

          {/* Button for creating new endpoints. */}
          <div className="col">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => props.onSelect(0)}
            >
              <i className="fa fa-fw fa-plus fa-1x" />
            </button>
          </div>
        </div>
      </div>
      <div className="organization-tabs"
        style={context.isProd ? { backgroundColor: "var(--prod-background)" } : { backgroundColor: "var(--dev-background)" }}>
        <Tabs defaultActiveKey={"rpm"} onSelect={(eventKey) => changeOrganization(eventKey)}>
          <Tab eventKey={"rpm"} title={"RPM"}>

          </Tab>
          <Tab eventKey={"etl"} title={"ETL"}></Tab>
        </Tabs>
      </div>

      <div className="list-body">
        {/* List of interactive endpoints. */}
        {!props.loading && props.endpoints.map(endpoint =>
          <div
            key={endpoint.endpointId}
            className="list-item selectable-container px-3 py-2"
            onClick={() => props.onSelect(endpoint.endpointId)}
          >
            {endpoint.endpointName}
          </div>
        )}

        {props.endpoints.length === 0 && !props.loading && (
          <div className="empty-list py-5 px-4">
            There are no endpoints to display
          </div>
        )}
      </div>
    </div>
  );
}

EndpointList.propTypes = {
  loading: PropTypes.bool,
  endpoints: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isRpm: PropTypes.bool.isRequired,
  onChangeIsRpm: PropTypes.func.isRequired
};