/*
Author:      Zachary Thomas
Created:     4/8/2022
Modified:    6/16/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useContext } from "react";
import apiRequest from "../../../../utilities/apiRequest";
import SaveChangesModal from "../../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../../components/Modal/Modal";
import ModalHeader from "../../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../../components/ModalFooter/ModalFooter";
import Spinner from "../../../../components/Spinner/Spinner";
import Error from "../../../../components/Error/Error";
import TemplateForm from "./TemplateForm/TemplateForm";
import Context from "../../../../components/Context/Context";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import {
  MIN_CODE_LENGTH,
  MAX_CODE_LENGTH,
  SUBDIRECTORY,
  PROD_API,
  DEV_API
} from "../../../../utilities/constantsNotificationManager";
import "./TemplateModal.scss";

// Modal for creating templates.
export default function TemplateModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [urgencyId, setUrgencyId] = useState(0);
  const [emailTemplate, setEmailTemplate] = useState("");
  const [emailShellTemplate, setEmailShellTemplate] = useState("");
  const [minuteSeparation, setMinuteSeparation] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultSms, setDefaultSms] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [forceTransmission, setForceTransmission] = useState(false);
  const { audienceType, subsystemId } = useParams();
  const context = useContext(Context);
  const history = useHistory();

  // Validate the template.
  function templateIsValid() {
    if (code.length < MIN_CODE_LENGTH || code.length > MAX_CODE_LENGTH) {
      setErrorMessage(
        `The code must be between ${MIN_CODE_LENGTH} and ${MAX_CODE_LENGTH} characters long.`
      );
      return false;
    } else if (props.urgencies.findIndex(urgency => urgency.urgencyId === urgencyId) === -1) {
      setErrorMessage(`A default urgency is required.`);
      return false;
    } else if (audienceType !== "operations" && defaultSms.length === 0) {
      setErrorMessage(
        "A default SMS is required."
      );
      return false;
    } else if (audienceType !== "operations" && defaultEmail.length === 0) {
      setErrorMessage(
        "A default email is required."
      );
      return false;
    } else if (!Number.isInteger(parseInt(minuteSeparation, 10)) || parseInt(minuteSeparation, 10) < 0) {
      setErrorMessage(
        "The notification minute separation must be an integer that is zero or greater."
      );
    } else {
      return true;
    }
  }

  // Create a template.
  async function createTemplate() {
    setShowConfirmExit(false);
    if (templateIsValid()) {
      let selectedTemplate = emailTemplate;
      let selectedEmailShellTemplate = emailShellTemplate;
      if (selectedTemplate.length === 0) {
        selectedTemplate = null;
      }
      if (selectedEmailShellTemplate.length === 0) {
        selectedEmailShellTemplate = null;
      }

      const requestBody = {
        templateCode: code,
        description: description,
        isGlobal: isGlobal,
        forceTransmission: forceTransmission,
        defaultUrgencyId: urgencyId,
        emailTemplate: selectedTemplate,
        emailShellTemplate: selectedEmailShellTemplate,
        minuteSeparation: parseInt(minuteSeparation, 10),
        defaultEmail: defaultEmail,
        defaultSms: defaultSms,
        addressees: []
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/subsystem/${audienceType}/${subsystemId}/template`,
        "POST",
        requestBody
      );
      setLoading(false);

      if (response.ok && responseBody) {
        history.push(`/${SUBDIRECTORY}/subsystem/${audienceType}/${subsystemId}/template/${responseBody.templateId}`);
      } else {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to create template.");
        }
      }
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal() {
    // Check to see if state is the same as props.
    if (
      code.length === 0
      && description.length === 0
      && urgencyId === 0
      && emailTemplate.length === 0
      && emailShellTemplate.length === 0
      && defaultEmail.length === 0
      && defaultSms.length === 0
      && isGlobal === false
      && forceTransmission === false
      && minuteSeparation.length === 0
    ) {
      // Since there have been no changes we can safely exit.
      dontSaveChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setCode("");
    setDescription("");
    setUrgencyId(0);
    setEmailTemplate("");
    setEmailShellTemplate("");
    setDefaultEmail("");
    setDefaultSms("");
    setIsGlobal(false);
    setForceTransmission(false);
    setErrorMessage("");
    setMinuteSeparation("");
    props.onClose();
  }

  return (
    <div className="template-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            Create Template
          </h5>
        </ModalHeader>

        <ModalBody>
          <TemplateForm
            code={code}
            description={description}
            urgencyId={urgencyId}
            emailTemplate={emailTemplate}
            emailShellTemplate={emailShellTemplate}
            defaultEmail={defaultEmail}
            defaultSms={defaultSms}
            isGlobal={isGlobal}
            forceTransmission={forceTransmission}
            minuteSeparation={minuteSeparation}
            urgencies={props.urgencies}
            onChangeCode={code => setCode(code)}
            onChangeDescription={description => setDescription(description)}
            onChangeUrgencyId={urgencyId => setUrgencyId(urgencyId)}
            onChangeEmailTemplate={emailTemplate => setEmailTemplate(emailTemplate)}
            onChangeEmailShellTemplate={emailShellTemplate => setEmailShellTemplate(emailShellTemplate)}
            onChangeDefaultEmail={defaultEmail => setDefaultEmail(defaultEmail)}
            onChangeDefaultSms={defaultSms => setDefaultSms(defaultSms)}
            onChangeIsGlobal={isGlobal => setIsGlobal(isGlobal)}
            onChangeMinuteSeparation={minuteSeparation => setMinuteSeparation(minuteSeparation)}
            onChangeForceTransmission={forceTransmission => setForceTransmission(forceTransmission)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-success" type="button" onClick={() => createTemplate()}>
            Create Template
          </button>

          <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => createTemplate()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

TemplateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  audienceType: PropTypes.string.isRequired,
  subsystemId: PropTypes.number.isRequired,
  urgencies: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};