/*
Author:      KC Willard
Created:     2/25/2022
Modified:    6/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
import React from "react";
import PropTypes from "prop-types";
import formatTimeToLocal from "../../../../utilities/formatTimeToLocal";
import { MAX_PROCESS_TITLE_LENGTH } from "../../../../utilities/constants";
import "./ProcessForm.scss";

// Form for creating and editing a process.
export default function ProcessForm(props) {
  // Function to prevent users entering invalid characters into number input fields
  function blockInvalidChars(event) {
    ["+", "-", "e", "."].includes(event.key) && event.preventDefault();
  }

  // Function to update the currently set processTypeName and processTypeId
  function updateProcessType(event) {
    const index = props.processTypes.findIndex(item => item.processType === event);
    props.onChangeProcessTypeName(event);
    props.onChangeProcessTypeId(props.processTypes[index].processTypeId);
  }

  return (
    <div className="process-form">
      <div className="row align-items-center">
        <div className="col-5 mb-3">
          <label >
            Process Name
          </label>
          <input
            className="form-control mx-auto"
            type="text"
            maxLength={MAX_PROCESS_TITLE_LENGTH}
            value={props.processName}
            onChange={(e) => props.onChangeProcessName(e.target.value)}
          />
        </div>
        {/* If the mode is create, we want the ability to switch the process type. Otherwise, disable the dropdown*/}
        {props.mode === "create" ? (
          <div className="col-5 mb-3">
            <label >
              Process Type
            </label>
            {/* If a process type hasn't been selected, display a message and render the list of options*/}
            {props.processTypeName.length === 0 ? (
              <select
                className="form-select"
                value={props.processTypeName}
                onChange={(event) => updateProcessType(event.target.value)}
              >
                <option value={-1}>Select a process type...</option>
                {props.processTypes.map((processType, i) => {
                  return <option
                    value={processType.processType}
                    key={i}
                  >
                    {processType.processType}
                  </option>;
                })}
              </select>
            ) : (
              <select
                className="form-select"
                value={props.processTypeName}
                onChange={(event) => updateProcessType(event.target.value)}
              >
                {props.processTypes.map((processType, i) => {
                  return <option
                    value={processType.processType}
                    key={i}
                  >
                    {processType.processType}
                  </option>;
                })}
              </select>
            )}
          </div>
        ) : (
          <div className="col-5 mb-3">
            <label >
              Process Type
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={props.processTypeName}
              disabled={true}
            />
          </div>
        )}
        <div className="col-2 mb-3 mt-4" id="toggleIsEnabledSwitch" >
          <div className="form-check form-switch ">
            <label className="control-label" htmlFor="toggleIsEnabled"><strong>Enabled</strong></label>
            <input
              className="form-check-input"
              type="checkbox"
              id="toggleIsEnabled"
              checked={props.processIsEnabled}
              onChange={(e) => props.onChangeProcessIsEnabled(e.target.checked)}
            >
            </input>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col  mb-3">
          <label>
            Frequency (min)
          </label>
          <input
            className="form-control"
            type="number"
            value={props.processInterval}
            onKeyDown={blockInvalidChars}
            onChange={(e) => props.onChangeProcessInterval(e.target.value)}
          />
        </div>
        <div className="col  mb-3">
          <label >
            Estimated Run Time (min)
          </label>
          <input
            className="form-control"
            type="number"
            value={props.processEstimatedRunTime}
            onKeyDown={blockInvalidChars}
            onChange={(e) => props.onChangeProcessEstimatedRunTime(e.target.value)}
          />
        </div>
        {props.processLastRunUtc.length > 0 ? (
          <div className="col mb-3">
            <label >
              Last Run Time (Local Time)
            </label>
            <input
              className="form-control"
              type="text"
              value={formatTimeToLocal(`${props.processLastRunUtc.replace(" ", "T")}.000Z`)}
              disabled={true}
            />
          </div>
        ) : (
          <div className="col mb-3">
            <label >
              Last Run Time (Local Time)
            </label>
            <input
              className="form-control"
              type="text"
              value={"Process has not run"}
              disabled={true}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col mb-3">
          <label>
            Settings (JSON)
          </label>
          <textarea
            className="form-control"
            type="json"
            value={props.processSettingsJson}
            onChange={(e) => props.onChangeProcessSettingsJson(e.target.value)}
          >
          </textarea>
        </div>
      </div>
    </div>
  );
}

ProcessForm.propTypes = {
  mode: PropTypes.string.isRequired,
  processName: PropTypes.string.isRequired,
  processIsEnabled: PropTypes.bool.isRequired,
  processTypeName: PropTypes.string.isRequired,
  processInterval: PropTypes.string.isRequired,
  processLastRunUtc: PropTypes.string.isRequired,
  processEstimatedRunTime: PropTypes.string.isRequired,
  processSettingsJson: PropTypes.string.isRequired,
  processTypes: PropTypes.array.isRequired,
  onChangeProcessName: PropTypes.func.isRequired,
  onChangeProcessIsEnabled: PropTypes.func.isRequired,
  onChangeProcessInterval: PropTypes.func.isRequired,
  onChangeProcessTypeName: PropTypes.func.isRequired,
  onChangeProcessTypeId: PropTypes.func.isRequired,
  onChangeProcessEstimatedRunTime: PropTypes.func.isRequired,
  onChangeProcessSettingsJson: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired
};