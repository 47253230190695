/*
Author:      KC Willard
Created:     12/20/2021
Modified:    6/14/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./Record.css";
import formatTimeToLocal from "../../../utilities/formatTimeToLocal";

// This component is used to display individual records in the table of records on RecordsPage.js
export default function Record(props) {
  function formatEventText() {
    if (props.record.event.length > 40) {
      return props.record.event.substring(0, 40) + "...";
    } else {
      return props.record.event;
    }
  }

  // function formatDate() {
  //   return new Date(props.record.recordArrivalUTC.substring(0, 23) + "Z").toUTCString();
  // }

  function formatDateISO() {
    return new Date(props.record.recordArrivalUTC.substring(0, 23) + "Z").toISOString();
  }
  return (
    <tr className={props.record.isTest ? "table-success" : ""}>
      <td>{props.record.eventThreadApp}</td>
      <td>{formatEventText()}</td>
      <td>{formatTimeToLocal(`${props.record.recordArrivalUTC}Z`)}</td>
      <td>{formatDateISO()}</td>
      <td>
        <a
          className="btn btn-outline-primary"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${props.index}`}
          aria-expanded="false"
          aria-controls={`collapse${props.index}`}
          onClick={() => props.onShowMore()}
        >
          {props.record.isTest ? "Show More- Test Event" : "Show More"}
        </a>
      </td>
    </tr >
  );
}

Record.propTypes = {
  record: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onShowMore: PropTypes.func.isRequired,
};