/*
Author:      Zachary Thomas
Created:     4/8/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./ConfirmationButtons.scss";

// Confirmation buttons for a field.
export default function ConfirmationButtons(props) {
  return (
    <div className="confirmation-buttons my-2 float-end">
      <button
        type="button"
        className="btn btn-success btn-floating me-2"
        onClick={() => props.onAccept()}>
        <i className="fa fa-check" />
      </button>

      <button
        type="button"
        className="btn btn-secondary btn-floating"
        onClick={() => props.onCancel()}>
        <i className="fa fa-times" />
      </button>
    </div>
  );
}

ConfirmationButtons.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};