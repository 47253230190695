/*
Author:      KC Willard
Created:     1/11/2022
Modified:    7/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useContext, useEffect } from "react";
import Card from "../../components/Card/Card";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import Context from "../../components/Context/Context";
import { ADMIN_ROLE, OPERATIONS_ROLE } from "../../utilities/constants";
import { SUBDIRECTORY as EVENTLOGVIEWER_SUBDIRECTORY } from "../../utilities/constantsEventlogViewer";
import { SUBDIRECTORY as ETLPROCESSMANAGER_SUBDIRECTORY } from "../../utilities/constantsEtlProcessManager";
import { SUBDIRECTORY as NOTIFICATIONMANAGER_SUBDIRECTORY } from "../../utilities/constantsNotificationManager";
import { SUBDIRECTORY as IOTMANAGER_SUBDIRECTORY } from "../../utilities/constantsIotManager";
import { SUBDIRECTORY as RPMADMINISTRATIVECONSOLE_SUBDIRECTORY } from "../../utilities/constantsRpmAdministrativeConsole";
import "./HomePage.css";

// Page for displaying links to all dashboards.
export default function HomePage() {
  const context = useContext(Context);
  // Setting the title and the links to be displayed in the navbar for the Homepage
  useEffect(() => {
    context.setTitle("RPM Dashboards");
    context.setLinks([]);
  }, []);
  return (
    <Fragment>
      <div className="row" >
        <div className="col my-4" >
          <a href={`/${EVENTLOGVIEWER_SUBDIRECTORY}/`} >
            <Card title="EventLog Viewer">
              <TextBlurb
                title=""
                paragraph="View Records and Trends of Lambdas"
                icon="line-chart"
              />
            </Card>
          </a>
        </div>
        {[ADMIN_ROLE].includes(context.role) &&
          <div className="col my-4">
            <a href={`/${ETLPROCESSMANAGER_SUBDIRECTORY}`} >
              <Card title="ETL Manager">
                <TextBlurb
                  title=""
                  paragraph="Control the export and import of data into databases"
                  icon="exchange"
                />
              </Card>
            </a>
          </div>
        }
      </div>
      <div className="row">
        {[ADMIN_ROLE, OPERATIONS_ROLE].includes(context.role) &&
          <div className="col my-4">
            <a href={`/${RPMADMINISTRATIVECONSOLE_SUBDIRECTORY}`} >
              <Card title="RPM Administrative Console">
                <TextBlurb
                  title=""
                  paragraph="Send Systemwide Notifications"
                  icon="bullhorn"
                />
              </Card>
            </a>
          </div>
        }
        {[ADMIN_ROLE].includes(context.role) &&
          <div className="col my-4">
            <a href={`/${NOTIFICATIONMANAGER_SUBDIRECTORY}`}>
              <Card title="Notification Manager">
                <TextBlurb
                  title=""
                  paragraph="Manage Alerts sent by Lambdas"
                  icon="envelope-open-o"
                />
              </Card>
            </a>
          </div>
        }
      </div>
      <div className="row">
        {[ADMIN_ROLE].includes(context.role) &&
          <div className="col my-4">
            <a href={`/${IOTMANAGER_SUBDIRECTORY}`} >
              <Card title="IoT Manager">
                <TextBlurb
                  title=""
                  paragraph="Push Firmware and Monitor devices"
                  icon="cog"
                />
              </Card>
            </a>
          </div>
        }
        <div className="col my-4">
          <Card title="Place Holder">
            <TextBlurb
              title=""
              paragraph="New Dashboard coming soon!"
              icon="wrench"
            />
          </Card>

        </div>
      </div>
    </Fragment>
  );
}

