/*
Author:      Zachary Thomas
Created:     3/1/2022
Modified:    8/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

const notificationManagerSubdirectory = "notification-manager";

// Constants that are referenced throughout the Notification Manager.
module.exports = Object.freeze({
  DEV_API: "https://u7p82hahee.execute-api.us-west-2.amazonaws.com/dev",
  PROD_API: "https://2w3twfbnxf.execute-api.us-west-2.amazonaws.com/prod",
  SUBDIRECTORY: notificationManagerSubdirectory,
  LINKS: [
    { name: "Manage Subsystems", url: `/${notificationManagerSubdirectory}/manage-subsystems` }
  ],
  MIN_CODE_LENGTH: 3,
  MAX_CODE_LENGTH: 30,
  MIN_NAME_LENGTH: 3,
  MAX_NAME_LENGTH: 50,
  EMAIL_TEMPLATES: [
    "Alert_Ceased.html",
    "Alert_Continuing.html",
    "Default.html",
    "EventLog_Digest.html",
    "Exception.html",
    "Iot_HardwareAlert.html",
    "RPMAlert_Geofence.html",
    "RPM_AssetAlert.html",
    "RPM_AssetReview.html",
    "RPM_InvitationToJoinCompanyAccount.html",
    "RPM_RejectRequestToJoinCompanyAccount.html",
    "RPM_RequestToJoinCompanyAccount.html"
  ],
  EMAIL_SHELL_TEMPLATES: [
    "Default.html",
    "Email_Template.html"
  ]
});