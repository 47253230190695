/*
Author:      KC Willard
Created:     10/17/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useContext, useReducer } from "react";
import apiRequest from "../../../utilities/apiRequest";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Spinner from "../../../components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import TestMessageForm from "./TestMessageForm/TestMessageForm";
import Context from "../../../components/Context/Context";
import PropTypes from "prop-types";
import deepCopy from "../../../utilities/deepCopy";
import { useParams } from "react-router-dom";
import {
  PROD_API,
  DEV_API
} from "../../../utilities/constantsNotificationManager";
import "./TestMessageModal.scss";
import formatTimeToUtc from "../../../utilities/formatTimeToUtc";

// Modal for Sending Test messages.
export default function TestMessageModal(props) {
  const types = {
    CREATE_MESSAGELIST: "CREATE_MESSAGELIST",
    UPDATE_MESSAGELIST: "UPDATE_MESSAGELIST",
    DELETE_MESSAGELIST: "DELETE_MESSAGELIST",
    CLEAR_MESSAGELIST: "CLEAR_MESSAGELIST",
  };
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [addresseeEmail, setAddresseeEmail] = useState("");
  const [addresseeSms, setAddresseeSms] = useState("");
  const [sendWithDelay, setSendWithDelay] = useState(false);
  const [dateToSend, setDateToSend] = useState("");
  const [timeToSend, setTimeToSend] = useState("");
  const { audienceType, subsystemId, templateId } = useParams();
  const context = useContext(Context);
  const [messageList, dispatch] = useReducer(messageListReducer, []);

  // Reducer for message list.
  function messageListReducer(state, action) {
    switch (action.type) {

      case types.CREATE_MESSAGELIST: {
        const stateDeepCopy = deepCopy(state);
        let nextId = 1;
        stateDeepCopy.forEach(message => {
          if (message.messageListId >= nextId) {
            nextId = message.messageListId + 1;
          }
        });
        const newMessage = {
          messageListId: nextId,
          message: "",
        };
        stateDeepCopy.push(newMessage);
        return stateDeepCopy;
      }

      case types.UPDATE_MESSAGELIST: {
        const stateDeepCopy = deepCopy(state);
        const messageListIndex = stateDeepCopy.findIndex(messageList =>
          messageList.messageListId === action.payload.messageListId
        );
        if (messageListIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(messageListIndex, 1, action.payload);
          return stateDeepCopy;
        }
      }

      case types.DELETE_MESSAGELIST: {
        const stateDeepCopy = deepCopy(state);
        const messageListIndex = stateDeepCopy.findIndex(messageList =>
          messageList.messageListId === action.payload
        );
        if (messageListIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(messageListIndex, 1);
          return stateDeepCopy;
        }
      }

      case types.CLEAR_MESSAGELIST: {
        return [];
      }

      default: {
        return state;
      }
    }
  }

  // Exit modal and clear states of controlled components.
  function exitModal() {
    setAddresseeEmail("");
    setAddresseeSms("");
    setSendWithDelay(false);
    setDateToSend("");
    setTimeToSend("");
    setErrorMessage("");
    dispatch({ type: types.CLEAR_MESSAGELIST, payload: [] });
    props.onClose();
  }
  // Set error messages if test message is not valid
  function testMessageIsValid() {
    if (addresseeEmail.length === 0 && addresseeSms.length === 0) {
      setErrorMessage(
        `Must enter an email address or sms address for message to send`
      );
      return false;
    } else if (sendWithDelay === true && dateToSend.length === 0) {
      setErrorMessage(
        `Please select a date to send the message`
      );
      return false;
    } else if (sendWithDelay === true && timeToSend.length === 0) {
      setErrorMessage(
        `Please select a time to send the message`
      );
      return false;
    } else {
      return true;
    }
  }

  // Send the test message.
  async function sendTestMessage() {
    if (testMessageIsValid()) {
      const tempMessageList = [];
      messageList.forEach(message => tempMessageList.push(message.message));
      const requestBody = {
        subsystemCode: props.subsystemCode,
        templateCode: props.templateCode,
        messageList: tempMessageList,
        addresseeEmail: addresseeEmail,
        addresseeSms: addresseeSms,
        timeToSendUtc: "",
      };
      if (sendWithDelay === true) {
        // Convert to UTC
        const tempTimeToSend = new Date(`${dateToSend}T${timeToSend}:00.000+0000`);
        const utc = formatTimeToUtc(tempTimeToSend);
        requestBody.timeToSendUtc = `${utc.toISOString().split(".")[0]}`;
      }

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${context.isProd ? PROD_API : DEV_API}/subsystem/${audienceType}/${subsystemId}/template/${templateId}/test`,
        "POST",
        requestBody
      );
      setLoading(false);

      if (response.ok && responseBody) {
        exitModal();
      } else {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to create template.");
        }
      }
    }

  }


  return (
    <div className="template-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold mt-2">
            Send Test Message
          </h5>
        </ModalHeader>

        <ModalBody>
          <TestMessageForm
            subsystemCode={props.subsystemCode}
            templateCode={props.templateCode}
            messageList={messageList}
            addresseeEmail={addresseeEmail}
            addresseeSms={addresseeSms}
            sendWithDelay={sendWithDelay}
            dateToSend={dateToSend}
            timeToSend={timeToSend}
            onChangeAddresseeEmail={addresseeEmail => setAddresseeEmail(addresseeEmail)}
            onChangeAddresseeSms={addresseeSms => setAddresseeSms(addresseeSms)}
            onChangeSendWithDelay={sendWithDelay => setSendWithDelay(sendWithDelay)}
            onChangeDateToSend={dateToSend => setDateToSend(dateToSend)}
            onChangeTimeToSend={timeToSend => setTimeToSend(timeToSend)}
            onAction={(type, payload) => dispatch({ type: type, payload: payload })}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-success" type="button" onClick={() => sendTestMessage()}>
            Send Message
          </button>

          <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>

    </div>
  );
}

TestMessageModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  subsystemCode: PropTypes.string.isRequired,
  templateCode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};