/*
Author:      Zachary Thomas
Created:     3/10/2022
Modified:    3/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import deepCopy from "../utilities/deepCopy";
import changeKeyName from "../utilities/changeKeyName";

// Clean up and format GET device resource API response body.
export default function IotManagerCleanDeviceResource(responseBody) {
  let responseBodyDeepCopy = deepCopy(responseBody);

  // Nest the response in an object.
  responseBodyDeepCopy = {
    resources: responseBodyDeepCopy
  };

  // Update the UTC timestamp to have the correct format.
  responseBodyDeepCopy.resources.forEach(resource => {
    changeKeyName(resource, "recordArrivalUTC", "arrivalTime");
    const lu = resource.arrivalTime;
    const arrivalTime = `${lu.slice(0, 4)}-${lu.slice(4, 6)}-${lu.slice(6, 11)}:${lu.slice(11, 13)}:${lu.slice(13)}`;
    resource.arrivalTime = arrivalTime;
    if (!resource.fileDescription) {
      resource.fileDescription = "";
    }

    if (!resource.name) {
      resource.name = "";
    }

    if (!resource.code) {
      resource.code = "";
    }

    if (!resource.fileName) {
      if (resource.location && resource.location.includes("/")) {
        const locationSplit = resource.location.split("/");
        resource.fileName = locationSplit.pop();
      } else {
        resource.fileName = "";
      }
    }
    changeKeyName(resource, "fileName", "name");
  });

  console.log("Processed Response Body: ", responseBodyDeepCopy);
  return responseBodyDeepCopy;
}